import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext'
import {Checkbox} from 'primereact/checkbox'
import {ColorPicker} from 'primereact/colorpicker'
import {Display} from '../../components/Display'
import '../../components/Forms.css'

class ApplicantNextStepForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'applicantNextStep', '/Etats/CVthèque', 'ApplicantNextSteps',

      {
        applicantNextStep: {
          id: props.match.params.id,
          defaultValue: false,
          color : "000000",
          icon: "",
          name: "",
        },

        constraints: {
          constraintViolationException: {
            applicant_nextStep_name: "Libellé déjà utilisé (Il doit être unique).",
          }
        },

        mandatoryFields: {
          name: {test: FormContainer.checkNotEmpty, state: false},
          icon: {test: t =>{return FormContainer.checkIcon (t, true)}, state: false},
        },

      }
    )
  }


  doRender() {
    let style = {}
    if ( this.state.applicantNextStep.color ) {
      style = {color: '#'+this.state.applicantNextStep.color}
    }
    let icon = ''
    if ( this.state.applicantNextStep.icon ) {
      icon = <i className={this.state.applicantNextStep.icon} style={{marginRight: '1em'}}/>
    }
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Etape d'une candidature {this.state.applicantNextStep.name}</h1>
                <FormContainer
                  values={this.state.applicantNextStep}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <div className="form-new-line p-grid">

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <MandatoryLabel htmlFor="name" isMissing={this.state.mandatoryFields.name.state}>Libellé</MandatoryLabel>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <InputText
                          className="form-input"
                          disabled={!this.state.editMode}
                          id="name"
                          autoFocus={true}
                          value={this.state.applicantNextStep.name}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <label htmlFor="defaultValue">Valeur par défaut</label>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <Checkbox
                        className="form-input"
                        disabled={!this.state.editMode}
                        inputId="defaultValue"
                        value="defaultValue"
                        onChange={this.handleCheckBox}
                        checked={this.state.applicantNextStep.defaultValue}
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <MandatoryLabel reminder='' isMissing={this.state.mandatoryFields.icon.state} htmlFor="icon">icône</MandatoryLabel>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <InputText
                          className="form-input"
                          disabled={!this.state.editMode}
                          id="icon"
                          autoFocus={true}
                          value={this.state.applicantNextStep.icon}
                          onChange={this.handleInputText}
                          onBlur={this.cleanUpIcon}
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <label htmlFor="color">Couleur</label>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <Display if={this.state.editMode}>
                          <ColorPicker
                            className="form-input"
                            id="color"
                            value={this.state.applicantNextStep.color}
                            onChange={this.handleInputText}
                          />
                        </Display>
                        <Display if={!this.state.editMode}>
                          <ColorPicker
                            className="form-input"
                            disabled={true}
                            id="color"
                            value={this.state.applicantNextStep.color}
                          />
                        </Display>
                      </div>
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <label>Apperçu</label>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <div style={style}>{icon}{this.state.applicantNextStep.name}</div>
                      </div>
                    </div>

                  </div>

                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ApplicantNextStepForm
