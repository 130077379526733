import React from 'react'
import {AuthenticatedSearch} from '../../components/AuthenticatedSearch'
import {FilterService} from '../../services/FilterService'
import {InputText} from 'primereact/inputtext'
import {Display} from '../../components/Display'
import {ElementSearch} from '../../components/ElementSearch'
import {Fieldset} from 'primereact/fieldset'

export class GlobalSearch extends AuthenticatedSearch {

  constructor (props) {
    super ( props, 'search', '/Tableau de bord', 'Search',

      {
        searchPattern: '',
        sales_filters: {},
        hr_filters: {},
        all_filters: {},
      }
    )

    this.setInChargeFilter = FilterService.setInChargeFilter.bind(this)
    this.setReferenceTeamFilter = FilterService.setReferenceTeamFilter.bind(this)
  }

  /*
   * Initialisation
   */
  async getExternalValues () {
    const sales_filters = this.setInChargeFilter ()
    const hr_filters = this.setReferenceTeamFilter ()
    const all_filters = this.defaultFilters (sales_filters, hr_filters)
    this.setState (
      {
        sales_filters: sales_filters,
        hr_filters: hr_filters,
        all_filters: all_filters,
      }
    )
  }

  /*
   * Global search rendering
   */

  doRender() {
    const results = this.totalResults()
    return (
      <div className="p-grid p-col-12">
        <div className="p-col-12">
          <label>Moteur de recherche globale</label>
        </div>
        <div className="p-col-12">
          <InputText
            className="form-input"
            id="globalSearch"
            autoComplete="off"
            autoFocus={true}
            placeholder="Recherche globale"
            value={this.state.searchPattern}
            onChange={this.handleSearch}
          />
        </div>

        <Display if={this.state.searchPattern}>
          <div className="p-col-12">
            <Fieldset legend="Résultats de recherche">
              <div className="p-grid p-col-12 p-fluid globalsearch-results">

                <Display if={!results}>
                  Aucun élément trouvé.
                </Display>

                <Display if={this.state.profile.unit === 'sales' || this.state.profile.globalManager}>
                  <ElementSearch
                    className="p-col-12 p-sm-6 p-lg-4"
                    title='Besoins'
                    path='/Besoins/'
                    service='Needs'
                    object='need'
                    search={this.state.searchPattern}
                    openForm={this.handleForm}
                    openList={this.handleList}
                    renderResult={(r) => " " + r.company_name + " - " + r.contact_name}
                    updateResults={this.handleResults}
                    filters={this.state.all_filters}
                    apiCall={this.apiCall}
                  />
                </Display>

                <Display if={this.state.profile.unit === 'hr' || this.state.profile.globalManager}>
                  <ElementSearch
                    className="p-col-12 p-sm-6 p-lg-4"
                    title='Demandes'
                    path='/Demandes/'
                    service='Needs'
                    object='request'
                    search={this.state.searchPattern}
                    openForm={this.handleForm}
                    openList={this.handleList}
                    renderResult={(r) => " " + r.company_name}
                    updateResults={this.handleResults}
                    filters={this.state.hr_filters}
                    apiCall={this.apiCall}
                  />
                </Display>

                <Display if={this.state.profile.unit === 'sales' || this.state.profile.globalManager}>
                  <ElementSearch
                    className="p-col-12 p-sm-6 p-lg-4"
                    title='Entreprises'
                    path='/Entreprises/'
                    service='Companies'
                    object='company'
                    search={this.state.searchPattern}
                    openForm={this.handleForm}
                    openList={this.handleList}
                    updateResults={this.handleResults}
                    filters={this.state.sales_filters}
                    apiCall={this.apiCall}
                  />
                </Display>

                <Display if={this.state.profile.unit === 'sales' || this.state.profile.globalManager}>
                  <ElementSearch
                    className="p-col-12 p-sm-6 p-lg-4"
                    title='Interlocuteurs'
                    path='/Interlocuteurs/'
                    service='Contacts'
                    object='contact'
                    search={this.state.searchPattern}
                    openForm={this.handleForm}
                    openList={this.handleList}
                    updateResults={this.handleResults}
                    filters={this.state.sales_filters}
                    apiCall={this.apiCall}
                  />
                </Display>

                <ElementSearch
                  className="p-col-12 p-sm-6 p-lg-4"
                  title='Candidats'
                  path='/Candidats/'
                  service='Applicants'
                  object='applicant'
                  search={this.state.searchPattern}
                  openForm={this.handleForm}
                  openList={this.handleList}
                  updateResults={this.handleResults}
                  filters={this.state.hr_filters}
                  apiCall={this.apiCall}
                />

              </div>
            </Fieldset>
          </div>
        </Display>
      </div>
    )
  }
}

