import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import ApplicantList from './ApplicantList'
import ApplicantForm from './ApplicantForm'
import AdminApplicantList from './AdminApplicantList'

export class Applicants extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Candidats-rencontrés' component={ApplicantList} />
        <Route exact path='/Candidats-vivier' component={ApplicantList} />
        <Route exact path='/Candidats-importés' component={ApplicantList} />
        <Route exact path='/Candidats-embauchés' component={ApplicantList} />
        <Route exact path='/Candidats' component={ApplicantList} />
        <Route path='/Candidats/:id' component={ApplicantForm} />
        <Route exact path='/Archives/Candidats' component={AdminApplicantList} />
      </Switch>
    )
  }
}
