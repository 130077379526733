import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import CompanyList from './CompanyList'
import CompanyForm from './CompanyForm'
import AdminCompanyList from './AdminCompanyList'

export class Companies extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Entreprises' component={CompanyList} />
        <Route path='/Entreprises/:id' component={CompanyForm} />
        <Route exact path='/Archives/Entreprises' component={AdminCompanyList} />
      </Switch>
    )
  }
}
