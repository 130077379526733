import React from 'react'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

export class PersonalContactTable extends AuthenticatedTable {

  constructor(props) {
    super (props, 'personalContact', '/Contacts', 'PersonalContacts')
  }

  handleCreate () {
    super.handleCreate (this.addParameters (this.state.profile.id))
  }


  async getValues (first=0, rows=0, sort=null, filter=null, filters=null) {
    filters = this.defaultFilters (filters, {user_id: {value : this.state.profile.id}})
    return await super.getValues (first, rows, sort, filter, filters)
  }

  doRender() {
    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        onOpen={this.handleOpen}
        onDelete={this.handleDelete}
        onCreate={this.handleCreate}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column field="name" header="Nom" sortable={true} style={{width:'35%'}} />
        <Column field="phone" header="Téléphone" sortable={true} style={{width:'10%'}} />
        <Column field="mobile" header="Mobile" sortable={true} style={{width:'10%'}} />
        <Column field="eMail" header="Courriel." sortable={true} style={{width:'45%'}} />
      </LazyDataTable>
    )
  }
}

