import React from 'react'
import {FilterService} from '../../services/FilterService'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {MailingWizard} from '../../components/MailingWizard'
import {MailingService} from '../../services/MailingService'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {ProgressBar} from 'primereact/progressbar'

export class ContactTable extends AuthenticatedTable {

  constructor(props) {
    super (props, 'contact', '/Interlocuteurs', 'Contacts',
      {
        reparentConfirm: null,
        reparent: {
          companyId: 0,
          contactIds: [],
          duplicates: [],
        }
      }
    )

    this.setInChargeFilter = FilterService.setInChargeFilter.bind(this)

    this.handleReparent = this.handleReparent.bind(this)
    this.doReparent = this.doReparent.bind(this)
    this.companiesGet = this.companiesGet.bind(this)

    this.field_list = MailingService.field_list
    this.template_list = []

  }

  getValues (first=0, rows=0, sort=null, filter=null, filters=null) {
    if ( this.props.companyId ) {
      filters = this.defaultFilters (filters, {company_id: {value : this.props.companyId}})
    }
    // Set access filter
    filters = this.setInChargeFilter (filters)
    const contacts = super.getValues (first, rows, sort, filter, filters)
    return contacts
  }

  async getExternalValues () {
    this.template_list = await this.mailingTemplateList ()
  }

  handleCreate () {
    super.handleCreate (this.addParameters (this.props.companyId))
  }


  batchReparentButton(s, a) {
    return (
      <Button
        className="p-button-warning"
        label="Changer d'entreprise"
        icon="fas fa-shipping-fast"
        onClick={this.handleAdd}
      />
    )
  }

  handleReparent (companyId, selection) {
    this.setState (
      {
        reparentConfirm: 'check',
        reparent: {
          companyId: companyId,
          contactIds: selection,
          duplicates: [],
        }
      }
    )
    return true ;
  }

  companiesGet (pattern, maxValues) {
    maxValues=20
    const filters = {fullQualifiedCompany : {value : pattern}}
    return this.apiCall ('Companies', 'list', 0, maxValues, false, false, filters)

  }

  async checkReparent () {
    const duplicates = await this.apiCall (
      'Companies',
      'checkDuplicates',
      this.props.companyId,
      this.state.reparent.companyId,
      this.state.reparent.contactIds.map(s => s.id)
    )
    if (duplicates.length) {
      this.setState (
        prevState => {
          prevState.reparent.duplicates = duplicates
          return (
            {
              reparent: prevState.reparent,
              reparentConfirm: 'duplicates',
            }
          )
        }
      )
    } else {
      await this.doReparent ()
    }
  }

  async doReparent () {
    this.setState ({reparentConfirm: 'running'})
    const duplicates = this.state.reparent.duplicates.map (d => d.id)
    const contactIds = this.state.reparent.contactIds.filter (c => ! duplicates.includes (c.id))
    await this.apiCall ('Companies', 'addItems', this.state.reparent.companyId, contactIds.map (c => c.id))
    if ( this.state.reparentConfirm ) {
      this.setState ({reparentConfirm:null})
      this.dataTable.loadValues ()
    }
  }

  renderReparentDialog () {
    let title = ''
    let body = null
    let footer = null
    switch ( this.state.reparentConfirm ) {
      case 'running':
        title = "Opétarion en cours ..."
        body = <div>
          <ProgressBar mode="indeterminate" />
        </div>
        footer = <div>
          <Button
            label="Continuer en arrière plan"
            icon="pi pi-arrow-circle-right"
            className="p-button"
            onClick={ () => this.setState ({reparentConfirm:null}) }
          />
        </div>
        break

      case 'check':
        this.checkReparent ()
        title = "Vérification en cours ..."
        body = <div>
          <ProgressBar mode="indeterminate" />
        </div>
        footer = <div>
        </div>
        break

      case 'duplicates':
        title = "Confirmation ..."
        body = <div>
          <p>{
            "La vérification des données à mis en évidence "
            + this.state.reparent.duplicates.length
            + " doublon" + (this.state.reparent.duplicates.length > 1 ? 's' : '') + "."
          }</p>
        </div>
        footer = <div>
          <Button
            label="Continuer sans ces éléments"
            icon="pi pi-check"
            className="p-button-success"
            onClick={this.doReparent}
          />
          <Button
            label="Annuler toute l'opération"
            icon="pi pi-times"
            className="p-button-danger"
            onClick={
              () => {
                this.setState(
                  {
                    reparentConfirm: null,
                    reparent: {
                      companyId: 0,
                      contactIds: [],
                      duplicates: 0,
                    }
                  }
                )
              }
            }
          />
        </div>
        break ;

      default:
        break ;
    }
    return (
      <Dialog
        style={{width: "40%"}}
        header={title}
        visible={!!this.state.reparentConfirm}
        modal={true}
        footer={footer}
        closable={false}
        onHide={() => this.setState({reparentConfirm:null})}
      >
        {body}
      </Dialog>
    )
  }

  doRender() {
    const mailingFrom =
      (this.state.profile.mailingName ? this.state.profile.mailingName : this.state.profile.username)
      + ' <' + (this.state.profile.mailingFrom ? this.state.profile.mailingFrom : this.state.profile.email) + '>'
    return (
      <div>
        <LazyDataTable
          ref={this.setRef}
          locked={this.props.locked}
          onOpen={this.handleOpen}
          onDelete={this.handleDelete}
          onCreate={this.handleCreate}
          createEnabler={() => this.props.companyId}
          onMailing={this.openMailing}
          mailingEnabler={this.enableMailing}
          mailingToPageOnly={true}

          // ------------------------------------------------------------------
          // custom1 is used to implement batch contact's company change
          // We will cheat with onAdd funtion to get the company chooser
          custom1Template={this.batchReparentButton}
          // This one is a fake just used to have a defined function field
          onCustom1={this.handleReparent}
          onAdd={this.handleReparent}
          // Do NOT enable add button, just use the action into Custom1 ;-)
          addEnabler={() => false}
          addItemFieldName="fullQualifiedCompany"
          addItemGet={this.companiesGet}
          addItemTitle="Changement d'entreprise"
          addItemPrompt="Entrez le nom de l'entreprise de destination"
          // ------------------------------------------------------------------

          getValues={this.getValues}
          watch={this.state.profile}
        >
          <Column field="name" header="Nom" sortable={true} style={{width:'22%'}} if={!!this.props.companyId} />
          <Column field="fullQualifiedName" header="Nom" sortable={true} style={{width:'22%'}} if={!this.props.companyId} />

          <Column field="notes" header="Notes" sortable={true} style={{width:'25%'}} body={LazyDataTable.maxLinesTemplate(3)}/>
          <Column field="tools" header="Environnement" sortable={true} style={{width:'25%'}} body={LazyDataTable.maxLinesTemplate(3)}/>
          <Column field="phone" header="Téléphone" sortable={true} style={{width:'9%'}} />
          <Column field="mobile" header="Mobile" sortable={true} style={{width:'9%'}} />
          <Column
            field="eMail"
            header="@"
            headerStyle={{fontSize: '1.3em'}}
            sortable={false}
            style={{width:'1%'}}
            body={LazyDataTable.eMailButtonTemplate (this.openMailing)}
          />
          <Column
            field="socialLink"
            header=<i className="fab fa-linkedin-in"/>
            headerStyle={{fontSize: '1.3em'}}
            sortable={false}
            style={{width:'1%'}}
            body={LazyDataTable.socialLinkTemplate ("fab fa-linkedin-in")}
          />
          <Column
            field="customer"
            header="Client"
            filterMethod={LazyDataTable.optionsFilter}
            filterParams={LazyDataTable.booleanDefaultOptions}
            sortable={true}
            style={{textAlign:'center', width:'7%'}}
            body={LazyDataTable.booleanTemplate}
          />
        </LazyDataTable>
        <MailingWizard
          sender={mailingFrom}
          recipient={this.state.mailing.mailTo}
          fields={this.field_list}
          doMailing={this.state.mailing.open}
          templates={this.template_list}
          getTemplate={this.mailingTemplateGet}
          mailingDone={this.sendMailing}
          maxAttachmentsSize={5000000}
        />
        {this.renderReparentDialog()}
      </div>
    )
  }
}

