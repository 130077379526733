import React, {Component} from 'react'

export class TextAreaToHTML extends Component {

/*
 * Transform TextArea input into HTML compatible childs
 * Mostly consist in converting \n into <br /> elements
 */


  /*
   * Main rendering
   */
  render() {
    if ( typeof (this.props.children) !== 'string' ) {
      return this.props.children
    }
    const lines = this.props.children.split ("\n")
    if (lines.length === 1) {
      return this.props.children
    }
    const children = []
    for (let i=0; i<lines.length; i++) {
      if (i) {
        children.push (<br key={i} />)
      }
      children.push (lines[i])
    }
    return (
      <div>
        {children}
      </div>
    )
  }

}
