import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import SalesEventTypeList from './SalesEventTypeList'
import SalesEventTypeForm from './SalesEventTypeForm'
import HrEventTypeList from './HrEventTypeList'
import HrEventTypeForm from './HrEventTypeForm'

export class EventTypes extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Types/Evénements/sales' component={SalesEventTypeList} />
        <Route path='/Types/Evénements/sales/:id' component={SalesEventTypeForm} />
        <Route exact path='/Types/Evénements/hr' component={HrEventTypeList} />
        <Route path='/Types/Evénements/hr/:id' component={HrEventTypeForm} />
      </Switch>
    )
  }
}
