import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputHtml} from '../../components/InputHtml'
import {InputTextarea} from 'primereact/inputtextarea'
import {Dropdown} from 'primereact/dropdown'
import {Spinner} from 'primereact/spinner'
import '../../components/Forms.css'

class MailingParameterForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'parameters', '/Paramétres/Mailings', 'Parameters',

      {
        parameters: {
          id: 1,
          sales_mailing_eventType: 1,
          hr_mailing_eventType: 1,
          mailing_maxSendBetween: 1,
          mailing_minDaysBetween: 1,
          mailing_signFull: '',
          mailing_signSimple: '',
          mailing_signTextOnly: '',
        },

        sales_mailing_eventType_list: [],
        hr_mailing_eventType_list: [],

        mandatoryFields: {
          sales_mailing_eventType: {test: FormContainer.checkNotEmpty, state: false},
          hr_mailing_eventType: {test: FormContainer.checkNotEmpty, state: false},
          mailing_maxSendBetween: {test: FormContainer.checkNotEmpty, state: false},
          mailing_minDaysBetween: {test: FormContainer.checkNotEmpty, state: false},
        },

      }
    )
  }

  async getExternalValues () {
    // Get events states
    const sales_eventType_list = await this.apiCall ('EventTypes', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, {className: {value : 'sales'}}, true)
    sales_eventType_list.values = sales_eventType_list.values.map (t => {return {label: t.name, value: t.id, ...t}})
    const hr_eventType_list = await this.apiCall ('EventTypes', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, {className: {value : 'hr'}}, true)
    hr_eventType_list.values = hr_eventType_list.values.map (t => {return {label: t.name, value: t.id, ...t}})
    this.setState (
      {
        sales_mailing_eventType_list: sales_eventType_list.values,
        hr_mailing_eventType_list: hr_eventType_list.values,
      }
    )
  }

  doRender() {
    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Paramètres des mailings</h1>
                <FormContainer
                  values={this.state.parameters}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <div className="form-new-line p-grid">

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-3">
                        <MandatoryLabel
                          htmlFor="sales_mailing_eventType"
                          isMissing={this.state.mandatoryFields.sales_mailing_eventType.state}
                        >
                          Evénement associé à un mailing client
                        </MandatoryLabel>
                      </div>
                      <div className="p-col-12 p-md-5">
                        <Dropdown
                          className="form-input"
                          id="sales_mailing_eventType"
                          disabled={!this.state.editMode}
                          options={this.state.sales_mailing_eventType_list}
                          value={this.state.parameters.sales_mailing_eventType}
                          onChange={this.handleSelect}
                          autoWidth={false}
                        />
                      </div >
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-3">
                        <MandatoryLabel
                          htmlFor="hr_mailing_eventType"
                          isMissing={this.state.mandatoryFields.hr_mailing_eventType.state}
                        >
                          Evénement associé à un mailing candidat
                        </MandatoryLabel>
                      </div>
                      <div className="p-col-12 p-md-5">
                        <Dropdown
                          className="form-input"
                          id="hr_mailing_eventType"
                          disabled={!this.state.editMode}
                          options={this.state.hr_mailing_eventType_list}
                          value={this.state.parameters.hr_mailing_eventType}
                          onChange={this.handleSelect}
                          autoWidth={false}
                        />
                      </div >
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-3">
                        <MandatoryLabel
                          htmlFor="mailing_maxSendBetween"
                          isMissing={this.state.mandatoryFields.mailing_maxSendBetween.state}
                        >
                          Nombre maximum d'envoi par campagne
                        </MandatoryLabel>
                      </div>
                      <div className="p-col-12 p-md-5">
                        <Spinner
                          className="form-input"
                          value={this.state.parameters.mailing_maxSendBetween}
                          id="mailing_maxSendBetween"
                          disabled={!this.state.editMode}
                          onChange={this.handleInputInteger}
                          min={0}
                          max={365}
                        />
                      </div >
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-3">
                        <MandatoryLabel
                          htmlFor="mailing_minDaysBetween"
                          isMissing={this.state.mandatoryFields.mailing_minDaysBetween.state}
                        >
                          Nombre de jours minimum entre deux campagnes
                        </MandatoryLabel>
                      </div>
                      <div className="p-col-12 p-md-5">
                        <Spinner
                          className="form-input"
                          value={this.state.parameters.mailing_minDaysBetween}
                          id="mailing_minDaysBetween"
                          disabled={!this.state.editMode}
                          onChange={this.handleInputInteger}
                          min={0}
                          max={365}
                        />
                      </div >
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-3 ">
                        <label htmlFor="mailing_signFull">Signature HTML Complète par défaut</label>
                      </div>
                      <div className="p-col-12 p-md-5">
                        <InputHtml
                          inputId="mailing_signFull"
                          className="form-input"
                          disabled={!this.state.editMode}
                          rows={10}
                          cols={80}
                          value={this.state.parameters.mailing_signFull}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-3 ">
                        <label htmlFor="mailing_signSimple">Signature HTML simplifiée par défaut</label>
                      </div>
                      <div className="p-col-12 p-md-5">
                        <InputHtml
                          inputId="mailing_signSimple"
                          className="form-input"
                          disabled={!this.state.editMode}
                          rows={10}
                          cols={80}
                          value={this.state.parameters.mailing_signSimple}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-3 ">
                        <label htmlFor="mailing_signTextOnly">Signature texte par défaut</label>
                      </div>
                      <div className="p-col-12 p-md-5">
                        <InputTextarea
                          id="mailing_signTextOnly"
                          className="form-input"
                          disabled={!this.state.editMode}
                          autoResize={true}
                          rows={10}
                          cols={80}
                          value={this.state.parameters.mailing_signTextOnly}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                  </div >
                </FormContainer>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default MailingParameterForm
