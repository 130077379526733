import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {Display} from '../../components/Display'
import {Picture} from '../../components/Picture'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {UserTable} from '../users/UserTable'
import {InputText} from 'primereact/inputtext'
//import {Checkbox} from 'primereact/checkbox'
import {Dropdown} from 'primereact/dropdown'
import '../../components/Forms.css'

class TeamForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'team', '/Equipes/Liste', 'Teams',

      {
        team: {
          id: props.match.params.id,
          name: "",
          manager_id: (props.match.params.manager_id ? parseInt (props.match.params.manager_id, 10) : 0),
          cVLibrary: false,
          logo: null,
          accessAll: true,
        },

        constraints: {
          constraintViolationException: {
            team_name: "Nom d'équipe déjà utilisé (Il doit être unique).",
          }
        },

        mandatoryFields: {
          name: {test: FormContainer.checkNotEmpty, state: false},
        },

        manager_list: [],
        isManager: false,

        removeManagerWarning: false,

      }
    )

    this.getNewUserList = this.getNewUserList.bind(this)
  }


  async getExternalValues () {
    // One can not manage a team he belongs
    const filters = {exclude_team_id: {value : this.state.team.id}}
    const manager_list = await this.apiCall ( 'Users', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, filters)
    const isManager = this.state.team.manager_id === this.state.profile.id
    manager_list.values = manager_list.values.map (u => {return {name: u.code + ' (' + u.firstName + ' ' + u.lastName + ')', id: u.id}})
    await this.setState (
      prevState => {
        prevState.team.manager_id = prevState.team.manager_id || prevState.profile.id
        return (
          {
            team: prevState.team,
            manager_list: manager_list.values,
            isManager: prevState.profile.globalManager || isManager,
          }
        )
      }
    )
  }


  async getNewUserList (template) {
    const filters = {
      // Global manager cannot belong to any team
      globalManager : {value: 'f'},
      // User must not already be in any team
      no_team : {value: true},
      // Noone can belongs to the team he manage
      exclude_user_id : {value: this.state.team.manager_id},
    }
    const users = await this.apiCall ('Users', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, filters)
    users.values = users.values.map ( e => { return {id: e.id, name : e.firstName + " " + e.lastName} } )
    return users
  }

  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Equipe {this.state.team.name}</h1>
                <FormContainer
                  values={this.state.team}
                  mandatoryFields={this.state.mandatoryFields}
                  // To be discussed ... locked={!this.state.profile.globalManager}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <div className="form-new-line p-grid">

                    <Display if={this.state.editMode}>
                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <MandatoryLabel htmlFor="name" isMissing={this.state.mandatoryFields.name.state}>Nom</MandatoryLabel>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <InputText
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="name"
                            autoFocus={true}
                            value={this.state.team.name}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpLastName}
                          />
                        </div>
                      </div>

                    </Display>

                    <Display if={this.state.manager_list.length}>
                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <MandatoryLabel htmlFor="manager">Manager</MandatoryLabel>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <Dropdown
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="manager"
                            options={this.state.manager_list.map (c => ({label: c.name, value: c.id}))}
                            value={this.state.team.manager_id}
                            onChange={this.handleExternalSelect}
                            autoWidth={false}
                          />
                        </div >
                      </div>
                    </Display>

                    <Display if={this.state.editMode || this.state.team.logo}>
                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="logo">Logo</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <Picture
                            className="form-input"
                            id="logo"
                            maxWidth="135"
                            maxHeight="50"
                            alt={"Logo associé à l'équipe " + this.state.team.name}
                            locked={!this.state.editMode}
                            update={!this.state.editMode}
                            onChange={this.handlePicture}
                            onLoad={this.getPicture}
                            value={this.state.team.logo}
                          />
                        </div>
                      </div>
                    </Display>

                    {/*
                    <Display if={this.state.profile.globalManager}>
                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="accessAll">Peut accéder à "Tous"</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <Checkbox
                          className="form-input"
                          disabled={!this.state.editMode}
                          inputId="accessAll"
                          value="accessAll"
                          onChange={this.handleCheckBox}
                          checked={this.state.team.accessAll}
                          />
                        </div>
                      </div>
                    </Display>

                    <Display if={this.state.profile.globalManager}>
                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="cVLibrary">Possède sa propre CVthèque</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <Checkbox
                          className="form-input"
                          disabled={!this.state.editMode}
                          inputId="cVLibrary"
                          value="cVLibrary"
                          onChange={this.handleCheckBox}
                          checked={this.state.team.cVLibrary}
                          />
                        </div>
                      </div>
                    </Display>
                    */}

                    <Display if={!this.state.editMode}>
                      <div className="form-new-line p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-3">
                          <label>Utilisateurs</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                        </div>
                        <div className="p-col-12 p-md-10">
                          <UserTable
                            teamId={this.state.team.id}
                            managerId={this.state.team.manager_id}
                            // To be discussed ... locked={this.state.editMode || !this.state.profile.globalManager}
                            locked={this.state.editMode}
                            getContext={this.getContext}
                            onAdd={this.handleAdd}
                            addItemGet={this.getNewUserList}
                            addItemMode="MultiSelect"
                            addItemTitle="Ajouter un utilisateur"
                            addItemPrompt="Choississez l'utilisateur à ajouter"
                            onRemove={this.handleRemove}
                          />
                        </div>
                      </div>
                    </Display>

                  </div>

                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TeamForm
