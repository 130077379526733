import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import PersonRoleList from './PersonRoleList'
import PersonRoleForm from './PersonRoleForm'

export class PersonRoles extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Personnes/Roles' component={PersonRoleList} />
        <Route path='/Personnes/Roles/:id' component={PersonRoleForm} />
      </Switch>
    )
  }
}
