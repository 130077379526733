import React from 'react'
import {UnitService} from '../../services/UnitService'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {Display} from '../../components/Display'
import {InputHtml} from '../../components/InputHtml'
import {TabView,TabPanel} from 'primereact/tabview'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {TeamTable} from '../teams/TeamTable'
import {Dialog} from 'primereact/dialog'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {Checkbox} from 'primereact/checkbox'
import {Dropdown} from 'primereact/dropdown'
import {Password} from 'primereact/password'
import '../../components/Forms.css'

class UserForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'user', '/Utilisateurs', 'Users',

      {

        user: {
          id: props.match.params.id,
          firstName: "",
          lastName: "",
          eMail: "",
          phone: "",
          mobile: "",
          webCard: "",

          unit: "",
          function: "",

          code: "",
          password: "",

          mailingName: "",
          mailingFrom: "",
          mailingSignFull: "",
          mailingSignSimple: "",
          mailingSignTextOnly: "",

          team_id: (props.match.params.team_id ? parseInt (props.match.params.team_id, 10) : 0),
          homeCrew_id: null,
          globalManager: false,
        },

        constraints: {
          constraintViolationException: {
            user_email: "Courriel utilisateur déjà utilisé (Il doit être unique car il sert d'identifiant).",
            user_full_name: 'Utilisateur déjà créé (Le couple "nom prénom" doit être unique).',
            user_code: () => {return 'Le code "' + this.state.user.code + '" est déjà utilisé (Il doit être unique).'},
          }
        },

        mandatoryFields: {
          firstName: {test: FormContainer.checkNotEmpty, state: false},
          lastName: {test: FormContainer.checkNotEmpty, state: false},
          eMail: {test: FormContainer.checkEmail, state: false},
          function: {test: FormContainer.checkNotEmpty, state: false},
          code: {test: FormContainer.checkNotEmpty, state: false},
          unit: {test: FormContainer.checkNotEmpty, state: false},
          password: {test: FormContainer.checkNotEmpty, state: false},
        },

        team_list: [],
        unit_list: UnitService.unit_list,

        globalParameters: {},

        globalManagerWarning: false,
        removeManagerError: false,
      }
    )

    this.getNewTeamList = this.getNewTeamList.bind(this)
    this.handleGlobalManager = this.handleGlobalManager.bind(this)
    this.handleHomeCrewChange = this.handleHomeCrewChange.bind(this)
    this.setDefaultTemplate= this.setDefaultTemplate.bind(this)
  }

  /*
   * Form data management
   */

  async getExternalValues () {
    // One can not belongs to a team he manage
    let filters = {exclude_manager_id: {value : this.state.user.id}}
    const team_list = await this.apiCall ( 'Teams', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, filters)

    // Get parameters
    const globalParameters = await this.getGlobalParameters ()

    await this.setState (
      {
        team_list: team_list.values,
        globalParameters: globalParameters,
      }
    )
    return true
  }

  async getNewTeamList () {
    const filters = {
      exclude_manager_id : {value : this.state.user.id},
      exclude_member_id : {value : this.state.user.id},
    }
    const teams = await this.apiCall ('Teams', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, filters)
    return teams
  }

  async setValues () {
    const ret = await super.setValues ()
    if ( this.state.user.id === this.state.profile.id ) {
      setTimeout(() => this.reload (0), 0)
    }
    return ret
  }

  async handleGlobalManager (e) {
    if (! e.checked && this.state.user.id === this.state.profile.id) {
      this.setState ({globalManagerWarning: true})
    }
    this.handleCheckBox (e)
  }

  handleHomeCrewChange (homeCrew) {
    this.setState (
      prevState => {
        prevState.user.homeCrew_id = homeCrew
        return {user: prevState.user}
      },
      this.setValues
    )
  }

  handleRemove (ids) {
    if ( ids.includes (this.state.user.homeCrew_id) ) {
      this.setState (
        prevState => {
          prevState.user.homeCrew_id = null
          return {user: prevState.user}
        }
      )
    }
    return super.handleRemove (ids)
  }

  setDefaultTemplate (templateName) {
    // Get template name and associated fields
    const localField = 'mailingSign' + templateName
    const globalField = 'mailing_sign' + templateName

    // Inject user values into template
    let template = this.state.globalParameters[globalField]
    const token = template.match (/{[^}]+}/g)
    if ( token ) {
      for (let i=0; i<token.length; i++) {
        const field = token[i].replace (/[{}]/g, '')
        if ( field && this.state.user[field] ) {
          template = template.replace (token[i], this.state.user[field])
        } else {
          // Prevent from endless loop
          template = template.replace (token[i], '')
        }
      }
    }

    // Replace local version with default
    this.setState (
      prevState => {
        prevState.user[localField] = template
        return {user: prevState.user}
      }
    )
  }

  doRender() {
    let fullName = ''
    if ( this.state.user.firstName ) {
      fullName = this.state.user.firstName
    }
    if ( this.state.user.lastName ) {
      fullName += (fullName?' ':'') + this.state.user.lastName
    }
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Utilisateur {fullName}</h1>
                <FormContainer
                  values={this.state.user}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >

                  <TabView>
                      <TabPanel header="Informations" rightIcon="pi pi-info">
                        <div className="form-new-line p-grid">

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="firstName" isMissing={this.state.mandatoryFields.firstName.state}>Prénom</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                disabled={!this.state.editMode}
                                id="firstName"
                                autoFocus={true}
                                value={this.state.user.firstName}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpFirstName}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="lastName" isMissing={this.state.mandatoryFields.lastName.state}>Nom</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                disabled={!this.state.editMode}
                                id="lastName"
                                value={this.state.user.lastName}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpLastName}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="eMail" isMissing={this.state.mandatoryFields.eMail.state}>Courriel</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                disabled={!this.state.editMode}
                                id="eMail"
                                value={this.state.user.eMail}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpEmail}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="phone">Téléphone</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="phone"
                                disabled={!this.state.editMode}
                                value={this.state.user.phone}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpIntlPhoneNumber}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="mobile">Mobile</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="mobile"
                                disabled={!this.state.editMode}
                                value={this.state.user.mobile}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpIntlPhoneNumber}
                              />
                            </div>
                          </div>

                          <div className="p-grid p-col-12 p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="unit" isMissing={this.state.mandatoryFields.unit.state}>Pôle</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <Dropdown
                                className="form-input"
                                disabled={!this.state.editMode || (this.state.user.id === this.state.profile.id && !this.state.profile.globalManager)}
                                id="unit"
                                options={this.state.unit_list}
                                value={this.state.user.unit}
                                onChange={this.handleSelect}
                                autoWidth={false}
                              />
                            </div >
                          </div >


                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="function" isMissing={this.state.mandatoryFields.function.state}>Fonction</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                disabled={!this.state.editMode || (this.state.user.id === this.state.profile.id && !this.state.profile.globalManager)}
                                id="function"
                                value={this.state.user.function}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="code" isMissing={this.state.mandatoryFields.code.state}>Code</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                disabled={!this.state.editMode || (this.state.user.id === this.state.profile.id && !this.state.profile.globalManager)}
                                id="code"
                                value={this.state.user.code}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpLastName}
                              />
                            </div>
                          </div>

                          <Display if={this.state.editMode}>
                            <div className="p-col-12 p-grid p-justify-even">
                              <div className="p-col-12 p-md-2">
                                <MandatoryLabel htmlFor="password" isMissing={this.state.mandatoryFields.password.state}>Mot de passe</MandatoryLabel>
                              </div>
                              <div className="p-col-12 p-md-6">
                                <Password
                                  className="form-input"
                                  disabled={!this.state.editMode}
                                  id="password"
                                  feedback={true}
                                  promptLabel='Qualité de votre mot de passe ...'
                                  weakLabel='Mot de passe vulnérable'
                                  mediumLabel='Mot de passe acceptable'
                                  strongLabel='Mot de passe excellent'
                                  value={this.state.user.password}
                                  onChange={this.handleInputText}
                                />
                              </div>
                            </div>
                          </Display>

                          <Display if={this.state.profile.globalManager}>
                            <div className="p-col-12 p-grid p-justify-even">
                              <div className="p-col-12 p-md-2">
                                <label htmlFor="globalManager">Manager global</label>
                              </div>
                              <div className="p-col-12 p-md-6">
                                <Checkbox
                                className="form-input"
                                disabled={!this.state.editMode || this.state.user.id === this.state.profile.id}
                                inputId="globalManager"
                                value="globalManager"
                                onChange={this.handleGlobalManager}
                                checked={this.state.user.globalManager}
                                />
                              </div>
                            </div>
                            <Dialog
                              header="Avertissement ..."
                              visible={this.state.globalManagerWarning}
                              style={{width: '50vw'}}
                              modal={true}
                              onHide={() => this.setState({globalManagerWarning: false})}
                            >
                              Si vous désactivez cette option sur votre propre compte, vous ne serez plus en mesure de l'activer après avoir quité le formulaire.
                            </Dialog>
                          </Display>


                          <Display if={this.state.team_list.length && !this.state.user.globalManager}>
                            <div className="p-col-12 p-grid p-justify-even">
                              <div className="p-col-12 p-md-2">
                                <MandatoryLabel htmlFor="team">Membre de l'équipe</MandatoryLabel>
                              </div>
                              <div className="p-col-12 p-md-6">
                                <Dropdown
                                  className="form-input"
                                  disabled={!this.state.editMode || this.state.user.id === this.state.profile.id}
                                  id="team"
                                  options={this.state.team_list.map (c => ({label: c.name, value: c.id}))}
                                  value={this.state.user.team_id}
                                  onChange={this.handleExternalSelect}
                                  autoWidth={false}
                                />
                              </div >
                            </div>
                          </Display>

                          <div className="form-new-line p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-3">
                              <label>Equipes managées</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                            </div>
                            <div className="p-col-12 p-md-10">
                              <TeamTable
                                noAutoFocus={this.state.editMode}
                                userId={this.state.user.id}
                                homeCrewId={this.state.user.homeCrew_id}
                                onHomeCrewChange={this.handleHomeCrewChange}
                                locked={this.state.editMode}
                                getContext={this.getContext}
                                onAdd={this.handleAdd}
                                addItemGet={this.getNewTeamList}
                                addItemMode="MultiSelect"
                                addItemTitle="Ajouter une équipe"
                                addItemPrompt="Choississez l'équipe à ajouter"
                                onRemove={this.handleRemove}
                              />
                            </div>
                          </div>
                        </div>

                      </TabPanel>
                      <TabPanel header="Mailing" rightIcon="pi pi-envelope">
                        <div className="form-new-line p-grid">

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-3">
                              <label htmlFor="mailingFrom">Nom de substitution pour les mailings</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                disabled={!this.state.editMode}
                                id="mailingName"
                                value={this.state.user.mailingName}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-3">
                              <label htmlFor="mailingFrom">Courriel de substitution pour les mailings</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                disabled={!this.state.editMode}
                                id="mailingFrom"
                                value={this.state.user.mailingFrom}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpEmail}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-3">
                              <label htmlFor="webCard">Carte de visite internet</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="webCard"
                                disabled={!this.state.editMode}
                                value={this.state.user.webCard}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even p-align-start">
                            <div className="p-col-12 p-md-3 p-grid p-nogutter">
                              <div className="p-col-12">
                                <label htmlFor="mailingSignFull">Signature HTML Complète</label>
                              </div>
                              <Display if={this.state.editMode}>
                                <div className="p-col-10" style={{marginTop: '1em'}} onClick={() => this.setDefaultTemplate ('Full')}>
                                  <i className="fas fa-stop form-fa-button"></i>
                                  <label>&nbsp;Utiliser le modèle par défaut</label>
                                </div>
                              </Display>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputHtml
                              inputId="mailingSignFull"
                              disabled={!this.state.editMode}
                              rows={10}
                              cols={80}
                              value={this.state.user.mailingSignFull}
                              onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even p-align-start">
                            <div className="p-col-12 p-md-3 p-grid p-nogutter">
                              <div className="p-col-12">
                                <label htmlFor="mailingSignSimple">Signature HTML simplifiée</label>
                              </div>
                              <Display if={this.state.editMode}>
                                <div className="p-col-10" style={{marginTop: '1em'}} onClick={() => this.setDefaultTemplate ('Simple')}>
                                  <i className="fas fa-stop form-fa-button"></i>
                                  <label>&nbsp;Utiliser le modèle par défaut</label>
                                </div>
                              </Display>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputHtml
                              inputId="mailingSignSimple"
                              className="form-input"
                              disabled={!this.state.editMode}
                              rows={10}
                              cols={80}
                              value={this.state.user.mailingSignSimple}
                              onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even p-align-start">
                            <div className="p-col-12 p-md-3 p-grid p-nogutter">
                              <div className="p-col-12">
                                <label htmlFor="mailingSignTextOnly">Signature texte</label>
                              </div>
                              <Display if={this.state.editMode}>
                                <div className="p-col-10" style={{marginTop: '1em'}} onClick={() => this.setDefaultTemplate ('TextOnly')}>
                                  <i className="fas fa-stop form-fa-button"></i>
                                  <label>&nbsp;Utiliser le modèle par défaut</label>
                                </div>
                              </Display>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputTextarea
                              id="mailingSignTextOnly"
                              className="form-input"
                              disabled={!this.state.editMode}
                              autoResize={true}
                              rows={10}
                              cols={80}
                              value={this.state.user.mailingSignTextOnly}
                              onChange={this.handleInputText}
                              />
                            </div>
                          </div>


                        </div>
                      </TabPanel>

                  </TabView>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserForm
