import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import DegreeList from './DegreeList'
import DegreeForm from './DegreeForm'

export class Degrees extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Personnes/Diplômes' component={DegreeList} />
        <Route path='/Personnes/Diplômes/:id' component={DegreeForm} />
      </Switch>
    )
  }
}
