import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {Display} from '../../components/Display'
import {StretchBox} from '../../components/StretchBox'
import {Checkbox} from 'primereact/checkbox'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {TextAreaToHTML} from '../../components/TextAreaToHTML'
import {Attachments} from '../../components/Attachments'
import {EventScroller} from './EventScroller'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {Dropdown} from 'primereact/dropdown'
import {Slider} from 'primereact/slider'
import {ProgressBar} from 'primereact/progressbar'
import {Fieldset} from 'primereact/fieldset'
import {MailingWizard} from '../../components/MailingWizard'
import {MailingService} from '../../services/MailingService'

import '../../components/Forms.css'
class ApplicantForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'applicant', '/Candidats', 'Applicants',

      {

        applicant: {
          id: props.match.params.id,
          prefix_id: 0,
          prefix: "",
          firstName: "",
          name: "",
          lastName: "",
          phone: "",
          mobile: "",
          eMail: "",
          noMailing: false,
          socialLink: "",
          address: "",
          zip: "",
          city: "",
          mobility_id: 0,
          mobility: "",
          degree_id: 0,
          degree: "",
          englishLevel_id: 0,
          englishLevel: "",
          experience_id: 0,
          experience: "",
          birthYear: "",
          legalStatute_id: 0,
          legalStatute: "",
          qualification: "",
          origin_id: 0,
          origin: "",
          formerCompany: "",
          formerRole_id: 0,
          formerRole: "",
          formerSalary: "",
          availability: "",
          soughtRole_id: 0,
          soughtRole: "",
          salaryExpectations: "",
          match: 0,
          state_id: 0,
          state: "",
          nextStep_id: 0,
          nextStep: "",
          otherApplication: "",
          notes: "",

        },

        constraints: {
          constraintViolationException: {
            applicant_name_email: "Email, nom et prénom d'interlocuteur déjà utilisés (Il doivent être uniques).",
          }
        },

        mandatoryFields: {
          firstName: {test: FormContainer.checkNotEmpty, state: false},
          lastName: {test: FormContainer.checkNotEmpty, state: false},
          eMail: {test: t => FormContainer.checkEmail (t, true), state: false},
          phone: {test: t => FormContainer.checkIntlPhoneNumber (t, true), state: false},
          //phone: {test: t => FormContainer.checkFrenchPhoneNumber (t, true), state: false},
          mobile: {test: t => FormContainer.checkIntlPhoneNumber (t, true), state: false},
          //mobile: {test: t => FormContainer.checkFrenchPhoneNumber (t, true), state: false},
          zip: {test: t => FormContainer.checkZipCode (t, true), state: false},
          formerSalary: {test: t => FormContainer.checkEuro (t, true), state: false},
          salaryExpectations: {test: t => FormContainer.checkEuro (t, true), state: false},
          birthYear: {test: t => FormContainer.checkBirthYear (t, true), state: false},
        },

        prefix_list: [],
        formerRole_list: [],
        soughtRole_list: [],
        mobility_list: [],
        degree_list: [],
        englishLevel_list: [],
        experience_list: [],
        legalStatute_list: [],
        origin_list: [],
        state_list: [],
        nextStep_list: [],

      }
    )

    //  Local specifics handlers
    this.field_list = MailingService.field_list
    this.template_list = []

  }


  /*
   * Form data management
   */

  cleanUpValues (values) {
    // Salaries are transmited as real numbers without any currency format
    const formatter = new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 0, style: 'currency', currency: 'EUR' })
    const formerSalary = parseFloat(String(values.formerSalary).replace(',','.').replace(/[\s€]/g,''))
    values.formerSalary = formatter.format(isNaN(formerSalary)?0.0:formerSalary)
    const salaryExpectations = parseFloat(String(values.salaryExpectations).replace(',','.').replace(/[\s€]/g,''))
    values.salaryExpectations = formatter.format(isNaN(salaryExpectations)?0.0:salaryExpectations)
    values.birthYear = values.birthYear ?? ""
  }




  async getExternalValues () {

    // Populate dropdown selectors options
    const [
      prefix_list,
      role_list,
      mobility_list,
      degree_list,
      englishLevel_list,
      experience_list,
      legalStatute_list,
      origin_list,
      state_list,
      nextStep_list
    ] = await Promise.all (
      [
        this.apiCall ('PersonCivilities', 'list'),
        this.apiCall ('Roles', 'list'),
        this.apiCall ('Mobilities', 'list'),
        this.apiCall ('Degrees', 'list'),
        this.apiCall ('EnglishLevels', 'list'),
        this.apiCall ('Experiences', 'list'),
        this.apiCall ('LegalStatutes', 'list'),
        this.apiCall ('ApplicantOrigins', 'list'),
        this.apiCall ('ApplicantStates', 'list'),
        this.apiCall ('ApplicantNextSteps', 'list'),
      ]
    )

    // Get default values
    const defaultMobility = this.defaultOption (mobility_list)
    const defaultDegree = this.defaultOption (degree_list)
    const defaultEnglishLevel = this.defaultOption (englishLevel_list)
    const defaultExperience = this.defaultOption (experience_list)
    const defaultLegalStatute = this.defaultOption (legalStatute_list)
    const defaultOrigin = this.defaultOption (origin_list)
    const defaultState = this.defaultOption (state_list)
    const defaultNextStep = this.defaultOption (nextStep_list)

    // Set default values and lists
    await this.setState (
      prevState => {
        prevState.applicant.mobility_id = prevState.applicant.mobility_id || defaultMobility.id
        prevState.applicant.mobility = prevState.applicant.mobility || defaultMobility.name
        prevState.applicant.degree_id = prevState.applicant.degree_id || defaultDegree.id
        prevState.applicant.degree = prevState.applicant.degree || defaultDegree.name
        prevState.applicant.englishLevel_id = prevState.applicant.englishLevel_id || defaultEnglishLevel.id
        prevState.applicant.englishLevel = prevState.applicant.englishLevel || defaultEnglishLevel.name
        prevState.applicant.experience_id = prevState.applicant.experience_id || defaultExperience.id
        prevState.applicant.experience = prevState.applicant.experience || defaultExperience.name
        prevState.applicant.legalStatute_id = prevState.applicant.legalStatute_id || defaultLegalStatute.id
        prevState.applicant.legalStatute = prevState.applicant.legalStatute || defaultLegalStatute.name
        prevState.applicant.origin_id = prevState.applicant.origin_id || defaultOrigin.id
        prevState.applicant.origin = prevState.applicant.origin || defaultOrigin.name
        prevState.applicant.state_id = prevState.applicant.state_id || defaultState.id
        prevState.applicant.state = prevState.applicant.state || defaultState.name
        prevState.applicant.nextStep_id = prevState.applicant.nextStep_id || defaultNextStep.id
        prevState.applicant.nextStep = prevState.applicant.nextStep || defaultNextStep.name

        return {
          prefix_list: prefix_list.values,
          formerRole_list: role_list.values,
          mobility_list: mobility_list.values,
          soughtRole_list: role_list.values,
          degree_list: degree_list.values,
          englishLevel_list: englishLevel_list.values,
          experience_list: experience_list.values,
          legalStatute_list: legalStatute_list.values,
          origin_list: origin_list.values,
          state_list: state_list.values,
          nextStep_list: nextStep_list.values,
          applicant: prevState.applicant,
        }
      }
    )

    // Get Mailling template list
    this.template_list = await this.mailingTemplateList ()
  }



  doRender() {
    /* Header */
    let state = ""
    const s = this.state.state_list.find (s => s.id === this.state.applicant.state_id)
    if (s) {
      state = <div style={{color: '#'+s['color']}}><i className={s['icon']} style={{marginRight: '1em'}}/>{s.name}</div>
    }
    let nextStep = ""
    const n = this.state.nextStep_list.find (n => n.id === this.state.applicant.nextStep_id)
    if (n) {
      nextStep = <div style={{color: '#'+n['color']}}><i className={n['icon']} style={{marginRight: '1em'}}/>{n.name}</div>
    }

    /* Contact */
    let title = this.state.applicant.prefix ?? ""
    title += title ? "  " : ""
    title += this.state.applicant.lastName + " " + this.state.applicant.firstName

    let address = this.state.applicant.address
    address += address ? " " : ""
    address += this.state.applicant.zip
    address += address ? " " : ""
    address += this.state.applicant.city

    /* Age */
    let applicantAge = "Age inconnu"
    if ( this.state.applicant.birthYear ) {
      const age = new Date().getFullYear() - this.state.applicant.birthYear
      applicantAge = age + " ans"
    }

    const mailFilters = {mailingIds: {value: [this.state.applicant.id]}}

    let mailTo = ''
    if (this.state.applicant.firstName) {
      mailTo = this.state.applicant.firstName
    }
    if (this.state.applicant.lastName) {
      mailTo += mailTo ? (' ' + this.state.applicant.lastName) : this.state.applicant.lastName
    }
    mailTo += mailTo ? (' <' + this.state.applicant.eMail  + '>') : this.state.applicant.eMail

    const mailFrom =
      (this.state.profile.mailingName ? this.state.profile.mailingName : this.state.profile.username)
      + ' <' + (this.state.profile.mailingFrom ? this.state.profile.mailingFrom : this.state.profile.email) + '>'


    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>
                  <div className="p-grid p-justify-between">
                    <div className="p-col-12 p-md-5">
                      <Display if={this.state.applicant.socialLink}>
                        <a href={this.state.applicant.socialLink} target="_blank" rel="noopener noreferrer">
                          <b><i className="fa-border fa-pull-left fab fa-linkedin-in"></i></b>
                        </a>
                      </Display>
                        <b>{title}</b>
                    </div>
                    <div className="p-col-12 p-md-2 form-text-align-md-center">
                      {state}
                    </div>
                    <div className="p-col-12 p-md-3 form-text-align-md-center">
                      {nextStep}
                    </div>
                    <div className="p-col-12 p-md-1 form-text-align-md-center">
                      <ProgressBar style={{marginTop: '0.5em'}} value={this.state.applicant.match}/>
                    </div>
                  </div>
                </h1>
                <FormContainer
                  values={this.state.applicant}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <div className="p-grid">


                      {/*
                          -----------------------------------------------------------------------

                                                   DISPLAY ONYL MODE

                          ------------------------------------------------------------------------
                      */}

                      <Display if={!this.state.editMode && this.state.applicant.id !== '0'}>

                        <div className="p-col-12 p-grid">
                          <div className="p-col-12 p-md-4">
                            <b>{applicantAge}</b>
                          </div>
                          <div className="p-col-12 p-md-4 form-text-align-md-center">
                            <b>{this.state.applicant.degree} - {this.state.applicant.experience} - Anglais {this.state.applicant.englishLevel}</b>
                          </div>
                          <div className="p-col-12 p-md-4 form-text-align-md-right">
                            <b>Origine : {this.state.applicant.origin}</b>
                          </div>

                          <div className="p-col-12 p-md-4">
                            <b><i className="pi pi-envelope"></i></b>
                            {address || 'Non spécifié.'}
                          </div>
                          <div className="p-col-12 p-md-2 form-text-align-md-center">
                            <b><i className="fas fa-phone"></i> </b>
                            {this.state.applicant.phone || 'Non spécifié.'}
                          </div>
                          <div className="p-col-12 p-md-2 form-text-align-md-center">
                            <b><i className="fas fa-mobile-alt"></i> </b>
                            {this.state.applicant.mobile || 'Non spécifié.'}
                          </div>
                          <Display if={this.state.applicant.eMail}>
                            <div
                              className="p-col-12 p-md-4 form-text-align-md-right"
                              style = {{cursor: 'pointer'}}
                              onClick={() => this.openMailing (null, null, mailFilters, mailTo)}
                            >
                              <span className="fa-stack" style={{verticalAlign: 'top', marginTop: '-2px', marginLeft: '-5px'}}>
                                <b><i className="fas fa-square fa-stack-2x" style={{color: '#339af0'}}></i> </b>
                                <b><i className="fas fa-at fa-stack-1x fa-inverse"></i> </b>
                              </span>
                              {this.state.applicant.eMail}
                            </div>
                          </Display>
                          <Display if={!this.state.applicant.eMail}>
                            <div className="p-col-12 p-md-4 form-text-align-md-right">
                              <b><i className="fas fa-at"></i> </b> Non spécifié.
                            </div>
                          </Display>


                          <div className="p-col-12 p-md-2 p-md-offset-1">
                            Poste actuel : {this.state.applicant.formerRole}
                          </div>
                          <div className="p-col-12 p-md-2 form-text-align-md-right">
                            Salaire actuel : {this.state.applicant.formerSalary}
                          </div>
                          <div className="p-col-12 p-md-2 p-md-offset-1">
                            Société : {this.state.applicant.formerCompany}
                          </div>
                          <div className="p-col-12 p-md-3">
                            Disponibilité : {this.state.applicant.availability}
                          </div>

                          <div className="p-col-12 p-md-2 p-md-offset-1">
                             Poste rechérché : {this.state.applicant.soughtRole}
                          </div>
                          <div className="p-col-12 p-md-2 form-text-align-md-right">
                            Salaire demandé : {this.state.applicant.salaryExpectations}
                          </div>
                          <div className="p-col-12 p-md-2 p-md-offset-1">
                            Statut : {this.state.applicant.legalStatute}
                          </div>
                          <div className="p-col-12 p-md-3">
                            Mobilité : {this.state.applicant.mobility}
                          </div>

                        </div>

                        <Display if={this.state.applicant.qualification}>
                          <div className="p-col-12 p-grid">
                            <div className="p-col-12">
                              <Fieldset legend="Compétences techniques et fonctionnelles">
                                <TextAreaToHTML>{this.state.applicant.qualification}</TextAreaToHTML>
                              </Fieldset>
                            </div>
                          </div>
                        </Display>
                        <Display if={this.state.applicant.notes}>
                          <div className="p-col-12 p-grid">
                            <div className="p-col-12">
                              <Fieldset legend="Notes">
                                <TextAreaToHTML>{this.state.applicant.notes}</TextAreaToHTML>
                              </Fieldset>
                            </div>
                          </div>
                        </Display>
                        <Display if={this.state.applicant.otherApplication}>
                          <div className="p-col-12 p-grid">
                            <div className="p-col-12">
                              <Fieldset legend="Autres candidatures">
                                {this.state.applicant.otherApplication}
                              </Fieldset>
                            </div>
                          </div>
                        </Display>

                        <StretchBox className="p-col-12 p-grid" id="scroller-container" until=".layout-footer">

                          <div className="p-col-12 p-md-8">
                            <EventScroller
                              getContext={() => this.context.get()}
                              objectId={this.state.applicant.id}
                              inChargeId={this.state.profile.id}
                            />
                          </div>

                          <div className="p-col-12 p-md-4">
                            <div className="p-col-12" style={{marginBottom: '0.25em'}}>
                              <label htmlFor="attachments">CVs et pièces jointes</label>
                            </div>
                            <Attachments
                              id="attachments"
                              rows={5}
                              locked={this.state.editMode}
                              getValues={this.getAttachmentsValuesFromObject}
                              getVersions={this.getAttachmentVersions}
                              setVersions={this.setAttachmentVersions}
                              delete={this.deleteAttachment}
                              doUpload={this.fileUpload}
                              doDownload={this.fileDownload}
                              watch={this.state.profile}
                            >
                            </Attachments>
                          </div>

                        </StretchBox>

                        <MailingWizard
                          sender={mailFrom}
                          recipient={this.state.mailing.mailTo}
                          fields={this.field_list}
                          doMailing={this.state.mailing.open}
                          templates={this.template_list}
                          getTemplate={this.mailingTemplateGet}
                          mailingDone={this.sendMailing}
                          maxAttachmentsSize={5000000}
                        />

                      </Display>

                      {/*
                          -----------------------------------------------------------------------

                                                      EDITION MODE

                          ------------------------------------------------------------------------
                      */}


                      <Display if={this.state.editMode}>


                        {/* Name group */}
                        <div className="form-new-line p-col-12 p-grid p-md-10 p-md-offset-1 p-justify-between p-nogutter">

                          <div className="p-col-12 p-grid p-md-2">
                            <div className="p-col-12">
                              <label htmlFor="prefix">Préfixe</label>
                            </div>
                            <div className="p-col-12">
                              <Dropdown
                                className="form-input"
                                id="prefix"
                                options={this.state.prefix_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.applicant.prefix_id}
                                onChange={this.handleExternalSelect}
                                showClear={false}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid p-md-5">
                            <div className="p-col-12">
                              <MandatoryLabel htmlFor="firstName" isMissing={this.state.mandatoryFields.firstName.state}>Prénom</MandatoryLabel>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="firstName"
                                autoFocus={true}
                                value={this.state.applicant.firstName}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpFirstName}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-md-5">
                            <div className="p-col-12">
                              <MandatoryLabel htmlFor="lastName" isMissing={this.state.mandatoryFields.lastName.state}>Nom</MandatoryLabel>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="lastName"
                                value={this.state.applicant.lastName}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpLastName}
                              />
                            </div>
                          </div>

                        </div>


                        {/* Media group */}
                        <div className="p-col-12 p-grid p-md-10 p-md-offset-1 p-justify-between p-nogutter">

                          <div className="p-col-12 p-grid p-md-2">
                            <div className="p-col-12">
                              <MandatoryLabel htmlFor="phone" isMissing={this.state.mandatoryFields.phone.state} reminder="">Téléphone</MandatoryLabel>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="phone"
                                value={this.state.applicant.phone}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpIntlPhoneNumber}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-md-2">
                            <div className="p-col-12">
                              <MandatoryLabel htmlFor="mobile" isMissing={this.state.mandatoryFields.mobile.state} reminder="">Mobile</MandatoryLabel>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="mobile"
                                value={this.state.applicant.mobile}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpIntlPhoneNumber}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-md-4">
                            <div className="p-col-12">
                              <MandatoryLabel htmlFor="eMail" isMissing={this.state.mandatoryFields.eMail.state} reminder="">Courriel</MandatoryLabel>
                              <label htmlFor="noMailing"> (Ne pas envoyer de mailing </label>
                              <Checkbox
                              className="form-input"
                              inputId="noMailing"
                              value="noMailing"
                              onChange={this.handleCheckBox}
                              checked={this.state.applicant.noMailing}
                              />
                              <label> )</label>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="eMail"
                                value={this.state.applicant.eMail}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpEmail}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-md-4">
                            <div className="p-col-12">
                              <label htmlFor="socialLink">LinkedIn ou site social</label>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="socialLink"
                                value={this.state.applicant.socialLink}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpUrl}
                              />
                            </div>
                          </div>

                        </div>


                        {/* Address group */}
                        <div className="p-col-12 p-grid p-md-10 p-md-offset-1 p-justify-between p-nogutter">

                          <div className="p-col-12 p-grid p-md-7">
                            <div className="p-col-12">
                              <label htmlFor="address">Adresse</label>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="address"
                                value={this.state.applicant.address}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-md-1">
                            <div className="p-col-12">
                              <MandatoryLabel htmlFor="zip" isMissing={this.state.mandatoryFields.zip.state} reminder="">Code postal</MandatoryLabel>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="zip"
                                value={this.state.applicant.zip}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpZipCode}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-md-4">
                            <div className="p-col-12">
                              <label htmlFor="city">Ville</label>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="city"
                                value={this.state.applicant.city}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpLastName}
                              />
                            </div>
                          </div>

                        </div>


                        {/* Qualification group */}
                        <div className="p-col-12 p-grid p-md-10 p-md-offset-1 p-justify-between p-nogutter">

                          <div className="p-col-12 p-grid p-md-2">
                            <div className="p-col-12">
                              <label htmlFor="degree">Diplôme</label>
                            </div>
                            <div className="p-col-12">
                              <Dropdown
                                className="form-input"
                                id="degree"
                                options={this.state.degree_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.applicant.degree_id}
                                onChange={this.handleExternalSelect}
                                showClear={false}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid p-md-2">
                            <div className="p-col-12">
                              <label htmlFor="experience">Expérience</label>
                            </div>
                            <div className="p-col-12">
                              <Dropdown
                                className="form-input"
                                id="experience"
                                options={this.state.experience_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.applicant.experience_id}
                                onChange={this.handleExternalSelect}
                                showClear={false}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid p-md-2">
                            <div className="p-col-12">
                              <label htmlFor="englishLevel">Niveau d'anglais</label>
                            </div>
                            <div className="p-col-12">
                              <Dropdown
                                className="form-input"
                                id="englishLevel"
                                options={this.state.englishLevel_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.applicant.englishLevel_id}
                                onChange={this.handleExternalSelect}
                                showClear={false}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid p-md-2">
                            <div className="p-col-12">
                              <MandatoryLabel
                                htmlFor="birthYear"
                                isMissing={this.state.mandatoryFields.birthYear.state}
                                reminder=""
                              >
                                Année de naissance
                              </MandatoryLabel>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="birthYear"
                                disabled={!this.state.editMode}
                                value={this.state.applicant.birthYear}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div >

                          <div className="p-col-12 p-grid p-md-2">
                            <div className="p-col-12">
                              <label htmlFor="mobility">Mobilité</label>
                            </div>
                            <div className="p-col-12">
                              <Dropdown
                                className="form-input"
                                id="mobility"
                                options={this.state.mobility_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.applicant.mobility_id}
                                onChange={this.handleExternalSelect}
                                showClear={false}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid p-md-2">
                            <div className="p-col-12">
                              <label htmlFor="origin">Origine</label>
                            </div>
                            <div className="p-col-12">
                              <Dropdown
                                className="form-input"
                                id="origin"
                                options={this.state.origin_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.applicant.origin_id}
                                onChange={this.handleExternalSelect}
                                showClear={false}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid">
                            <div className="p-col-12">
                              <label htmlFor="qualification">Compétences techniques et fonctionnelles</label>
                            </div>
                          </div>
                          <div className="p-col-12 p-grid p-nogutter">
                            <div className="p-col-12">
                              <InputTextarea
                                rows={5}
                                cols={80}
                                autoResize={true}
                                className="form-input"
                                id="qualification"
                                value={this.state.applicant.qualification}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                        </div>


                        {/* Former company group */}
                        <div className="p-col-12 p-grid p-md-10 p-md-offset-1 p-justify-between p-nogutter">

                          <div className="p-col-12 p-grid p-md-3">
                            <div className="p-col-12">
                              <label htmlFor="formerRole">Fonction actuelle</label>
                            </div>
                            <div className="p-col-12">
                              <Dropdown
                                className="form-input"
                                id="formerRole"
                                options={this.state.formerRole_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.applicant.formerRole_id}
                                onChange={this.handleExternalSelect}
                                showClear={false}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid p-md-3">
                            <div className="p-col-12">
                              <MandatoryLabel
                                htmlFor="formerSalary"
                                isMissing={this.state.mandatoryFields.formerSalary.state}
                                reminder=""
                              >
                                Salaire actuel
                              </MandatoryLabel>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="formerSalary"
                                disabled={!this.state.editMode}
                                value={this.state.applicant.formerSalary}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpEuroSalary}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-md-3">
                            <div className="p-col-12">
                              <label htmlFor="formerCompany">Entreprise actuelle</label>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="formerCompany"
                                value={this.state.applicant.formerCompany}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpLastName}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-md-3">
                            <div className="p-col-12">
                              <label htmlFor="availability">Disponibilité</label>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="availability"
                                value={this.state.applicant.availability}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                        </div>

                        {/* Sought group */}
                        <div className="p-col-12 p-grid p-md-10 p-md-offset-1 p-justify-between p-nogutter">

                          <div className="p-col-12 p-grid p-md-3">
                            <div className="p-col-12">
                              <label htmlFor="soughtRole">Fonction visée</label>
                            </div>
                            <div className="p-col-12">
                              <Dropdown
                                className="form-input"
                                id="soughtRole"
                                options={this.state.soughtRole_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.applicant.soughtRole_id}
                                onChange={this.handleExternalSelect}
                                showClear={false}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid p-md-3">
                            <div className="p-col-12">
                              <MandatoryLabel
                                htmlFor="salaryExpectations"
                                isMissing={this.state.mandatoryFields.salaryExpectations.state}
                                reminder=""
                              >
                                Prétentions salariales
                              </MandatoryLabel>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="salaryExpectations"
                                disabled={!this.state.editMode}
                                value={this.state.applicant.salaryExpectations}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpEuroSalary}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-md-3">
                            <div className="p-col-12">
                              <label htmlFor="legalStatute">Statut juridique souhaité</label>
                            </div>
                            <div className="p-col-12">
                              <Dropdown
                                className="form-input"
                                id="legalStatute"
                                options={this.state.legalStatute_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.applicant.legalStatute_id}
                                onChange={this.handleExternalSelect}
                                showClear={false}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid p-md-3">
                            <div className="p-col-12">
                              <label htmlFor="otherApplication">Autres candidatures</label>
                            </div>
                            <div className="p-col-12">
                              <InputText
                                className="form-input"
                                id="otherApplication"
                                value={this.state.applicant.otherApplication}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                        </div>


                        {/* HR group */}
                        <div className="p-col-12 p-grid p-md-10 p-md-offset-1 p-justify-between p-nogutter">

                          <div className="p-col-12 p-grid p-md-3">
                            <div className="p-col-12">
                              <label htmlFor="match">Pertinence par rapport au besoin ({this.state.applicant.match}%)</label>
                            </div>
                            <div className="p-col-12">
                              <Slider
                                className="form-input"
                                id="match"
                                value={this.state.applicant.match}
                                step={5}
                                onChange={this.handleInputSlider}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-md-3">
                            <div className="p-col-12">
                              <label htmlFor="state">Statut</label>
                            </div>
                            <div className="p-col-12">
                              <Dropdown
                                className="form-input"
                                id="state"
                                options={this.state.state_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.applicant.state_id}
                                onChange={this.handleExternalSelect}
                                showClear={false}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid p-md-3">
                            <div className="p-col-12">
                              <label htmlFor="nextStep">Prochaine étape</label>
                            </div>
                            <div className="p-col-12">
                              <Dropdown
                                className="form-input"
                                id="nextStep"
                                options={this.state.nextStep_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.applicant.nextStep_id}
                                onChange={this.handleExternalSelect}
                                showClear={false}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid p-col-nogutter">
                            <div className="p-col-12">
                              <label htmlFor="notes">Notes</label>
                            </div>
                          </div>
                          <div className="p-col-12 p-grid p-nogutter">
                            <div className="p-col-12">
                              <InputTextarea
                                rows={5}
                                cols={80}
                                autoResize={true}
                                className="form-input"
                                id="notes"
                                value={this.state.applicant.notes}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                        </div>

                    </Display>

                  </div>

              </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ApplicantForm
