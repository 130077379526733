import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import salesMainBoard from './SalesMainBoard.js'
import HrMainBoard from './HrMainBoard.js'

export class Boards extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exaxt path='/Tableau de bord commercial' component={salesMainBoard} />
        <Route exaxt path='/Tableau de bord RH' component={HrMainBoard} />
      </Switch>
    )
  }
}
