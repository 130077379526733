import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {Attachments} from '../../components/Attachments'
import {InputText} from 'primereact/inputtext'
import {Fieldset} from 'primereact/fieldset'
import {Dropdown} from 'primereact/dropdown'
import {Calendar} from 'primereact/calendar'
import {Checkbox} from 'primereact/checkbox'
import {MailingService} from '../../services/MailingService'
import MailingRecipientTable from './MailingRecipientTable'
import MailingErrorTable from './MailingErrorTable'
//import {Optional, TabView, TabPanel} from '../../components/Display'
import {Optional} from '../../components/Display'
import {TabView,TabPanel} from 'primereact/tabview'
import {Style} from '../../components/Style'
import '../../components/Forms.css'


class MailingForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'mailing', '/Mailings', 'Mailings',

      {
        mailing: {
          id: props.match.params.id,
          subject: "",
          sender: "",
          html: "",
          text: "",
          state: "",
          state_id: 0,
          state_state: "",
          date: new Date (),
        },

        state_list: [],
      }
    )

  }


  /*
   * Form data management
   */

  async getExternalValues () {

    // Populate type dropdown selector options
    const state_list = await this.apiCall ('MailingStates', 'list')
    const defaultState = {id: 0, name: ''}
    const defaultStateValue = state_list.values.find (s => s.defaultValue === true) ;
    if ( defaultStateValue ) {
      defaultState.id = defaultStateValue.id
      defaultState.name = defaultStateValue.name
    }

    // Setup values
    await this.setState (
      prevState => {
        prevState.mailing.state_id = prevState.mailing.state_id || defaultState.id
        prevState.mailing.state = prevState.mailing.state || defaultState.name
        //      prevState.mailing.contact_name = prevState.mailing.contact_name || objectInfos.commonName
        return {
          state_list: state_list.values,
          mailing: prevState.mailing,
        }
      }
    )
  }

  cleanUpValues (values) {
    // Dates are received encoded as an ISO 8601 string then converted to date object
    values.date = new Date (values.date)
    // Replace all fieds values by there labels
    values.html = MailingService.replaceFieldValueByLabels (values.html)
  }

  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Mailling "<i>{this.state.mailing.subject}</i>"</h1>
                <FormContainer
                  values={this.state.mailing}
                  locked={true}
                  onExit={this.handleExit}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <Optional>
                    <TabView>
                        <TabPanel header="Informations" rightIcon="pi pi-info">
                          <div className="form-new-line p-grid">

                            <div className="p-grid p-col-12 p-md-4">
                              <div className="p-col-12">
                                <label htmlFor="date">Date d'expédition</label>
                              </div>
                              <div className="p-col-12">
                                <Calendar
                                  className="form-input"
                                  disabled={true}
                                  id="date"
                                  value={this.state.mailing.date}
                                  onChange={this.handleCalendar}
                                  placeHolder=""
                                  showIcon={true}
                                  readOnlyInput={true}
                                  monthNavigator={true}
                                  yearNavigator={true}
                                  yearRange="1900:2100"
                                  dateFormat="dd/mm/yy"
                                  locale={FormContainer.localDate('fr')}
                                ></Calendar>
                              </div>
                            </div>

                            <div className="p-grid p-col-12 p-md-4">
                              <div className="p-col-12">
                                <label htmlFor="state">Etat</label>
                              </div>
                              <div className="p-col-12">
                                <Dropdown
                                  className="form-input"
                                  disabled={true}
                                  id="state"
                                  options={this.state.state_list.map (c => ({label: c.name, value: c.id}))}
                                  value={this.state.mailing.state_id}
                                  onChange={this.handleExternalSelect}
                                  autoWidth={false}
                                />
                              </div >
                            </div >

                            <div className="p-grid p-col-12 p-md-4">
                              <div className="p-col-12">
                                &nbsp;
                              </div>
                              <div className="p-col-12">
                                <Checkbox
                                className="form-input"
                                disabled={true}
                                inputId="uniqueRecipient"
                                value="uniqueRecipient"
                                onChange={console.log}
                                checked={this.state.mailing.uniqueRecipient !== ''}
                                />
                                <label htmlFor="uniqueRecipient">&nbsp; Mail unitaire</label>
                              </div>
                            </div >


                            <div className="p-col-12 p-grid">
                              <div className="p-col-12">
                                <label htmlFor="subject">Sujet</label>
                              </div>
                              <div className="p-col-12">
                                <InputText
                                  className="form-input"
                                  disabled={true}
                                  id="subject"
                                  autoFocus={true}
                                  value={this.state.mailing.subject}
                                  onChange={this.handleInputText}
                                />
                              </div>
                            </div>

                            <div className="p-col-12 p-grid">
                              <div className="p-col-12">
                                <label htmlFor="sender">Expéditeur</label>
                              </div>
                              <div className="p-col-12">
                                <InputText
                                  className="form-input"
                                  disabled={true}
                                  id="sender"
                                  autoFocus={true}
                                  value={this.state.mailing.sender}
                                  onChange={this.handleInputText}
                                />
                              </div>
                            </div>

                            <div className="p-col-12 p-grid">
                              <div className="p-col-12">
                                <label htmlFor="html">Message</label>
                              </div>
                              <div className="p-col-12">
                                <Style>
                                  {`
                                    .MailingFormHTMLFieldSet .p-fieldset-legend {
                                      display: none;
                                    }
                                  `}
                                </Style>
                                <Fieldset className="MailingFormHTMLFieldSet">
                                  <div
                                    dangerouslySetInnerHTML={
                                      {
                                        __html: this.state.mailing.html
                                      }
                                    }
                                  />
                                </Fieldset>
                              </div>
                            </div>

                          </div>
                        </TabPanel>

                        <TabPanel header="Pièces jointes" rightIcon="pi pi-paperclip">
                          <div className="form-new-line p-grid">
                            <div className="p-col-12 form-new-line">
                              <label htmlFor="attachments">Pièces jointes associées :</label>
                            </div>
                            <div className="p-col-12">
                              <Attachments
                                id="attachments"
                                readOnly={true}
                                getValues={this.getAttachmentsValuesFromObject}
                                getVersions={this.getAttachmentVersions}
                                setVersions={this.setAttachmentVersions}
                                doDownload={this.fileDownload}
                                watch={this.state.profile}
                              >
                              </Attachments>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel header="Destinataires" rightIcon="pi pi-users">
                          <div className="form-new-line p-grid">
                            <div className="p-col-12 form-new-line">
                              <label htmlFor="attachments">Destinataires auquels à été envoyé le mailing :</label>
                            </div>
                            <div className="p-col-12">
                              <MailingRecipientTable
                                mailingId={this.state.mailing.id}
                                getContext={this.getContext}
                              />
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel header="Erreurs" rightIcon="pi pi-exclamation-circle" if={this.state.mailing.state_state === 'error'}>
                          <div className="form-new-line p-grid">
                            <div className="p-col-12 form-new-line">
                              <label htmlFor="attachments">Destinataires en erreur :</label>
                            </div>
                            <div className="p-col-12">
                              <MailingErrorTable
                                mailingId={this.state.mailing.id}
                                getContext={this.getContext}
                              />
                            </div>
                          </div>
                        </TabPanel>
                    </TabView>
                  </Optional>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MailingForm

