import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import KeyForm from './KeyForm'
import KeyList from './KeyList'

export class Keys extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/clées' component={KeyList} />
        <Route path='/clées/:id' component={KeyForm} />
      </Switch>
    )
  }
}
