import React, {Component} from 'react'

export class MandatoryLabel extends Component {

/*
 * Label extention dealing with required fiels indicator
 * - Add "reminder" text at the end of the text label (default to a star '*')
 *
 * - If "isMissing" is true then
 *   Add "form-label-missing-field" class to label comonent.
 *   Add "form-label-target-missing-field" class to label htmlFor component.
 */

  render() {

      const props = {...this.props}
      let reminder = ' *'

      if ( 'isMissing' in props) {
        if ( props.isMissing ) {
          if ( this.props.className ) {
            props.className += " form-label-missing-field"
          } else {
            props.className = "form-label-missing-field"
          }
          if ( 'htmlFor' in props ) {
            const htmlFor = document.getElementById(props.htmlFor)
            if ( htmlFor ) {
              htmlFor.classList.add('form-label-target-missing-field')
            }
          }
        } else {
          if ( 'htmlFor' in props ) {
            const htmlFor = document.getElementById(props.htmlFor)
            if ( htmlFor ) {
              htmlFor.classList.remove('form-label-target-missing-field')
            }
          }

        }
        delete props.isMissing
      }

      if ( 'reminder' in props ) {
        reminder = props.reminder
        delete props.reminder
      }


    return (
      <label {...props}>
        <span>
          {this.props.children} {reminder}
        </span>
      </label>
    )
  }
}
