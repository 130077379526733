import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import ContactList from './ContactList'
import ContactForm from './ContactForm'
import AdminContactList from './AdminContactList'

export class Contacts extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Interlocuteurs' component={ContactList} />
        <Route path='/Interlocuteurs/:id/:company_id?' component={ContactForm} />
        <Route exact path='/Archives/Interlocuteurs' component={AdminContactList} />
      </Switch>
    )
  }
}
