import {AuthenticatedComponent} from './AuthenticatedComponent'
import './Searches.css'

export class AuthenticatedSearch extends AuthenticatedComponent {

  constructor(props, objectName, pathName, serviceName, state = {}) {
    super(props, objectName, pathName, serviceName)
    this.state = {
      ...this.state,              // First, set parent state
      searchPattern: "",          // Next add our own values (may be overwriting parent's ones)
      filters: {},
      results: {},
      ...state,                   // Last add child values (may be overwriting ours)
    }

    this.handleSearch = this.handleSearch.bind (this)
    this.handleForm = this.handleForm.bind (this)
    this.handleList = this.handleList.bind (this)
    this.handleResults = this.handleResults.bind (this)

    this.getContext = this.getContext.bind (this)
    this.setContext = this.setContext.bind (this)
  }


  /*
   * Custom context management
   */

  getContext () {
    let context =  {searchPattern: this.state.searchPattern}
    if ( this.props.getContext ) {
      const propsContext = this.props.getContext()
      context = { ...context, ...propsContext }
    }
    return context
  }

  setContext (context) {
    if (context && context.searchPattern) {
      this.setState ({searchPattern: context.searchPattern})
    }
  }

  /*
   * Handle search input change
   * - Send input value to search filter
   */
  handleSearch (e) {
    const value = e.target.value
    this.setState (
      {
        searchPattern: value,
      }
    )
  }

  /*
   * Method handling Form display of the results
   */
  handleForm (id, index, total, path, getter) {
    const getValues = getter
    const cleanOnExit = this.props.cleanOnExit
    const pattern = this.state.searchPattern
    const filters = this.state.filters
    function get (index) {
      return getValues (index, 1, pattern, filters)
    }
    function clean () {
      if ( cleanOnExit !== undefined ) {
        cleanOnExit ()
      }
    }
    const browser = {
      get:  get,
      clean: clean,
      current: index,
      number: total
    }
    this.context.pushRoute (path + id, this.getContext(), {browser: browser})
  }

  /*
   * Methose handling List display of the results
   */
  handleList (path, objectName) {
    const objectTable = objectName + 'Table'
    this.context.pushRoute (path, this.getContext(), {[objectTable]: {filter: this.state.searchPattern, filters: this.state.filters}})
  }

  /*
   * Method called by each element to update it's number of results
   */
  handleResults (service, numberMatched) {
    this.setState (
      prevState => {
        prevState.results[service] = numberMatched
        return {results: prevState.results}
      }
    )
  }

  /*
   * Compute the total number of results for all elements
   */
  totalResults () {
    let sum = 0;
    for( let result in this.state.results ) {
      if( this.state.results.hasOwnProperty( result ) ) {
        sum += parseFloat (this.state.results[result])
      }
    }
    return sum;
  }

}

