import React, {Component} from 'react'
import {InputTextarea} from 'primereact/inputtextarea'
import {Fieldset} from 'primereact/fieldset'
import {Display} from './Display'
import {Style} from './Style'


/*
 * Input component for HTML
 */

export class InputHtml extends Component {

  render() {

    return (
      <div>
        <Style>
          {`
            .InputFormHTMLFieldSet {
              height: ${Math.round(this.props.rows * 1.5)}em;
            }
            .InputFormHTMLFieldSet .p-fieldset-legend {
              display: none;
            }
          `}
        </Style>
        <Display if={this.props.disabled}>
          <Fieldset className="InputFormHTMLFieldSet">
            <div
              dangerouslySetInnerHTML={
                {
                  __html: this.props.value
                }
              }
            />
          </Fieldset>
        </Display>
        <Display if={!this.props.disabled}>
          <InputTextarea
            rows={this.props.rows}
            cols={this.props.cols}
            autoFocus={this.props.autoFocus}
            autoResize={true}
            className="form-input"
            id={this.props.inputId}
            disabled={this.props.disabled}
            value={this.props.value}
            onChange={this.props.onChange}
          />
        </Display>
      </div>
    )

  }

}

