import React from 'react'
import {FilterService} from '../../services/FilterService'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
//import {UnitService} from '../../services/UnitService'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

export class MailingTable extends AuthenticatedTable {

  constructor(props) {
    super (
      props, 'mailing', '/Mailings', 'Mailings',
      {
        state_list: [],
        inCharge_list: [],
      }
    )

    this.setInChargeFilter = FilterService.setInChargeFilter.bind(this)
  }

  /*
   * Reetrieve mailing sent by current user or users managed by current user
   */
  getValues (first=0, rows=0, sort=null, filter=null, filters=null) {
      // Set access filter
      filters = this.setInChargeFilter (filters)
      // Get results
      return super.getValues (first, rows, sort, filter, filters)
  }

  /*
   * Get mailing states
   */
  async getExternalValues () {
    const state_list = await this.apiCall ('MailingStates', 'list')
    state_list.values = state_list.values.map (t => {return {label: t.name, value: t.id, ...t}})
    this.setState (
      {
        state_list: state_list.values,
      }
    )
  }


  doRender() {

    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        noAutoFocus={this.props.noAutoFocus}
        onOpen={this.handleOpen}
        //onDelete={this.handleDelete }
        //deleteTitle="Archiver"
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column key="subject" field="subject" header="Sujet" sortable={true} style={{width:'28%'}}/>
        <Column
          key="date"
          field="date"
          header="Date"
          filterMethod={LazyDataTable.dateFilter}
          sortable={true}
          style={{width:'1%'}}
          body={LazyDataTable.dateTemplate}
        />
        <Column
          key="state"
          field="state"
          header="Etat"
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.state_list}
          sortable={true}
          style={{width:'10%'}}
          body={LazyDataTable.optionTemplate}
        />
        <Column
          key="uniqueRecipient"
          field="uniqueRecipient"
          header=<i className="fas fa-mail-bulk"></i>
          sortable={true}
          filterMethod={LazyDataTable.optionsFilter}
          filterParams={LazyDataTable.massMailingDefaultOptions}
          style={{textAlign:'center', width:'3%'}}
          body={LazyDataTable.massMailingTemplate}
        />
        <Column key="sender" field="sender" header="Expéditeur" sortable={true} style={{width:'12%'}}/>
        {/* Just in case, you never know ...
        <Column
          field="className"
          header=<i className="fas fas fa-address-book"></i>
          sortable={true}
          style={{width:'6%', textAlign:'center'}}
          body={UnitService.classTemplate}
          filterMethod={LazyDataTable.optionsFilter}
          filterParams={UnitService.unit_list}
        />
        */}
      </LazyDataTable>
    )
  }
}

