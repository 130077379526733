import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import NeedList from './NeedList'
import NeedForm from './NeedForm'

export class Needs extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Besoins' component={NeedList} />
        <Route path='/Besoins/:id/:object?/:inCharge?' component={NeedForm} />
        <Route exact path='/Demandes' component={NeedList} />
      </Switch>
    )
  }
}
