import React from 'react'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import {NeedTable} from './NeedTable'

class NeedList extends AuthenticatedComponent {

  doRender() {
    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Gestion des Besoins</h1>

              <div className="p-grid">
                <div className="p-col-12">
                  <NeedTable/>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default NeedList
