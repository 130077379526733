import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import TeamOrganigram from './TeamOrganigram'
import TeamList from './TeamList'
import TeamForm from './TeamForm'
import AdminTeamList from './AdminTeamList'

export class Teams extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Equipes/Organigramme' component={TeamOrganigram} />
        <Route exact path='/Equipes/Liste' component={TeamList} />
        <Route path='/Equipes/Liste/:id/:manager_id?' component={TeamForm} />
        <Route exact path='/Archives/Equipes' component={AdminTeamList} />
      </Switch>
    )
  }
}
