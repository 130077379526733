import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext'
import {Dropdown} from 'primereact/dropdown'
import '../../components/Forms.css'

class KeyForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'key', '/Clées', 'Keys',

      {
        key: {
          id: props.match.params.id,
          value: "",
          value_id: 0,
          comment: "",
        },

        constraints: {
          constraintViolationException: {
            key_value: "Cet utilisateur à déjà une validation active en cours...",
          }
        },

        mandatoryFields: {
          value: {test: FormContainer.checkNotEmpty, state: false},
          comment: {test: FormContainer.checkNotEmpty, state: false},
        },

        value_list: [],
      }
    )
  }



  /*
   * Form data management
   */

  async getExternalValues () {
    // Never let open key for edition !
    if ( this.state.key.id !== '0' ) {
      await this.context.setRoute (this.pathName)
    } else {
      const value_list = await this.apiCall ( 'Users', 'list')
      value_list.values = value_list.values.map (u => {return {name: u.eMail, id: u.id}})
      await this.setState ({value_list: value_list.values})
    }
  }


  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Attribution d'une nouvelle clé d'activation</h1>
                <FormContainer
                  values={this.state.key}
                  mandatoryFields={this.state.mandatoryFields}
                  locked={!this.state.isManager}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <div className="form-new-line p-grid">

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <MandatoryLabel htmlFor="value" isMissing={this.state.mandatoryFields.value.state}>Login associé</MandatoryLabel>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <Dropdown
                          className="form-input"
                          disabled={!this.state.editMode}
                          id="value"
                          options={this.state.value_list.map (u => ({label: u.name, value: u.id}))}
                          value={this.state.key.value_id}
                          onChange={this.handleExternalSelect}
                          autoWidth={false}
                        />
                      </div >
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <MandatoryLabel htmlFor="comment" isMissing={this.state.mandatoryFields.comment.state}>Libellé</MandatoryLabel>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <InputText
                          className="form-input"
                          id="comment"
                          value={this.state.key.comment}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                  </div>

                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default KeyForm
