import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext'
import '../../components/Forms.css'

class PersonRoleForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'personRole', '/Personnes/Roles', 'Roles',

      {
        personRole: {
          id: props.match.params.id,
          name: "",
        },

        constraints: {
          constraintViolationException: {
            role_name: "Libellé déjà utilisé (Il doit être unique).",
          }
        },

        mandatoryFields: {
          name: {test: FormContainer.checkNotEmpty, state: false},
        },

      }
    )
  }


  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Role d'une personne {this.state.personRole.name}</h1>
                <FormContainer
                  values={this.state.personRole}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <div className="form-new-line p-grid">

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <MandatoryLabel htmlFor="name" isMissing={this.state.mandatoryFields.name.state}>Libellé</MandatoryLabel>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <InputText
                          className="form-input"
                          disabled={!this.state.editMode}
                          id="name"
                          autoFocus={true}
                          value={this.state.personRole.name}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                  </div>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PersonRoleForm
