import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import MailingList from './MailingList'
import MailingForm from './MailingForm'

export class Mailings extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Mailings' component={MailingList} />
        <Route path='/Mailings/:id' component={MailingForm} />
      </Switch>
    )
  }
}
