import React, {Component} from 'react'
import {DataFiles} from '../helpers/DataFiles'
import {Display} from './Display'

export class Picture extends Component {

  constructor(props) {
    super(props)

    this.state = {
      src: null,
      file: null
    }

    this.alt = this.props.alt ?? " "

    this.fileInputRef = React.createRef()

    this.openFileDialog = this.openFileDialog.bind(this)
    this.handleFilesAdded = this.handleFilesAdded.bind(this)
    this.handleClear = this.handleClear.bind(this)

  }



  /*
   * Life cycle methods
   * ==================================================================
   */

  /*
   * Load Picture from value
   */
  async loadPicture () {
    if ( this.props.value ) {
      const rawData = await this.props.onLoad (this.props.value)
      const blob = DataFiles.b64ToBlob(rawData)
      const url = window.URL.createObjectURL(blob)
      this.setState ({src: url})
    } else {
      this.setState ({src: null})
    }
  }

  /*
   * Load initial picture
   */
  async componentDidMount () {
    this.alt = await this.alt
    this.loadPicture ()
  }

  /*
   * Reload pricture
   */
  async componentDidUpdate (prevProps, prevState) {
    if ( prevProps.value !== this.props.value ) {
      this.loadPicture ()
    }
  }

  /*
   * File selection handling
   * ==================================================================
   */

  /*
   * Open file selector when form is clicked
   */
  openFileDialog() {
    if (this.props.locked) {
        return false
    }
    this.fileInputRef.current.click()
  }


  /*
   * Get file and set it as current
   */
  async handleFilesAdded(event) {

    if ( event.target.files && event.target.files.length) {
      const input = event.target.files.item(0)
      const url = await DataFiles.fileToUrl (input, this.props.maxWidth, this.props.maxHeight)
      const file = DataFiles.b64UrlToFile (url, input.name)

      if ( this.props.onChange ) {
        this.props.onChange (
          {
            target: {
              id: this.props.id,
              value: file
            },
            originalEvent: event
          }
        )
      }
      this.setState ({src:url, file:file})

    }

  }

  /*
   * Clear the Picture
   */
  handleClear (event) {
    event.stopPropagation ()
    if ( this.props.onChange ) {
      this.props.onChange (
        {
          target: {
            id: this.props.id,
            value: null,
          },
          originalEvent: event
        }
      )
    }
    this.setState ({src:null, file:null})
  }


 /*
  * Rendering methods
  * ==================================================================
  */


  /*
   * Component rendering
   */
  render() {
    return (
      <div
          style={{maxHeight: this.props.height, maxWidth: this.props.width, height: '100%', ...this.props.style}}
          className={this.props.locked ? "" :  "p-inputtext"}
        >
        <div
          style={{display: 'inline-block', width: '95%', height: '100%'}}
          onClick={this.openFileDialog}
        >
          <input
            style={{display: "none"}}
            ref={this.fileInputRef}
            className="FileInput"
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={this.handleFilesAdded}
          />
          <img
            alt={this.alt}
            style={{objectFit: "contain", maxHeight: this.props.locked ? this.props.height : '100%'}}
            src={this.state.src ?? this.props.missing}
          >
          </img>
        </div>
        <Display if={!this.props.locked && this.state.src}>
          <div style={{cursor: 'pointer', float: 'right'}} onClick={this.handleClear}>
            <i className="pi pi-times"></i>
          </div>
        </Display>
      </div>
    )
  }
}
