import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import ImportsParameterForm from './ImportsParameterForm'
import ImportList from './ImportList'
import ImportForm from './ImportForm'
import ImportTypeList from './ImportTypeList'
import ImportTypeForm from './ImportTypeForm'
import ImportStateList from './ImportStateList'
import ImportStateForm from './ImportStateForm'

export class Imports extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Imports' component={ImportList} />
        <Route path='/Imports/:id' component={ImportForm} />
        <Route exact path='/Paramétres/Imports' component={ImportsParameterForm} />
        <Route exact path='/Types/Imports' component={ImportTypeList} />
        <Route path='/Types/Imports/:id' component={ImportTypeForm} />
        <Route exact path='/Etats/Imports' component={ImportStateList} />
        <Route path='/Etats/Imports/:id' component={ImportStateForm} />
      </Switch>
    )
  }
}
