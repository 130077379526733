import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import EnglishLevelList from './EnglishLevelList'
import EnglishLevelForm from './EnglishLevelForm'

export class EnglishLevels extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path="/Personnes/Niveaux d'anglais" component={EnglishLevelList} />
        <Route path="/Personnes/Niveaux d'anglais/:id" component={EnglishLevelForm} />
      </Switch>
    )
  }
}
