import React from 'react'
import {FilterService} from '../../services/FilterService'
import {EventService} from '../../services/EventService'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

export class EventTable extends AuthenticatedTable {

  constructor(props) {
    super (
      props, 'event', '/Evénements', 'Events',
      {
        type_list: [],
        inCharge_list: [],
      }
    )
    this.getInCharge_list = FilterService.getInCharge_list.bind(this)
    this.setInChargeFilter = FilterService.setInChargeFilter.bind(this)
  }


  async getValues (first=0, rows=0, sort=null, filter=null, filters=null) {
    // Set props filters if any
    filters = this.defaultFilters (filters, this.props.filters) ;
    if ( this.props.filters && this.props.filters.className && this.props.filters.className.value !== 'hr' ) {
      // Set access filter
      filters = this.setInChargeFilter (filters)
    }
    // Get results
    const events = await super.getValues (first, rows, sort, filter, filters)
    // Map inchard_id = 0 to anybody
    return FilterService.mapInChargeAnyBody (events)
  }



  async getExternalValues () {

    // Get users inCharge
    const inCharge_list = await this.getInCharge_list (this.props.filters && this.props.filters.className && this.props.filters.className.value === 'hr')

    // Get event types
    const filters = this.defaultFilters (this.props.filters) ;
    const type_list = await this.apiCall ('EventTypes', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, filters)
    type_list.values = type_list.values.map (t => {return {label: t.name, value: t.id, ...t}})

    this.setState (
      {
        type_list: type_list.values,
        inCharge_list: inCharge_list.values
      }
    )
  }


  doRender() {

    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        noAutoFocus={this.props.noAutoFocus}
        onOpen={this.handleOpen}
        onDelete={this.handleDelete }
        deleteTitle="Archiver"
        //onCreate={this.handleCreate}
        onRemove={this.props.onRemove}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column key="itemName" field="itemName" header="Interlocuteur" sortable={true} style={{width:'25%'}} />
        <Column key="description" field="description" header="Description" sortable={true} style={{width:'45%'}} body={LazyDataTable.maxLinesTemplate(3)}/>
        <Column
          key="type"
          field="type"
          header="Type"
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.type_list}
          sortable={false}
          style={{width:'10%'}}
          body={LazyDataTable.optionTemplate}
        />
        <Column
          key="realDate"
          field="realDate"
          header="Date"
          filterMethod={LazyDataTable.dateFilter}
          sortable={true}
          style={{width:'5%'}}
          body={LazyDataTable.dateTemplate}
        />
        <Column
          key="state"
          field="state"
          header="Statut"
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={EventService.states}
          sortable={false}
          style={{width:'10%'}}
          body={EventService.stateTemplate}
        />
        <Column
          key="inCharge"
          if={this.state.profile.expert}
          field="inCharge"
          header=<i className="pi pi-user"/>
          sortable={true}
          style={{width:'5%'}}
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.inCharge_list}
        />
      </LazyDataTable>
    )
  }
}

