import React from 'react'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import {ApplicantTable} from './ApplicantTable'

class ApplicantList extends AuthenticatedComponent {

  doRender() {
    /*
     * FIXME
     * This selection is based on the states and steps Ids
     * If user would change or add states or steps, wue should
     * find another way to do this
     * FIXME
     */
    let filters = {}
    switch ( this.props.match.path.replace (/\/:.*$/, '').replace (/^\/(.*-)?/, '') ) {
      case 'rencontrés':
        filters = {
          ExcludeImport: {value : true},
          nextStep: {
            value : [
              255976,     // A jouer
              255977,     // Proposition d'embauche
              255975,     // Inconnu
              256147,     // Plus disponible
              256148,     // Proposition à l'étude
            ]
          }
        }
        break ;

      case 'vivier':
        filters = {
          nextStep: {value : 256146}
        }
        break ;

      case 'embauchés':
        filters = {
          nextStep: {value : 255978}
        }
        break ;

      case 'importés':
        filters = {
          state: {value : 306018}
        }
        break ;

      default:
        filters = {
          ExcludeImport: {value : true}
        }
        break ;

    }


    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Gestion des candidats</h1>

              <div className="p-grid">
                <div className="p-col-12">
                  <ApplicantTable filters={filters}/>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default ApplicantList
