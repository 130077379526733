import React from 'react'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

class KeyList extends AuthenticatedTable {

  constructor(props) {
    super (props, 'key', '/clées', 'Keys')
  }

  doRender() {

    const stateTemplate = (rawData, column) => {
      if ( rawData[column.field] ) {
        return <i className="pi pi-minus-circle"></i>
      }
      return <i className="pi pi-check"></i>
    }

    const stateOptions = [
      { label : 'Actives', value : '0' },
      { label : 'Révoquées', value : '1' },
      { label : 'Toutes', value : '' },
    ]

    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Gestion des clés</h1>

              <div className="p-grid">
                <div className="p-col-12">
                  <LazyDataTable
                    ref={this.setRef}
                    locked={this.props.locked}
                    onCreate={this.handleCreate}
                    onDisable={this.handleDelete}
                    disableTitle="Révoquer"
                    onEnable={this.handleRestore}
                    getValues={this.getAdminValues}
                    watch={this.state.profile}
                  >
                    <Column field="value" header="Clé" sortable={true} style={{width:'20%'}}/>
                    <Column field="comment" header="Nom associé" sortable={true} style={{width:'20%'}}/>
                    <Column
                      field="creationDate"
                      header="Générée le"
                      sortable={true}
                      style={{width:'10%'}}
                      body={LazyDataTable.dateTemplate}
                      filterMethod={LazyDataTable.dateFilter}
                    />
                    <Column field="creationUser_code" header="par" sortable={true} style={{width:'10%'}}/>
                    <Column
                      field="deletionDate"
                      header="Révoquéé le"
                      sortable={true}
                      style={{width:'10%'}}
                      body={LazyDataTable.dateTemplate}
                      filterMethod={LazyDataTable.dateFilter}
                    />
                    <Column field="deletionUser_code" header="par" sortable={true} style={{width:'10%'}}/>
                    <Column
                      field="deleted"
                      header="Etat"
                      sortable={true}
                      style={{textAlign:'center', width:'10%'}}
                      body={stateTemplate}
                      filterMethod={LazyDataTable.optionsFilter}
                      filterParams={stateOptions}
                    />
                  </LazyDataTable>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default KeyList
