import React, {Component} from 'react'
import {EventEmitter} from 'eventemitter3'
import {FileUpload} from 'primereact/fileupload'
import {ProgressBar} from 'primereact/progressbar'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {SplitButton} from 'primereact/splitbutton'
import {Dialog} from 'primereact/dialog'
import {Editor} from 'primereact/editor'
import {MandatoryLabel} from './MandatoryLabel'
import {Display} from './Display'

import {Dropdown} from 'primereact/dropdown'
import './Mailing.css'

export class MailingWizard extends Component {

  constructor (props) {
    super (props)

    this.state = {
      message: {
        sender: this.props.sender,
        copy: "",
        blindCopy: "",
        uniqueRecipient: this.props.recipient ? this.props.recipient : "",
        subject: this.props.value ? this.props.value.subject : "",
        html: this.props.value ? this.props.value.html : null,
        text: this.props.value ? this.props.value.text : "",
        attachments: []
      },

      step: "Writing",

      template: 0,

      uploadProgress: 0.0,
    }

    // Mandatory fields used in template mode
    this.mandatoryFields = this.props.mandatoryFields || { subject: {state: false}, text: {state: false} }

    // Quill handler
    this.quill = null
    this.setQuill = this.setQuill.bind(this)

    // Focus handler
    this.focused = null
    this.selectFocus = this.selectFocus.bind(this)
    this.setFocus = this.setFocus.bind(this)

    // Insertable fields
    this.field_list = this.props.fields
    this.field_set = this.props.fields.map (f => {return { label: f.label, command: () => this.insertField (f.label) }})
    this.insertField = this.insertField.bind(this)
    this.setFields = this.setFields.bind(this)

    // Templates
    this.handleTemplate = this.handleTemplate.bind(this)
    this.insertTemplate = this.insertTemplate.bind(this)

    // Standard mail composer
    this.handleSender = this.handleSender.bind(this)
    this.handleCopy = this.handleCopy.bind(this)
    this.handleBlindCopy = this.handleBlindCopy.bind(this)
    this.handleSubject = this.handleSubject.bind(this)
    this.handleMessage = this.handleMessage.bind(this)

    // Mailing events
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSendMail = this.handleSendMail.bind(this)

    // Attachments handler
    this.attachments = null
    this.setAttachments = this.setAttachments.bind(this)
    this.handleUpload = this.handleUpload.bind(this)

    // Upload handlers
    this.xhrHandle = null
    this.setXhrHandle = this.setXhrHandle.bind(this)
    this.handleProgress = this.handleProgress.bind(this)
    this.cancelSending = this.cancelSending.bind(this)
  }

  /*
   * Set the Quill handler from the editor reference
   */
  setQuill(editor) {
    if (editor) {
      this.quill = editor.quill
      if ( this.props.disabled !== undefined ) {
        this.quill.enable(!this.props.disabled)
      }
    }
  }

  setIcons(icons) {
    icons['bold'] = '<svg width="24" height="24" viewBox="0 0 24 24" class="ql-stroke"><text x="0" y="18" >G</text></svg>'
    icons['italic'] = '<svg width="24" height="24" viewBox="0 0 24 24" class="ql-stroke"><text x="0" y="18" >I</text></svg>'
    icons['underline'] = '<svg width="24" height="24" viewBox="0 0 24 24" class="ql-stroke"><text x="0" y="18" >S</text></svg>'
  }

  /*
   * Set focus handler on an input component
   */
  selectFocus(input) {
    if (input) {
      this.focused = input.element
    }
  }

  /*
   * Give the focus on the focused component
   * Set all toolbar's components tabindex to -1 to prevent tan navigation
   * this allow to jump from Sender ans Subject fields directly to editor
   */
  setFocus () {
    if (this.focused) {
      // Wait for on tick cycle to allow DOM ton be fully loaded
      setTimeout(
        () => {
          this.focused.focus ()
          // This is a bit hacky but Quill do not care about tabindex in HTML :-(
          const tabHideElements = document.querySelectorAll(".ql-toolbar *") ;
          tabHideElements.forEach (
            function(item) {
              item.setAttribute("tabindex", -1);
            }
          );
        }
      )
    }
  }

  componentDidMount () {
    if ( this.props.template ) {
      this.setFocus ()
    }
  }

  /*
   * CleanUp message when mailing wizard is triggered
   */
  componentDidUpdate (prevProps, prevState) {
    // Reset editor when sending mail
    if (!prevProps.doMailing && this.props.doMailing) {
      this.setState (
        {
          message: {
            sender: this.props.sender,
            copy: "",
            blindCopy: "",
            uniqueRecipient: this.props.recipient ? this.props.recipient : "",
            subject: "",
            html: null,
            text: "",
            attachments: []
          },
          field: (this.props.fields && this.props.fields.length) ? this.props.fields[0].value : '',
          step: "Writing",
          uploadProgress: 0.0,
        }
      )
    }
    // Propagate props.disabled to Quill editor
    if ( this.props.disabled !== undefined && prevProps.disabled !== this.props.disabled ) {
      this.quill.enable(!this.props.disabled)
    }
    // Synchronise state.message with external props.value changes
    if (this.props.value && this.state.message.subject !== this.props.value.subject) {
      this.setState (
        prevState => {
          return {
            message: {
              sender: prevState.message.sender,
              copy: prevState.message.copy,
              blindCopy: prevState.message.blindCopy,
              uniqueRecipient: this.props.recipient ? this.props.recipient : "",
              subject: this.props.value.subject,
              html: this.props.value.html,
              text: this.props.value.text,
              attachments: []
            },

          }
        }
      )
    }
  }

  /*
   * Set e-mail sender
   */
  handleSender (event) {
    const sender = event.target.value
    this.setState (
      prevState => {
        prevState.message.sender = sender
        return { message: prevState.message }
      }
    )
  }

  /*
   * Set e-mail copy
   */
  handleCopy (event) {
    const copy = event.target.value
    this.setState (
      prevState => {
        prevState.message.copy = copy
        return { message: prevState.message }
      }
    )
  }

  /*
   * Set e-mail blindCopy
   */
  handleBlindCopy (event) {
    const blindCopy = event.target.value
    this.setState (
      prevState => {
        prevState.message.blindCopy = blindCopy
        return { message: prevState.message }
      }
    )
  }

  /*
   * Set e-mail subject
   */
  handleSubject (event) {
    const subject = event.target.value
    this.setState (
      prevState => {
        prevState.message.subject = subject
        return { message: prevState.message }
      },
      this.propagateChanges
    )
  }

  /*
   * Handle message modifications
   */
  handleMessage (event) {
    const html = event.htmlValue
    const text = event.textValue
    this.setState (
      prevState => {
        prevState.message.html = html
        prevState.message.text = text
        return { message: prevState.message }
      },
      this.propagateChanges
    )
  }


  /*
   * Insert the currently selected field
   */
  insertField (field) {
    const sendEvent = new EventEmitter ()
    const target = this.field_list.find (s => s.label === field)
    if ( target && target.value[0] === ':' ) {
      const lastPosition = this.quill.getLength ()
      this.quill.insertText(lastPosition, "\n{{" + field + '}}')
    } else {
      const currentPosition = this.quill.getSelection(true).index
      const separator = (currentPosition && this.quill.getText (currentPosition - 1, 1) !== ' ') ? ' ' : ''
      this.quill.insertText(currentPosition, separator + '{{' + field + '}} ')
    }
    sendEvent.once ('text-change')
    this.quill.focus()
  }

  /*
   * Select current template
   */
  handleTemplate (event) {
    this.setState({template: event.value})
  }

  /*
   * Insert the currently selected template
   */
  async insertTemplate () {
    if ( this.state.template ) {
      const template = await this.props.getTemplate (this.state.template)
      this.setState (
        prevState => {
          prevState.message.subject = template.subject
          prevState.message.html = template.html
          prevState.message.text = template.text
          return { message: prevState.message }
        },
        this.propagateChanges
      )
    }
  }

  /*
   * On SendMail, sanitise fields and return mailing data
   */
  async handleSendMail () {
    await this.attachments.upload ()
    this.setState (
      prevState => {
        prevState.message.html = this.setFields (prevState.message.html)
        prevState.message.text = this.setFields (prevState.message.text)
        return {
          message: prevState.message,
          step: "Sending"
        }
      },
      () => this.props.mailingDone (this.state.message, xhr => this.setXhrHandle (xhr), event => this.handleProgress (event))
    )
  }

  /*
   * Cancel mail and attachments sending
   * Data transfert is just interrupted
   * Mailing is not destroyed, it will stay in partial state
   */
  cancelSending () {
    this.xhrHandle.abort ()
    this.xhrHandle = null
  }

  /*
   * Replace all field labels by their values
   * This method is already present in a more generic form
   * into MailinfService but we do not want to be dependant
   * of a service so we keep this copy there.
   */
  setFields (content) {
    const token = content.match (/{{[^}]+}}/g)
    if ( token ) {
      for (let i=0; i<token.length; i++) {
        const field = this.field_list.find (s => s.label === token[i].replace (/[{}]/g, ''))
        if ( field ) {
          content = content.replace (token[i], '{{' + field.value + '}}')
        } else {
          // Should never happen but prevent from endless loop :-)
          content = content.replace (token[i], '') ;
        }
      }
    }
    return content
  }


  /*
   * On cancel, Return empty mailing
   */
  handleCancel () {
    this.props.mailingDone (null)
  }

  /*
   * Attachments handler
   */
  setAttachments (attachments) {
    if (attachments) {
      this.attachments = attachments
    }
  }

  /*
   * Returns attachments files list on upload event
   * Does NOT actual upload
   */
  handleUpload (attachments) {
    this.setState (
      prevState => {
        prevState.message.attachments = attachments.files
        return {message: prevState.message}
      },
      // This is a bit hacky but FileUpload component does not allow to disable preview img
      // so we add a class to the previview divs in order to handle them with css
      () => {
        const fileUploadDivs = document.querySelectorAll('.p-fileupload-row div');
        for ( let i=0; i<fileUploadDivs.length; i++ ) {
          if ( fileUploadDivs[i].querySelector ('img') ) {
            fileUploadDivs[i].classList.add('mailing-wizard-fileupload-preview-img')
          }
        }
      }
    )
  }

  /*
   * Set handler on current XHR upload transaction
   */
  setXhrHandle (xhr) {
    this.xhrHandle = xhr
  }

  /*
   * Compute uploading progress
   */
  handleProgress (event) {
    if (event.lengthComputable) {
      const progress = (event.loaded / event.total) * 100
      this.setState( {uploadProgress: progress})
    }
  }

  /*
   * Propagate changes in template mode
   */
  async propagateChanges () {
    if ( this.props.template && this.props.onChange ) {
      await this.props.onChange (this.state.message.subject, this.state.message.html, this.state.message.text)
    }
  }

  /*
   * Main rendering
   */
  render() {
    const editorHeader = (

      <div className="p-grid mailing-wizard-frame p-justify-between">
          <div className="p-col-2">
            <select className="ql-size" defaultValue="normal">
              <option value="normal">normal</option>
              <option value="small">petit</option>
              <option value="large">grand</option>
            </select>
          </div>
          <div className="p-col-2">
            <button className="ql-bold" aria-label="Bold" disabled={this.props.disabled}></button>
            <button className="ql-italic" aria-label="Italic" disabled={this.props.disabled}></button>
            <button className="ql-underline" aria-label="Underline" disabled={this.props.disabled}></button>
          </div>
          <div className="p-col-5 p-grid mailing-wizard-template-block">
            <div className="p-col-4">
              <Button
                className={"p-button-info mailing-wizard-template-button"}
                label={"Modèle"}
                icon="pi pi-arrow-circle-right"
                disabled={this.props.disabled}
                onClick={this.insertTemplate}
              />
            </div>
            <div className="p-col-8">
              <Dropdown
                classNAme="mailing-wizard-template-template"
                disabled={this.props.disabled}
                value={this.state.template}
                options={this.props.templates ?? []}
                onChange={this.handleTemplate}
              />
            </div>
          </div>
          <div className="p-col-3 p-grid mailing-wizard-selector-block">
            <SplitButton
              disabled={this.props.disabled}
              className={"p-button-info"}
              label="Champs de publipostage"
              model={this.field_set}
            />
          </div>
        </div>
    )
    const dialogFooter =  (
      <div>
        <Display if={this.state.message.html && this.state.message.subject}>
          <Button
            label={"Envoyer"}
            icon="pi pi-envelope"
            className={"p-button-info"}
            onClick={this.handleSendMail}
          />
        </Display>
        <Button
          label={"Annuler"}
          icon="pi pi-times-circle"
          className={"p-button-danger"}
          onClick={this.handleCancel}
        />
      </div>
    )
    const sendingFooter =  (
      <div>
          <Button
            label={"Annuler"}
            icon="pi pi-times-circle"
            className={"p-button-danger mailing-wizard-sending-cancel"}
            onClick={this.cancelSending}
          />
      </div>
    )

    const wizardBody = (
      <div className="p-grid p-col-12">

        <div className="p-col-12 p-grid p-nogutter">
          <div className="p-col-12 p-md-1">
            <Display if={this.props.template}>
              <MandatoryLabel htmlFor="subject" isMissing={this.mandatoryFields.subject.state}>Sujet :</MandatoryLabel>
            </Display>
            <Display if={!this.props.template}>
              <label htmlFor="subject">Sujet :</label>
            </Display>
          </div>
          <div className="p-col-12 p-md-11">
            <InputText
              id="subject"
              ref={this.selectFocus}
              disabled={this.props.disabled}
              // In template mode use props values instead of states ones
              value={this.props.value ? this.props.value.subject : this.state.message.subject}
              onChange={this.handleSubject}
            />
          </div>
        </div>

        <Display if={!this.props.template}>
          <div className="p-col-12 p-grid p-nogutter">
            <div className="p-col-12 p-md-1">
              <label htmlFor="sender">Expéditeur :</label>
            </div>
            <div className="p-col-12 p-md-11">
              <InputText
                className="form-input"
                id="sender"
                value={this.state.message.sender}
                onChange={this.handleSender}
              />
            </div>
          </div>
        </Display>

        <Display if={!this.props.template && this.props.recipient}>
          <div className="p-col-12 p-grid p-nogutter">
            <div className="p-col-12 p-md-1">
              <label htmlFor="recipient">Destinataire :</label>
            </div>
            <div className="p-col-12 p-md-11">
              <InputText
                className="form-input"
                id="recipient"
                disabled={true}
                value={this.props.recipient}
              />
            </div>
          </div>
        </Display>

        <Display if={!this.props.template && this.props.recipient}>
          <div className="p-col-12 p-grid p-nogutter">
            <div className="p-col-12 p-md-1">
              <label htmlFor="copy">Copie :</label>
            </div>
            <div className="p-col-12 p-md-11">
              <InputText
                className="form-input"
                id="copy"
                value={this.state.message.copy}
                onChange={this.handleCopy}
              />
            </div>
          </div>
        </Display>

        <Display if={!this.props.template && this.props.recipient}>
          <div className="p-col-12 p-grid p-nogutter">
            <div className="p-col-12 p-md-1">
              <label htmlFor="blindCopy">Copie cachée :</label>
            </div>
            <div className="p-col-12 p-md-11">
              <InputText
                className="form-input"
                id="blindCopy"
                value={this.state.message.blindCopy}
                onChange={this.handleBlindCopy}
              />
            </div>
          </div>
        </Display>

        <div className="p-col-12">
          <Display if={this.props.template}>
            <MandatoryLabel htmlFor="message" isMissing={this.mandatoryFields.text.state}>Sujet :</MandatoryLabel>
          </Display>
          <Display if={!this.props.template}>
            <label htmlFor="message">Message :</label>
          </Display>
        </div>
        <div className="p-col-12">
          <Editor
            className="mailing-wizard-editor"
            id="message"
            style={{height:'320px'}}
            ref={this.setQuill}
            refIcons={this.setIcons}
            // In template mode use props values instead of states ones
            value={this.props.value ? this.props.value.html : this.state.message.html}
            onTextChange={this.handleMessage}
            headerTemplate={editorHeader}
          />
        </div>

        <Display if={!this.props.template}>
          <div className="p-col-12">
            <label htmlFor="message">Pièces jointes :</label>
          </div>
          <div className="p-col-12 ">
            <FileUpload
              ref={this.setAttachments}
              chooseLabel="Ajouter des pièces jointes"
              invalidFileSizeMessageSummary="Fichier trop volumineux"
              invalidFileSizeMessageDetail="La taille maximum de chaque pièce jointe est limitée à {0}"
              auto={true}
              customUpload={true}
              uploadHandler={this.handleUpload}
              multiple={true}
              previewWidth={1}
              maxFileSize={this.props.maxAttachmentsSize ?? 1000000}
            />
          </div>

          <Dialog
            header="Envoie du courriel"
            footer={sendingFooter}
            className="mailing-wizard-sending-panel"
            visible={this.state.step === "Sending"}
            modal={true}
            onHide={()=>{}}
            closable={false}
          >
            <Display if={this.state.uploadProgress <= 0 || this.state.uploadProgress >= 100}>
              <label>Transmission des données</label>
              <ProgressBar mode="indeterminate" />
            </Display>
            <Display if={this.state.uploadProgress > 0 && this.state.uploadProgress < 100}>
              <label>Insertion des pièces jointes</label>
              <ProgressBar value={this.state.uploadProgress.toFixed(0)} />
            </Display>
          </Dialog>
        </Display>

      </div>
    )

    if ( this.props.template ) {
      return wizardBody
    } else {
      return (
        <Dialog
          header="Courriel à envoyer"
          footer={dialogFooter}
          className="mailing-wizard-panel"
          visible={this.props.doMailing}
          modal={true}
          onHide={()=>{}}
          closable={false}
          onShow={this.setFocus}
        >
          {wizardBody}
        </Dialog>
       )
    }
  }

}

