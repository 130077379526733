import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {Checkbox} from 'primereact/checkbox'
import {InputText} from 'primereact/inputtext'
import '../../components/Forms.css'

class LegalStatuteForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'legalStatute', '/Personnes/Statuts juridiques', 'LegalStatutes',

      {
        legalStatute: {
          id: props.match.params.id,
          name: "",
          defaultValue: false,
          color : "000000",
          icon: "",
        },

        constraints: {
          constraintViolationException: {
            legalStatute_name: "Libellé déjà utilisé (Il doit être unique).",
          }
        },

        mandatoryFields: {
          name: {test: FormContainer.checkNotEmpty, state: false},
        },

      }
    )
  }


  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Statut juridique {this.state.legalStatute.name}</h1>
                <FormContainer
                  values={this.state.legalStatute}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <div className="form-new-line p-grid">

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <MandatoryLabel htmlFor="name" isMissing={this.state.mandatoryFields.name.state}>Libellé</MandatoryLabel>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <InputText
                          className="form-input"
                          disabled={!this.state.editMode}
                          id="name"
                          autoFocus={true}
                          value={this.state.legalStatute.name}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <label htmlFor="defaultValue">Valeur par défaut</label>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <Checkbox
                        className="form-input"
                        disabled={!this.state.editMode}
                        inputId="defaultValue"
                        value="defaultValue"
                        onChange={this.handleCheckBox}
                        checked={this.state.legalStatute.defaultValue}
                        />
                      </div>
                    </div>

                  </div>

                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LegalStatuteForm
