import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import LegalStatuteList from './LegalStatuteList'
import LegalStatuteForm from './LegalStatuteForm'

export class LegalStatutes extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Personnes/Statuts juridiques' component={LegalStatuteList} />
        <Route path='/Personnes/Statuts juridiques/:id' component={LegalStatuteForm} />
      </Switch>
    )
  }
}
