import React, {Component} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'

export class ApplicationRoutes extends Component {

/*
 * Build dynamic routing from registerded application components
 */


  /*
   * Main rendering
   */
  render() {
    if ( this.props.routes ) {
      return (
        <Switch>
          { // "/Wait" refresh location : does nothing, just ... wait
            // TODO : need de dynamic URL instead of /Wait to avoid conflict with other routes
            <Route exact path='/Wait' render={() => null}/>
           }
          { // Default route : unconsitionally redirect / to default destination if any
            this.props.default &&
            <Redirect exact from="/" to={this.props.default}/>
          }
          { // Explicit registered routes
            this.props.routes.map ((r, i) => <Route key={i} path={r.route} component={r.component}/>)
          }
          { // Catch all route : unconditionally redirect all unmached route to /
            <Redirect to='/'/>
          }
        </Switch>
      )
    }
    return null
  }
}
