import React from 'react'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'

export class TeamTable extends AuthenticatedTable {

  constructor(props) {
    super (
      props, 'team', '/Equipes/Liste', 'Teams',
      {
        manager_list: [],
      }
    )

    this.homeCrewTemplate = this.homeCrewTemplate.bind(this)
    this.enableHomeCrewSelector = this.enableHomeCrewSelector.bind(this)
    this.homeCrewButton = this.homeCrewButton.bind(this)
  }

  getValues (first=0, rows=0, sort=null, filter=null, filters=null) {

    // Filter only teams managed by provided userId if any
    if ( this.props.userId ) {
        filters = this.defaultFilters (filters, {manager_id: {value : this.props.userId}})

    } else if ( this.state.profile.id ) {

      // Filter only teams managed by the current user
      if ( !this.state.profile.expert ) {
        filters = this.defaultFilters (filters, {manager_id: {value : this.state.profile.id}})

      // Show not only teams managed by the current user but also teams managed by his crew members
      } else {
        // If current user is global manager, do not filter in expert mode, show every companies
        if ( !this.state.profile.globalManager ) {
          filters = this.defaultFilters (filters, {top_manager_id: {value: this.state.profile.id}})
        }
      }
    }

    // Get results ...
    return super.getValues (first, rows, sort, filter, filters)
  }

  async getExternalValues () {

    let filters = null

    // Filter only ... this user !
    if ( this.props.userId ) {
      filters = {user_id: {value: this.props.userId}}

    // Show not only managers managed by the current user
    } else if ( this.state.profile.id ) {

      // If current user is global manager, do not filter in expert mode, show every managers
      if ( !this.state.profile.globalManager ) {
        filters = {
          manager_id: {value: this.state.profile.id} ,
          have_crew: {value: true},
        }
      }
    }

    // Get results ...
    const manager_list = await this.apiCall ('Users', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, filters)
    manager_list.values = manager_list.values.map (u => {return {label: u.code, value: u.id}})
    this.setState ({manager_list: manager_list.values})
  }

  handleCreate () {
    super.handleCreate (this.addParameters (this.props.userId))
  }

  // Method provindig representation template for homeCrew field
  homeCrewTemplate(rawData, column) {
    if ( rawData[column.field] === this.props.homeCrewId ) {
      return <i className="pi pi-star"></i>
    }
    return ""
  }

  homeCrewButton(s, a) {
    const selection = ( s && s.length ) ? s[0].id : null
    const label = selection === this.props.homeCrewId ? "Sortir de l'équipe" : "Intégrer l'équipe"
    const icon = selection === this.props.homeCrewId ? "fas fa-home" : "fas fa-house-user"
    const button = selection === this.props.homeCrewId ? "p-button-danger" : "p-button-info"
    const homeCrew = selection === this.props.homeCrewId ? null : selection
    return (
      <Button
        className={button}
        label={label}
        icon={icon}
        onClick={() => a (homeCrew)}
      />
    )
  }

  enableHomeCrewSelector (s) {
    return  ( this.props.userId && s.length === 1 )
  }

  doRender() {
    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        noAutoFocus={this.props.noAutoFocus}
        onOpen={this.handleOpen}
        onDelete={this.props.userId?null:this.handleDelete}
        deleteTitle="Archiver"
        // To be discussed ... deleteEnabler={this.enableDelete}
        onCreate={this.handleCreate}
        onRemove={this.props.onRemove}
        onAdd={this.props.onAdd}
        addItemGet={this.props.addItemGet}
        addItemMode={this.props.addItemMode}
        addItemTitle={this.props.addItemTitle}
        addItemPrompt={this.props.addItemPrompt}
        custom1Template={this.homeCrewButton}
        onCustom1={this.props.onHomeCrewChange}
        custom1Enabler={this.enableHomeCrewSelector}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column field="name" header="Nom" sortable={true} style={{width:'20%'}}/>
        <Column
          field="manager"
          header="Manager"
          sortable={true}
          style={{width:'15%'}}
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.manager_list}
        />
        <Column
          field="id"
          header=<i className="fas fa-house-user"></i>
          body={this.homeCrewTemplate}
          filterMethod={LazyDataTable.noFilter}
          style={{width:'1%'}}
          if={!!this.props.userId}
        />
      </LazyDataTable>
    )
  }
}

