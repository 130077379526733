import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import MailingTemplateList from './MailingTemplateList'
import AdminMailingTemplateList from './AdminMailingTemplateList'
import MailingTemplateForm from './MailingTemplateForm'

export class MailingTemplates extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Mailing/Modèles' component={MailingTemplateList} />
        <Route path='/Mailing/Modèles/:id' component={MailingTemplateForm} />
        <Route exact path='/Archives/Mailing/Modèles' component={AdminMailingTemplateList} />
      </Switch>
    )
  }
}
