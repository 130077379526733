import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import SalesEventList from './SalesEventList'
import HrEventList from './HrEventList'
import EventForm from './EventForm'

export class Events extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Evénements/Clients' component={SalesEventList} />
        <Route exact path='/Evénements/Candidats' component={HrEventList} />
        <Route path='/Evénements/:id/:object?/:inCharge?' component={EventForm} />
      </Switch>
    )
  }
}
