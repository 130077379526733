export class FilterService {


  /*
   * Get a list of users in charge of an object that current user can access to
   */
  static async getInCharge_list (all=false) {

    // Set default empty values
    let inCharge_list = {
      totalRecords: 0,
      values: [],
    }

    if ( this.state.profile.id ) {

      // Set query filters
      const filters = all ? null : FilterService.setInChargeFilter.bind(this) (null, 'user_list')

      // Get Results
      inCharge_list = await this.apiCall ('Users', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, filters)
      inCharge_list.values = inCharge_list.values.map (u => {return {label: u.code, value: u.id}})
      // In expert mode show user "All" too
      if ( this.state.profile.expert && this.state.profile.showAll ) {
        inCharge_list.values.push ({value: 0, label: 'Tous'})
      }
    }

    return inCharge_list
  }


  /*
   * Set query filters restricting the current user to only object he is in charge of
   */
  static setInChargeFilter (filters=null, subject='inCharge') {

    if ( this.state.profile.id ) {

      // Filter only companies owned by the current user
      if ( !this.state.profile.expert ) {
        //console.log ('Standard mode : only me')
        filters = this.defaultFilters (filters, {[subject]: {value: [this.state.profile.id]}})

      // In expert mode show also companies owned by the manager crews members
      // Each user manage at list the "All" virtual user, so these companies will appears too
      } else {
        // If current user is global manager, do not filter in expert mode, show every companies
        if ( !this.state.profile.globalManager ) {
          //console.log ("Expert mode : me, the members of my crews and nobody")
          filters = this.defaultFilters (filters, {manager_id: {value: this.state.profile.id}})
        }
        //else { console.log ('Expert mode and global manager : absolutly everybody') }
      }

      if ( ! this.state.profile.showAll ) {
          filters = this.defaultFilters (filters, {hideAll: {value: true}})
      }
    }

    return filters
  }


  /*
   * Map an object's 0 inCharge_id with "anybody"
   */
  static mapInChargeAnyBody (objects) {
    objects.values = objects.values.map (o => {o.inCharge = o.inCharge || 'Tous'; return (o)})
    return objects
  }


  /*
   * Set filters according HR reference team
   */
  static setReferenceTeamFilter (filters=null) {
    if ( this.state.profile.cVLibrary ) {
      return this.defaultFilters (filters, {referenceTeam: {value : this.state.profile.cVLibrary}})
    }
    return this.defaultFilters (filters, {noReferenceTeam: {value : null}})
  }

}

