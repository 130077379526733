import React, {Component} from 'react'
import {OrganizationChart} from 'primereact/organizationchart'
import {Display} from './Display'
import {Picture} from './Picture'
import './Organigram.css'

export class Organigram extends Component {

  constructor(props) {
    super(props)

    this.state = {

      organigram: [
        {
          label: "Construction de l'organigramme...",
          manager : '',
          className: 'organigram-node-top',
          expanded: true,
        }
      ],
    }

    this.loadValues = this.loadValues.bind(this)
    this.openTeam = this.openTeam.bind(this)
    this.nodeTemplate = this.nodeTemplate.bind(this)
  }


  /*
   * Data managment methods
   * ==================================================================
   */

  /*
   * LoadValues : call parent loader function to get data
   */
  async loadValues () {

    const organigram = []
    let children = organigram
    let filters = null

    // 1) Find the team current user belongs to if any
    filters = { member_id: {value: this.props.userId} }
    const teams = await this.props.getTeams (0, Number.MAX_SAFE_INTEGER, false, false, filters)
    if ( teams.totalRecords ) {
      const team = teams.values[0]
      const node = {
        label: team.name,
        logo: team.logo,
        manager: team.manager_name,
        children: [],
        expanded: true,
        className: 'organigram-node-top',
      }
      organigram.push (node)
      children = node.children
    }

    // 2) Find all teams managed by the current user
    filters = { top_manager_id: {value: this.props.userId} }
    const crewList = await this.props.getTeams (0, Number.MAX_SAFE_INTEGER, false, false, filters)

    const crews = await this.getCrews (this.props.userId, crewList.values)
    if ( crews.length ) {
      if ( children === organigram ) {
        crews[0].className = 'organigram-node-top organigram-node-current'
      }
      children.push (...crews)
    } else {
      // if current user does not manage any crew, create an empty leaf
      const filters = {user_id: {value: this.props.userId}}
      const userInfos = await this.props.getMembers (0, 1, false, false, filters)
      if ( userInfos.totalRecords ) {
        const user = userInfos.values[0]
        children.push (
          {
            label: user.firstName + ' ' + user.lastName,
            manager: '',
            className: 'organigram-node-leaf organigram-node-current',
          }
        )
      }
    }

    if (organigram.length) {
      this.setState ({organigram: organigram})
    } else {
      this.setState (
        {
          organigram: [
            {
              label: "Aucune équipe trouvée.",
              manager : '',
              className: 'organigram-node-top',
            }
          ],
        }
      )
    }
  }

  /*
   * getCrews : Get a list of crews managed by a user
   */
  async getCrews (userId, crewList) {

    const crews = []
    if ( userId ) {
      // Get la list of team managed by the user
      const teams = crewList.filter ( c => c.manager_id === userId )

      for (let t=0; t<teams.length; t++) {

        // For all of them, insert an entry in the crews list
        const team = teams[t]
        const crew = {
          label: team.name,
          logo: team.logo,
          teamId: team.id,
          manager: team.manager_name,
          children: [],
          expanded: true,
          className: 'organigram-node',
        }
        if ( team.manager_id === this.props.userId ) {
          crew.className += ' organigram-node-current'
        }
        crews.push (crew)

        // Get the list of teams managed by a member of the current team
        const nextLevel = crewList.filter ( c => c.manager_team === team.id )
        // Get the list these team's managers
        // the [...new set ()] stuff garantee uniqueness of manager_id
        const subManagers = [...new Set (nextLevel.map (c => c.manager_id))]
        // Add sub crews trees to the curent team child list
        for (let n=0; n<subManagers.length; n++) {
          const subCrews = await this.getCrews (subManagers[n], crewList)
          crew.children.push (...subCrews)
        }

      }
    }
    return crews
  }

  /*
   * Open a team
   */
  openTeam (event) {
    if ( this.props.onOpen && event.node.teamId ) {
      this.props.onOpen ({data: {id: event.node.teamId}})
    }
  }

  /*
   * Refresh data when userId changes
   */
  componentDidUpdate (prevProps, prevState) {
    if ( this.props.userId && prevProps.userId !== this.props.userId ) {
      this.loadValues ()
    }
  }

  /*
   * Component rendering
   */

  nodeTemplate(node) {
    if ( node === undefined || !node || node.label === undefined ) {
      return null
    }
    if ( !node.manager ) {
        return (
          <div>
            <div className="node-content">
              <div>{node.label}</div>
            </div>
          </div>
        )
    }
    return (
      <div className="">
        <div className="node-header"><h1>{node.label}</h1></div>
        <Display if={node.logo}>
          <Picture
            maxWidth="135"
            maxHeight="50"
            locked={true}
            onLoad={this.props.getLogo}
            value={node.logo}
          />
        </Display>
        <div className="node-content">
          <div>{node.manager}</div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <OrganizationChart
        value={this.state.organigram}
        selectionMode="single"
        onNodeSelect={this.openTeam}
        nodeTemplate={this.nodeTemplate}>
      </OrganizationChart>
    )
  }

}

