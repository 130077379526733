import React from 'react'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import {EventTable} from './EventTable'

class SalesEventList extends AuthenticatedComponent {

  doRender() {
    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Evénements clients</h1>

              <div className="p-grid">
                <div className="p-col-12">
                  <EventTable filters={{className: { value: 'sales' }}}/>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default SalesEventList
