import React from 'react'

export class EventService {

  /*
   * Events computed states values helpers
   */

  /* States definition */
  static states = [
    {label: "Terminé", value: "done", type: "Fixed", color: "ForestGreen", icon: "pi pi-check"},
    {label: "Annulé", value: "canceled", type: "Fixed", color: "DimGray", icon: "pi pi-times"},
    {label: "En cours", value: "running", type: "computed", color: "Blue", icon: "pi pi-ellipsis-h"},
    {label: "A venir", value: "pending", type: "computed", color: "Teal", icon: "pi pi-calendar"},
    {label: "En retard", value: "late", type: "computed", color: "Red", icon: "pi pi-spin pi-clock"},
    {label: "Rappel", value: "alert", type: "computed", color: "Magenta", icon: "pi faa-ring animated pi-bell"},
  ]

  /* Computed Dropdownn event states */
  static currentState_list (editMode=false, startDate=0, endDate=0, alertDate=0) {
    if ( !editMode ) {
      return EventService.states
    }
    const availableStates = EventService.states.filter (s => s.type === "Fixed")
    const today = EventService.toDate (new Date())
    startDate = EventService.toDate (startDate)
    endDate = endDate ? EventService.toDate (endDate) : startDate
    alertDate = alertDate ? EventService.toDate (alertDate) : null

    if ( alertDate && alertDate <= today ) {
      availableStates.push (EventService.states.find (s => s.value === "alert"))
    } else if ( startDate > today ) {
      availableStates.push (EventService.states.find (s => s.value === "pending"))
    } else if ( !alertDate && endDate < today ) {
      availableStates.push (EventService.states.find (s => s.value === "late"))
    } else {
      availableStates.push (EventService.states.find (s => s.value === "running"))
    }
    return availableStates
  }

  /* Computed Form event states */
  static computeState (state, startDate=0, endDate=0, alertDate=0) {
    const model = EventService.states.find (s => s.value === state)
    if ( model && model.type === 'Fixed' ) {
      return state
    }
    const today = EventService.toDate (new Date())
    startDate = EventService.toDate (startDate)
    endDate = endDate ? EventService.toDate (endDate) : startDate
    alertDate = alertDate ? EventService.toDate (alertDate) : null

    if ( startDate > today ) {
      return "pending"
    }
    if ( alertDate) {
      if ( alertDate <= today ) {
        return "alert"
      } else {
        return "running"
      }
    }
    if ( endDate < today ) {
      return ("late")
    }
    return "running"
  }

  /* computes Event's state label */
  static computeStateLabel (state, startDate=0, endDate=0, alertDate=0) {
    const stateValue = EventService.computeState (state, startDate, endDate)
    const stateInfos = EventService.states.find (s => s.value === stateValue)
    if ( stateInfos ) {
      return stateInfos['label']
    }
    return ""
  }

  /* Computed database event states */
  static cleanupState (state) {
    const model = EventService.states.find (s => s.value === state)
    if ( model && model.type === 'Fixed' ) {
      return state
    }
    return "computed"
  }

  /* Computed Table event states template */
  static stateTemplate(rawData, column) {
    let style = {}
    let icon = ''
    const startDate = new Date (rawData['startDate'])
    const endDate = rawData['endDate'] ? new Date (rawData['endDate']) : null
    const alertDate = rawData['alertDate'] ? new Date (rawData['alertDate']) : null
    const state = EventService.computeState (rawData['state'], startDate, endDate, alertDate)
    const model = EventService.states.find (s => s.value === state)
    if ( model['color'] !== undefined && model['color'] ) {
      style = {color: model['color']}
    }
    if ( model['icon'] !== undefined && model['icon'] ) {
      icon = <i className={model['icon']} style={{marginRight: '1em'}}/>
    }
    return <div style={style}>{icon}{model['label']}</div>
  }

  /* Helper function : returns the day corresponding to a date and time */
  static toDate (dateTime) {
    return new Date (dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate())
  }

}

