import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import ApplicantNextStepList from './ApplicantNextStepList'
import ApplicantNextStepForm from './ApplicantNextStepForm'

export class ApplicantNextSteps extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Etapes/CVthèque' component={ApplicantNextStepList} />
        <Route path='/Etapes/CVthèque/:id' component={ApplicantNextStepForm} />
      </Switch>
    )
  }
}
