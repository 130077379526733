import React, {Component} from 'react'
import {DataScroller} from 'primereact/datascroller'

export class Scroller extends Component {

  constructor(props) {
    super(props)

    this.state = {
      dataTableValue: [],
      totalRecords: null,
      isLoading: true,
    }

    this.loadValues = this.loadValues.bind(this)
    this.setRef = this.setRef.bind(this)
    this.rows = props.rows ?? 50
  }


  /*
   * Data managment methods
   * ==================================================================
   */

  /*
   * LoadValues : call parent loader function to get a slice of data
   */
  async loadValues (event) {
    if ( this.state.totalRecords === null || event.first < this.state.totalRecords ) {
      this.setState({isLoading: true})
      const items = await this.props.getValues (event.first, event.rows)
      await this.setState (
        prevState => {
          return {
            isLoading: false,
            totalRecords: items.totalRecords,
            dataTableValue: [...prevState.dataTableValue, ...items.values]
          }
        }
      )
    }
  }

  setRef (ref) {
    if ( this.ref === undefined ) {
      this.ref = ref
      this.ref.contentElement.classList.add('stretch-box-content')
    }
  }


  /*
   * Component rendering
   */
  render() {
    return (
      <div className="p-grid">
        <div className="p-col-12" onClick={this.props.onNewItem} style={{cursor: 'pointer'}}>
          <b><i className="fa fa-plus-circle" style={{fontSize: '1.1em', margin:'4px 4px'}}></i></b>
          {this.props.title}
        </div>
        <div className="p-col-12 stretch-box-item" style={{paddingBottom: 0}}>
          <DataScroller
            ref={this.setRef}
            header={this.props.header ()}
            inline={true}
            scrollHeight="1px"
            value={this.state.dataTableValue}
            rows={this.rows}
            lazy={true}
            onLazyLoad={this.loadValues}
            itemTemplate={this.props.itemTemplate}
          />
        </div>
      </div>
    )
  }
}
