export class MailingService {

  /*
   * User's Unit definition
   */

  static field_list = [
    // "simple" fields (not prefixed) are reserved for recipient
    {label: 'Nom complet', value: 'name'},
    {label: 'Nom de famille', value: 'lastName'},
    {label: 'Prénom', value: 'firstName'},
    {label: 'Civilité', value: 'prefix'},
    // "colon" fields are used for email signature (must be present in sender data)
    {label: 'Signature complète', value: ':mailingSignFull'},
    {label: 'Signature simplifiée', value: ':mailingSignSimple'},
    // "plus" fields are used for sender data
    // ex: {label: 'Département', value: '+unit'},
  ]

  /*
   * Replace all field values by their corresponding labels
   */
  static replaceFieldValueByLabels (rawData) {
    const token = rawData.match (/{{[^}]+}}/g)
    if ( token ) {
      for (let i=0; i<token.length; i++) {
        const field = MailingService.field_list.find (s => s.value === token[i].replace (/[{}]/g, ''))
        if ( field ) {
          rawData = rawData.replace (token[i], '{{' + field.label + '}}')
        } else {
          // Should never happens but prevent from endless loop :-)
          rawData = rawData.replace (token[i], '') ;
        }
      }
    }
    return rawData
  }

  /*
   * Replace all field labels by their values
   */
  static replaceFieldLabelsByValues (rawData) {
    const token = rawData.match (/{{[^}]+}}/g)
    if ( token ) {
      for (let i=0; i<token.length; i++) {
        const field = MailingService.field_list.find (s => s.label === token[i].replace (/[{}]/g, ''))
        if ( field ) {
          rawData = rawData.replace (token[i], '{{' + field.value + '}}')
        } else {
          // Should never happens but prevent from endless loop :-)
          rawData = rawData.replace (token[i], '') ;
        }
      }
    }
    return rawData
  }

}

