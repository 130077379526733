import React from 'react'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'

export class Footer extends AuthenticatedComponent {

  doRender() {
    return  (
      <div className="layout-footer">
        <span className="footer-text" style={{'marginRight': '5px'}}>GesCo 2.0 &copy; Octarine Solutions 2019</span>
      </div>
    )
  }

}
