import React from 'react'
import {EventService} from '../../services/EventService'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {Scroller} from '../../components/Scroller'
import '../../components/Scrollers.css'

export class EventScroller extends AuthenticatedTable {
  constructor(props) {
    super (props, 'event', '/Evénements', 'Events')

    this.renderItem = this.renderItem.bind(this)
    this.getValues = this.getValues.bind(this)
    this.handleCreate = this.handleCreate.bind(this)

  }

  handleCreate () {
    super.handleCreate (this.addParameters (this.props.objectId, this.props.inChargeId))
  }


  getValues (first=0, rows=0, sort=null, filter=null, filters=null) {
    filters = this.defaultFilters (filters, {object_id: {value : this.props.objectId}})
    return super.getValues (first, rows, sort, filter, filters)
  }

  renderHeader () {
    return (
      <div className="p-grid">
        <div className="p-col-2 scroller-text-left">
          Dates
        </div>
        <div className="p-col-7 scroller-text-left">
          Description
        </div>
        <div className="p-col-2 scroller-text-left">
          Type
        </div>
        <div className="p-col-1 scroller-text-left">
          <i className="pi pi pi-user"></i>
        </div>
      </div>
    )
  }

  /*
   * Rendering item template
   * Used to display each items in the DataView component
   */
  renderItem(v) {
    const realDate = v.realDate ? new Date (v.realDate) : null
    const date = realDate ? realDate.toLocaleDateString() : ""
    const startDate = v.startDate ? new Date (v.startDate) : null
    const endDate = v.endDate ? new Date (v.endDate) : null
    const alertDate = v.alertDate ? new Date (v.alertDate) : null

    return (
      <div className="p-grid scroller-item-separator" onDoubleClick={() => this.handleOpen ({data: v})}>
        <div className="p-col-2">
          {date}
        </div>
        <div className="p-col-7 scroller-item-content">
          {v.description}
        </div>
        <div className="p-col-2">
          {v.type}<br/>
          {EventService.computeStateLabel (v.state, startDate, endDate, alertDate)}
        </div>
        <div className="p-col-1">
          {v.inCharge}
        </div>
      </div>
    )
  }

  doRender() {
    // Do not initialise scroller before having an object ID !
    if (! this.props.objectId) {
      return null
    }
    return (
      <Scroller
        title="Evénements"
        header={this.renderHeader}
        itemTemplate={this.renderItem}
        getValues={this.getValues}
        onNewItem={this.handleCreate}
        watch={this.state.profile}
      >
      </Scroller>
    )
  }
}

