import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import NeedStateList from './NeedStateList'
import NeedStateForm from './NeedStateForm'

export class NeedStates extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Etats/Besoins' component={NeedStateList} />
        <Route path='/Etats/Besoins/:id' component={NeedStateForm} />
      </Switch>
    )
  }
}
