import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {Display} from '../../components/Display'
import {StretchBox} from '../../components/StretchBox'
import {Picture} from '../../components/Picture'
import {EventScroller} from './EventScroller'
import {NeedScroller} from '../needs/NeedScroller'
import {TextAreaToHTML} from '../../components/TextAreaToHTML'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {AutoComplete} from 'primereact/autocomplete'
import {Checkbox} from 'primereact/checkbox'
import {Dropdown} from 'primereact/dropdown'
import {Fieldset} from 'primereact/fieldset'
import {Attachments} from '../../components/Attachments'
import {TabView,TabPanel} from 'primereact/tabview'
import {MailingWizard} from '../../components/MailingWizard'
import {MailingService} from '../../services/MailingService'
import '../../components/Forms.css'
import './Contacts.css'

class ContactForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'contact', '/Interlocuteurs', 'Contacts',

      {

        contact: {
          id: props.match.params.id,
          prefix: "",
          prefix_id: 0,
          firstName: "",
          lastName: "",
          phone: "",
          mobile: "",
          fax: "",
          eMail: "",
          socialLink: "",
          notes: "",
          tools: "",
          customer: false,
          greetings: false,
          evening: false,
          noMailing: false,
          service: "",
          company_id: (props.match.params.company_id ? parseInt (props.match.params.company_id, 10) : 0),
          company: "",
          fullQualifiedCompany: "",
          inCharge_id: 0,
          role_id: 0,
          role: "",
        },

        company: {
          id: 0,
          name: "",
          unit: "",
          address: "",
          zip: "",
          city: "",
          eMail: "",
          phone: "",
          fax: "",
          notes: "",
          logo: null,
          inCharge: "",
          inCharge_id: 0,
        },

        constraints: {
          constraintViolationException: {
            contact_name_email: "Email, nom et prénom d'interlocuteur déjà utilisés (Il doivent être uniques).",
          }
        },

        mandatoryFields: {
          firstName: {test: FormContainer.checkNotEmpty, state: false},
          lastName: {test: FormContainer.checkNotEmpty, state: false},
          eMail: {test: t =>{return FormContainer.checkEmail (t, true)}, state: false},
          //phone: {test: t =>{return FormContainer.checkFrenchPhoneNumber (t, true)}, state: false},
          phone: {test: t => FormContainer.checkIntlPhoneNumber (t, true), state: false},
          //mobile: {test: t =>{return FormContainer.checkFrenchPhoneNumber (t, true)}, state: false},
          mobile: {test: t => FormContainer.checkIntlPhoneNumber (t, true), state: false},
          //fax: {test: t =>{return FormContainer.checkFrenchPhoneNumber (t, true)}, state: false},
          fax: {test: t =>{return FormContainer.checkIntlPhoneNumber (t, true)}, state: false},
        },

        reloadOnChangeFields: [
          'company_id'
        ],


        // See comments into formContainer for a full documentation of this :-)
        autoCompleteFields: {
          fullQualifiedCompany: {
            service: 'Companies',
            suggestions: null,
            data: null,
            key: 'fullQualifiedCompany',
            id: 'company_id',
          },
        },

        role_list: [],
        prefix_list: [],

        currentUserCanManageThisContact: false,
      }
    )

    //  Local specifics handlers
    this.handleOpenCompanyTarget = this.handleOpenCompanyTarget.bind(this)

    this.field_list = MailingService.field_list
    this.template_list = []

  }


  /*
   * Form data management
   */

  async getValues (...args) {
    // Wait for contact infos then get company infos
    await super.getValues (...args)
    const company = await this.apiCall ( 'Companies', 'Get', this.state.contact.company_id )
    await this.setState ({company: company})

  }


  async getExternalValues () {

    // Populate prefix dropdown selector options
    const prefix_list = await this.apiCall ('PersonCivilities', 'list')

    // Populate role dropdown selector options
    const role_list = await this.apiCall ( 'Roles', 'list')


    // Determine id the current user can manage the contact
    const currentUserCanManageThisContact =
      !this.state.contact.inCharge_id
      || this.state.profile.globalManager
      || this.state.profile.id === this.state.contact.inCharge_id

    await this.setState (
      {
        prefix_list: prefix_list.values,
        role_list: role_list.values,
        currentUserCanManageThisContact: currentUserCanManageThisContact,
      }
    )

    // Get Mailling template list
    this.template_list = await this.mailingTemplateList ()
  }

  /* Open the contact's company */
  handleOpenCompanyTarget () {
    const route = this.getAppRoutes().find (r => r.service === 'Companies')
    if ( ! route.access || route.access () ) {
      this.context.pushRoute (route.route + '/' + this.state.company.id, this.getContext())
    } else {
      // TODO : Do a nice warning pannel !
      alert ("Accés refusé.")
    }
  }

  doRender() {

    let title = ''
    if (this.state.contact.prefix) {
      title += this.state.contact.prefix + ' '
    }
    if (this.state.contact.firstName) {
      title += this.state.contact.firstName + ' '
    }
    if (this.state.contact.lastName) {
      title += this.state.contact.lastName + ' '
    }

    let address = ''
    if (this.state.company.address) {
      address += this.state.company.address
    }
    if (this.state.company.zip) {
      if (address) {
        address += '  -  '
      }
      address += this.state.company.zip
    }
    if (this.state.company.city) {
      if (address) {
        address += '  '
      }
      address += this.state.company.city
    }

    let role = ''
    if ( this.state.contact.role ) {
      role = ' ' + this.state.contact.role
    }

    let service = ''
    if (this.state.contact.service) {
      service = ' (Service ' + this.state.contact.service + ')'
    }

    const mailFilters = {mailingIds: {value: [this.state.contact.id]}}

    let mailTo = ''
    if (this.state.contact.firstName) {
      mailTo = this.state.contact.firstName
    }
    if (this.state.contact.lastName) {
      mailTo += mailTo ? (' ' + this.state.contact.lastName) : this.state.contact.lastName
    }
    mailTo += mailTo ? (' <' + this.state.contact.eMail  + '>') : this.state.contact.eMail

    const mailFrom =
      (this.state.profile.mailingName ? this.state.profile.mailingName : this.state.profile.username)
      + ' <' + (this.state.profile.mailingFrom ? this.state.profile.mailingFrom : this.state.profile.email) + '>'

    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>
                  <div className="p-grid p-justify-between contact-form-company-block">
                    <div className="p-col-12 p-md-5" onClick={this.handleOpenCompanyTarget} style = {{cursor: 'pointer'}}>
                      {this.state.company.name} {this.state.company.unit && <span style={{color:'grey'}}>({this.state.company.unit})</span>}
                    </div>
                    <div className="p-col-12 p-md-2">
                      <Display if={this.state.company.logo}>
                        <Picture
                          style={{float: 'left'}}
                          className="form-input"
                          id="logo"
                          maxWidth="100"
                          maxHeight="30"
                          //height="30px"
                          locked={true}
                          onLoad={this.getPicture}
                          value={this.state.company.logo}
                        />
                      </Display>
                    </div>
                    <div className="p-col-12 p-md-5 form-text-align-md-right">
                      Gérée par {this.state.company.inCharge ? this.state.company.inChargeName : 'Tous'}
                    </div>
                    <div className="p-col-12 contact-form-company-info-block">
                      <Fieldset legend="Coordonnées">
                        <div className="p-col-12 p-grid">
                          <div className="p-col-12 p-md-10 p-md-offset-1">
                            <b><i className="pi pi-envelope"></i></b>
                            {address || 'Non spécifié.'}
                          </div>
                          <div className="p-col-12 p-md-2 p-md-offset-1">
                            <b><i className="fas fa-phone"></i> </b>
                            {this.state.company.phone || 'Non spécifié.'}
                          </div>
                          <div className="p-col-12 p-md-4 form-text-align-md-center">
                            <b><i className="fas fa-globe-europe"></i> </b>
                            {this.state.company.webSite || 'Non spécifié.'}
                          </div>
                          <div className="p-col-12 p-md-4 form-text-align-md-right">
                            <b><i className="fas fa-at"></i> </b>
                            {this.state.company.eMail || 'Non spécifié.'}
                          </div>
                        </div>
                      </Fieldset>
                    </div>
                  </div>
                </h1>
                <FormContainer
                  values={this.state.contact}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <TabView>

                    <TabPanel header="Informations" rightIcon="pi pi-info">
                      <div className="form-new-line p-grid">

                        {/*
                            -----------------------------------------------------------------------

                                                    DISPLAY ONLY MODE

                            ------------------------------------------------------------------------
                        */}


                        <Display if={!this.state.editMode}>

                          <div className="p-col-12 p-grid">
                            <div className="p-col-12 p-md-10">
                              <Display if={this.state.contact.socialLink}>
                                <a href={this.state.contact.socialLink} target="_blank" rel="noopener noreferrer">
                                  <b><i className="fa-border fa-pull-left fab fa-linkedin-in"></i></b>
                                </a>
                              </Display>
                              <b style={{fontSize: "1.5em"}}>{title}</b>
                              {role}
                              <span style={{color:'grey'}}>{service}</span>
                            </div>
                            <div className="p-col-12 p-md-2 form-text-align-md-right">
                              {this.state.contact.customer?"Client": "Prospect"}
                            </div>


                            <div className="p-col-12 p-md-3">
                              <Display if={this.state.contact.evening}>
                                <b><i className="fas fa-moon"></i> </b>Joingnable en soirée
                              </Display>
                              <Display if={!this.state.contact.evening}>
                                <b><i className="fas fa-sun"></i> </b>En journée uniquement
                              </Display>
                            </div>
                            <div className="p-col-12 p-md-2 form-text-align-md-center">
                              <b><i className="fas fa-phone"></i> </b>
                              {this.state.contact.phone || 'Non spécifié.'}
                            </div>
                            <div className="p-col-12 p-md-2 form-text-align-md-center">
                              <b><i className="fas fa-mobile-alt"></i> </b>
                              {this.state.contact.mobile || 'Non spécifié.'}
                            </div>
                            <Display if={this.state.contact.eMail}>
                              <div
                                className="p-col-12 p-md-5 form-text-align-md-right"
                                style = {{cursor: 'pointer'}}
                                onClick={() => this.openMailing (null, null, mailFilters, mailTo)}
                              >
                                <span className="fa-stack" style={{verticalAlign: 'top', marginTop: '-2px', marginLeft: '-5px'}}>
                                  <b><i className="fas fa-square fa-stack-2x" style={{color: '#339af0'}}></i> </b>
                                  <b><i className="fas fa-at fa-stack-1x fa-inverse"></i> </b>
                                </span>
                                {this.state.contact.eMail}
                              </div>
                            </Display>
                            <Display if={!this.state.contact.eMail}>
                              <div className="p-col-12 p-md-5 form-text-align-md-right">
                                <b><i className="fas fa-at"></i> </b> Non spécifié.
                              </div>
                            </Display>
                          </div>

                          <Display if={this.state.contact.notes}>
                            <div className="p-col-12 p-grid">
                              <div className="p-col-12">
                                <Fieldset legend="Notes">
                                  <TextAreaToHTML>{this.state.contact.notes}</TextAreaToHTML>
                                </Fieldset>
                              </div>
                            </div>
                          </Display>
                          <Display if={this.state.contact.tools}>
                            <div className="p-col-12 p-grid">
                              <div className="p-col-12">
                                <Fieldset legend="Environnement Technique et Fonctionnel">
                                  <TextAreaToHTML>{this.state.contact.tools}</TextAreaToHTML>
                                </Fieldset>
                              </div>
                            </div>
                          </Display>

                          <StretchBox className="p-col-12 p-grid" id="scroller-container" until=".layout-footer" placeHolder={20} minItemsHeight="250px">

                            <div className="p-col-12 p-md-6">
                              <EventScroller
                                getContext={() => this.context.get()}
                                objectId={this.state.contact.id}
                                inChargeId={this.state.contact.inCharge_id}
                              />
                            </div>

                            <div className="p-col-12 p-md-6">
                              <NeedScroller
                                getContext={() => this.context.get()}
                                contactId={this.state.contact.id}
                                inChargeId={this.state.contact.inCharge_id}
                              />
                            </div>

                          </StretchBox>

                          <MailingWizard
                            sender={mailFrom}
                            recipient={this.state.mailing.mailTo}
                            fields={this.field_list}
                            doMailing={this.state.mailing.open}
                            templates={this.template_list}
                            getTemplate={this.mailingTemplateGet}
                            mailingDone={this.sendMailing}
                            maxAttachmentsSize={5000000}
                          />

                        </Display>


                        {/*
                            -----------------------------------------------------------------------

                                                       EDITION MODE

                            ------------------------------------------------------------------------
                        */}


                        <Display if={this.state.editMode}>

                          <Display if={this.state.contact.id !== '0' && this.state.currentUserCanManageThisContact}>
                            <div className="p-col-12 p-grid p-justify-even">
                              <div className="p-col-12 p-md-2">
                                <label htmlFor="fullQualifiedCompany">Entreprise</label>
                              </div>
                              <div className="p-col-12 p-md-6">
                                <AutoComplete
                                  className="form-input"
                                  placeholder="Entrez quelques lettres du nom de l'entreprise que vous cherchez ..."
                                  autoComplete="off"
                                  inputId="fullQualifiedCompany"
                                  id="fullQualifiedCompany"
                                  value={this.state.contact.fullQualifiedCompany}
                                  onChange={this.handleInputText}
                                  suggestions={this.state.autoCompleteFields.fullQualifiedCompany.suggestions}
                                  completeMethod={FormContainer.getSuggestions.bind (this, 'fullQualifiedCompany')}
                                  onFocus={this.saveAutoComplete}
                                  onBlur={this.cleanUpAutoComplete}
                                  onSelect={this.cleanUpAutoComplete}
                                />
                              </div>
                            </div>
                          </Display>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="prefix">Préfixe</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <Dropdown
                                className="form-input"
                                id="prefix"
                                options={this.state.prefix_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.contact.prefix_id}
                                onChange={this.handleExternalSelect}
                                showClear={true}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="firstName" isMissing={this.state.mandatoryFields.firstName.state}>Prénom</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="firstName"
                                autoFocus={true}
                                value={this.state.contact.firstName}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpFirstName}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="lastName" isMissing={this.state.mandatoryFields.lastName.state}>Nom</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="lastName"
                                value={this.state.contact.lastName}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpLastName}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="service">Service</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="service"
                                value={this.state.contact.service}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpText}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="role">Fonction</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <Dropdown
                                className="form-input"
                                id="role"
                                options={this.state.role_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.contact.role_id}
                                onChange={this.handleExternalSelect}
                                showClear={true}
                                autoWidth={false}
                              />
                            </div >
                          </div >


                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="phone" isMissing={this.state.mandatoryFields.phone.state} reminder="">Téléphone</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="phone"
                                value={this.state.contact.phone}
                                onChange={this.handleInputText}
                                //onBlur={this.cleanUpSimpleFrenchPhoneNumber}
                                onBlur={this.cleanUpIntlPhoneNumber}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="mobile" isMissing={this.state.mandatoryFields.mobile.state} reminder="">Mobile</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="mobile"
                                value={this.state.contact.mobile}
                                onChange={this.handleInputText}
                                //onBlur={this.cleanUpSimpleFrenchPhoneNumber}
                                onBlur={this.cleanUpIntlPhoneNumber}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="fax" isMissing={this.state.mandatoryFields.fax.state} reminder="">Fax</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="fax"
                                value={this.state.contact.fax}
                                onChange={this.handleInputText}
                                //onBlur={this.cleanUpSimpleFrenchPhoneNumber}
                                onBlur={this.cleanUpIntlPhoneNumber}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="eMail" isMissing={this.state.mandatoryFields.eMail.state} reminder="">Courriel</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="eMail"
                                value={this.state.contact.eMail}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpEmail}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="socialLink">LinkedIn ou site social</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="socialLink"
                                value={this.state.contact.socialLink}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpUrl}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="notes">Notes</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputTextarea
                                rows={5}
                                cols={80}
                                autoResize={true}
                                className="form-input"
                                id="notes"
                                value={this.state.contact.notes}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="tools">Environnement Technique et Fonctionnel</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputTextarea
                                rows={5}
                                cols={80}
                                autoResize={true}
                                className="form-input"
                                id="tools"
                                value={this.state.contact.tools}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="customer">Client</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <Checkbox
                              className="form-input"
                              inputId="customer"
                              value="customer"
                              onChange={this.handleCheckBox}
                              checked={this.state.contact.customer}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="greetings">Vœux de fin d'année</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <Checkbox
                              className="form-input"
                              inputId="greetings"
                              value="greetings"
                              onChange={this.handleCheckBox}
                              checked={this.state.contact.greetings}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="evening">Peut être appelé en soirée</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <Checkbox
                              className="form-input"
                              inputId="evening"
                              value="evening"
                              onChange={this.handleCheckBox}
                              checked={this.state.contact.evening}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="noMailing">Pas de mailing</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <Checkbox
                              className="form-input"
                              inputId="noMailing"
                              value="noMailing"
                              onChange={this.handleCheckBox}
                              checked={this.state.contact.noMailing}
                              />
                            </div>
                          </div>

                        </Display>

                      </div>

                    </TabPanel>

                    <TabPanel header="Pièces jointes" rightIcon="pi pi-paperclip">
                      <div className="form-new-line p-grid">
                        <div className="p-col-12 form-new-line">
                          <label htmlFor="attachments">Pièces jointes associées :</label>
                        </div>
                        <div className="p-col-12">
                          <Attachments
                            id="attachments"
                            locked={this.state.editMode}
                            getValues={this.getAttachmentsValuesFromObject}
                            getVersions={this.getAttachmentVersions}
                            setVersions={this.setAttachmentVersions}
                            delete={this.deleteAttachment}
                            doUpload={this.fileUpload}
                            doDownload={this.fileDownload}
                            watch={this.state.profile}
                          >
                          </Attachments>
                        </div>
                      </div>
                    </TabPanel>

                  </TabView>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactForm
