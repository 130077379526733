import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import ApplicantOriginList from './ApplicantOriginList'
import ApplicantOriginForm from './ApplicantOriginForm'

export class ApplicantOrigins extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Origines/CVthèque' component={ApplicantOriginList} />
        <Route path='/Origines/CVthèque/:id' component={ApplicantOriginForm} />
      </Switch>
    )
  }
}
