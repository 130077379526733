import React from 'react'
import {EventService} from '../../services/EventService'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {Display} from '../../components/Display'
import {InputTextarea} from 'primereact/inputtextarea'
import {Dropdown} from 'primereact/dropdown'
import {Calendar} from 'primereact/calendar'
import {Button} from 'primereact/button'
import {Attachments} from '../../components/Attachments'
import {TabView,TabPanel} from 'primereact/tabview'
import '../../components/Forms.css'


class EventForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'event', '/Evénements', 'Events',

      {
        event: {
          id: props.match.params.id,
          description: "",
          startDate: new Date (),
          endDate: new Date (),
          alertDate: "",
          type: "",
          type_id: 0,
          state: "done",
          currentState: "done",
          object_id: (props.match.params.object ? parseInt (props.match.params.object, 10) : 0),
          objectType: "",
          inChargeName: "",
          inCharge_id: 0,
          itemName: "",
        },

        mandatoryFields: {
          description: {test: FormContainer.checkNotEmpty, state: false},
          type: {test: FormContainer.checkNotEmpty, state: false},
          state: {test: FormContainer.checkNotEmpty, state: false},
          startDate: {test: FormContainer.checkNotEmpty, state: false},
        },

        type_list: [],
        inCharge_list: [],

        currentState_list: EventService.states,
      }
    )

    //  Local specifics handlers
    this.handleOpenEventTarget = this.handleOpenEventTarget.bind(this)
    this.handleState = this.handleState.bind(this)
    this.handleStartDate = this.handleStartDate.bind(this)
    // User requested to remove endDate
    //this.handleEndDate = this.handleEndDate.bind(this)
    this.handleAlertDate = this.handleAlertDate.bind(this)
  }



  /*
   * Form data management
   */

  async getExternalValues () {


    // Get linked item informations
    const itemInfos = await this.getObjectFromId (this.state.event.object_id)
    const className = ( itemInfos && itemInfos.eventClasses && itemInfos.eventClasses.includes('hr') ) ? 'hr' : 'other'

    // Populate type dropdown selector options
    const typeFilters = this.defaultFilters ({className : {value: itemInfos.eventClasses}})
    const type_list = await this.apiCall ('EventTypes', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, typeFilters)
    const defaultType = {id: 0, name: ''}
    const defaultTypeValue = type_list.values.find (t => t.defaultValue === true) ;
    if ( defaultTypeValue ) {
      defaultType.id = defaultTypeValue.id
      defaultType.name = defaultTypeValue.name
    }

    // Populate inCharge dropdown selector options
    //const inChargeFilters = this.defaultFilters ({unit : {value: 'rh'}})
    const inCharge_list = await this.apiCall ( 'Users', 'list', 0, Number.MAX_SAFE_INTEGER)
    inCharge_list.values = inCharge_list.values.map (u => {return {name: u.code + ' (' + u.firstName + ' ' + u.lastName + ')', id: u.id}})

    // Compute current event state
    const currentState = EventService.computeState (
      this.state.event.state,
      this.state.event.startDate,
      this.state.event.endDate,
      this.state.event.alertDate,
    )

    // Setup values
    return this.setState (
      prevState => {
        prevState.event.type_id = prevState.event.type_id || defaultType.id
        prevState.event.type = prevState.event.type || defaultType.name
        prevState.event.objectService = itemInfos.service
        prevState.event.itemName = itemInfos.itemName
        prevState.event.className = className
        prevState.event.currentState = currentState
        return {
          type_list: type_list.values,
          inCharge_list: inCharge_list.values,
          event: prevState.event,
        }
      }
    )
  }

  async componentDidUpdate(prevProps, prevState) {
    if ( await super.componentDidUpdate(prevProps, prevState) ) {
      if ( !prevState.event.object_id && this.state.event.object_id ) {
        await this.getExternalValues ()
      }
      if ( this.state.event.currentState === undefined ) {
        // Recompute current event state
        const currentState = EventService.computeState (
          this.state.event.state,
          this.state.event.startDate,
          this.state.event.endDate,
          this.state.event.alertDate,
        )
        this.setState (
          prevState => {
            prevState.event.currentState = currentState
            return {event: prevState.event}
          }
        )
      }
    }
  }

  cleanUpValues (values) {
    // Dates are received encoded as an ISO 8601 string then converted to date objecr
    values.startDate = new Date (values.startDate)
    if ( values.endDate ) {
      values.endDate = new Date (values.endDate)
    }
    if ( values.alertDate ) {
      values.alertDate = new Date (values.alertDate)
    }
  }


  /*
   * When end date changes
   * - User requested to remove end date => now end date == start date
   * - update computed state
   */
  handleStartDate (e) {
    const startDate = e.value
    this.handleCalendar (e)
    this.setState (
      prevState => {
        // Be sure that startDate is never after alertDate
        if ( this.state.event.alertDate && this.state.event.alertDate < startDate ) {
          prevState.event.alertDate = startDate
        }
        // User requested to remove end date => now end date == start date
        prevState.event.endDate = startDate
        // Force computed state
        prevState.event.currentState = EventService.computeState (
          'computed',
          startDate,
          prevState.event.endDate,
          prevState.event.alertDate
        )
        prevState.event.state = EventService.cleanupState (prevState.event.currentState)
        return {event: prevState.event}
      }
    )
  }

  /*
   * When end date changes
   * - make sure start is never afert end
   * - update computed state
  handleEndDate (e) {
    const endDate = e.value
    this.handleCalendar (e)
    this.setState (
      prevState => {
        // Be sure that startDate is never after endDate
        if ( ! this.state.event.startDate || endDate < this.state.event.startDate ) {
          prevState.event.startDate = endDate
        }
        // Update computed state if needed
        prevState.event.currentState = EventService.computeState (
          prevState.event.currentState,
          prevState.event.startDate,
          endDate,
          prevState.event.alertDate
        )
        prevState.event.state = EventService.cleanupState (prevState.event.currentState)
        return {event: prevState.event}
      }
    )
  }
  */

  /*
   * When alert date changes
   * - update computed state
   */
  handleAlertDate (e) {
    const alertDate = e.value
    this.handleCalendar (e)
    this.setState (
      prevState => {
        // Be sure that startDate is never after alertDate
        if ( ! this.state.event.startDate || (alertDate && alertDate < this.state.event.startDate) ) {
          prevState.event.startDate = alertDate
          // User requested to remove end date => now end date == start date
          prevState.event.endDate = alertDate
        }
        // Force computed state
        prevState.event.currentState = EventService.computeState (
          'computed',
          prevState.event.startDate,
          prevState.event.endDate,
          alertDate
        )
        prevState.event.state = EventService.cleanupState (prevState.event.currentState)
        return {event: prevState.event}
      }
    )
  }

  /* Update state dropdown and set real object state accordingly to computed one */
  async handleState (e) {
    await this.handleSelect (e)
    await this.setState (
      prevState => {
        prevState.event.state = EventService.cleanupState (prevState.event.currentState)
        return ({event: prevState.event})
      }
    )
  }

  /* Open the object in relation with this event */
  handleOpenEventTarget () {
    const route = this.getAppRoutes().find (r => r.service === this.state.event.objectService)
    if ( ! route.access || route.access () ) {
      this.context.pushRoute (route.route + '/' + this.state.event.object_id, this.getContext())
    } else {
      // TODO : Do a nice warning pannel !
      alert ("Accés refusé.")
    }
  }

  doRender() {
    let description = ''
    if ( this.state.event.itemName ) {
      if ( description ) {
        description += ' : '
      }
      description += this.state.event.itemName
    }
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Evénement {description}</h1>
                <FormContainer
                  values={this.state.event}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <TabView>

                    <TabPanel header="Informations" rightIcon="pi pi-info">
                      <div className="form-new-line p-grid">

                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="type" isMissing={this.state.mandatoryFields.type.state}>Type</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="type"
                              options={this.state.type_list.map (c => ({label: c.name, value: c.id}))}
                              value={this.state.event.type_id}
                              onChange={this.handleExternalSelect}
                              autoWidth={false}
                            />
                          </div >
                        </div >

                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="currentState" isMissing={this.state.mandatoryFields.state.state}>Etat</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="currentState"
                              options={
                                EventService.currentState_list(
                                  this.state.editMode,
                                  this.state.event.startDate,
                                  this.state.event.endDate,
                                  this.state.event.alertDate
                                )
                              }
                              value={this.state.event.currentState}
                              onChange={this.handleState}
                              autoWidth={false}
                            />
                          </div >
                        </div >

                        <Display if={this.state.event.itemName}>
                          <div className="p-grid p-col-12 p-md-4">
                            <div className="p-col-12">
                              <label htmlFor="openIt" >Ouvrir l'élément lié</label>
                            </div>
                            <div className="p-col-12">
                              <Button
                                id="openIt"
                                disabeld={this.props.locked}
                                className="p-button-info"
                                label={this.state.event.itemName}
                                icon="pi pi-folder-open"
                                onClick={this.handleOpenEventTarget}
                                disabled={this.state.editMode}
                              />
                            </div >
                          </div >
                        </Display>

                        <div className="p-col-12 p-grid">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="description" isMissing={this.state.mandatoryFields.description.state}>Description</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <InputTextarea
                              rows={5}
                              cols={80}
                              autoFocus={true}
                              autoResize={true}
                              className="form-input"
                              id="description"
                              disabled={!this.state.editMode}
                              value={this.state.event.description}
                              onChange={this.handleInputText}
                            />
                          </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="startDate" isMissing={this.state.mandatoryFields.startDate.state}>Date de début</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <Calendar
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="startDate"
                              value={this.state.event.startDate}
                              onChange={this.handleStartDate}
                              placeHolder=""
                              showIcon={true}
                              showButtonBar={true}
                              clearButtonStyleClass="form-hidden"
                              readOnlyInput={true}
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange="1900:2100"
                              dateFormat="dd/mm/yy"
                              locale={FormContainer.localDate('fr')}
                            ></Calendar>
                          </div>
                        </div>

                        {/* User requested to remove endDate field
                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <label htmlFor="endDate">Date de fin</label>
                          </div>
                          <div className="p-col-12">
                            <Calendar
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="endDate"
                              value={this.state.event.endDate}
                              onChange={this.handleEndDate}
                              placeHolder=""
                              showIcon={true}
                              readOnlyInput={true}
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange="1900:2100"
                              dateFormat="dd/mm/yy"
                              locale={FormContainer.localDate('fr')}
                            ></Calendar>
                          </div>
                        </div>
                        */}

                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <label htmlFor="alertDate">Date de rappel</label>
                          </div>
                          <div className="p-col-12">
                            <Calendar
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="alertDate"
                              value={this.state.event.alertDate}
                              onChange={this.handleAlertDate}
                              placeHolder=""
                              showIcon={true}
                              showButtonBar={true}
                              readOnlyInput={true}
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange="1900:2100"
                              dateFormat="dd/mm/yy"
                              locale={FormContainer.localDate('fr')}
                            ></Calendar>
                          </div>
                        </div>

                        <Display if={this.state.event.className === 'hr'}>
                          <div className="p-grid p-col-12 p-md-4">
                            <div className="p-col-12">
                              <label htmlFor="inCharge">Recruteur</label>
                            </div>
                            <div className="p-col-12">
                              <Dropdown
                                className="form-input"
                                disabled={!this.state.editMode}
                                id="inCharge"
                                options={this.state.inCharge_list.map (c => ({label: c.name, value: c.id}))}
                                value={this.state.event.inCharge_id}
                                onChange={this.handleExternalSelect}
                                autoWidth={false}
                              />
                            </div >
                          </div>
                        </Display>
                      </div>
                    </TabPanel>

                    <TabPanel header="Pièces jointes" rightIcon="pi pi-paperclip">
                      <div className="form-new-line p-grid">
                        <div className="p-col-12 form-new-line">
                          <label htmlFor="attachments">Pièces jointes associées :</label>
                        </div>
                        <div className="p-col-12">
                          <Attachments
                            id="attachments"
                            parentId={this.state.event.id}
                            locked={this.state.editMode}
                            getValues={this.getAttachmentsValues}
                            getVersions={this.getAttachmentVersions}
                            setVersions={this.setAttachmentVersions}
                            delete={this.deleteAttachment}
                            doUpload={this.fileUpload}
                            doDownload={this.fileDownload}
                            watch={this.state.profile}
                          >
                          </Attachments>
                        </div>
                      </div>
                    </TabPanel>

                  </TabView>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EventForm

