import React from 'react'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

class AdminApplicantList extends AuthenticatedTable {

  constructor(props) {
    super (props, 'applicant', '/Archives/Candidats', 'Applicants')
  }

  doRender() {
    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Archives candidats</h1>

              <div className="p-grid">
                <div className="p-col-12">
                  <LazyDataTable
                    ref={this.setRef}
                    locked={this.props.locked}
                    onDisable={this.handleDelete}
                    disableTitle="Archiver"
                    onEnable={this.handleRestore}
                    getValues={this.getAdminValues}
                    watch={this.state.profile}
                  >
                    <Column field="name" header="Nom" sortable={true} style={{width:'20%'}}/>
                    <Column
                      field="creationDate"
                      header="Créé le"
                      sortable={true}
                      style={{width:'10%'}}
                      body={LazyDataTable.dateTemplate}
                      filterMethod={LazyDataTable.dateFilter}
                    />
                    <Column field="creationUser_code" header="par" sortable={true} style={{width:'10%'}}/>
                    <Column
                      field="modificationDate"
                      header="Modifié le"
                      sortable={true}
                      style={{width:'10%'}}
                      body={LazyDataTable.dateTemplate}
                      filterMethod={LazyDataTable.dateFilter}
                    />
                    <Column field="modificationUser_code" header="par" sortable={true} style={{width:'10%'}}/>
                    <Column
                      field="deletionDate"
                      header="Archivé le"
                      sortable={true}
                      style={{width:'10%'}}
                      body={LazyDataTable.dateTemplate}
                      filterMethod={LazyDataTable.dateFilter}
                    />
                    <Column field="deletionUser_code" header="par" sortable={true} style={{width:'10%'}}/>
                    <Column
                      field="deleted"
                      header="Etat"
                      sortable={true}
                      style={{textAlign:'center', width:'10%'}}
                      body={LazyDataTable.archiveTemplate}
                      filterMethod={LazyDataTable.optionsFilter}
                      filterParams={LazyDataTable.archiveDefaultOptions}

                    />
                  </LazyDataTable>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default AdminApplicantList
