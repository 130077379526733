import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import RequestList from './RequestList'
import RequestForm from './RequestForm'

export class Requests extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Demandes' component={RequestList} />
        <Route path='/Demandes/:id/:object?' component={RequestForm} />
      </Switch>
    )
  }
}
