import React from 'react'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

export class MailingRecipientTable extends AuthenticatedTable {

  constructor(props) {
    super (props, 'mailings', '', 'Mailings')
  }

  async getValues (first=0, rows=0, sort=null, filter=null, filters=null) {
    // Set default empty values
    let values = this.defaultValues ()

    // Get recipients from the mailing object
    if ( this.props.mailingId ) {
      const recipients = await this.apiCall (
        this.serviceName,
        'recipientList',
        0, Number.MAX_SAFE_INTEGER, null, null,
        {mailing_id: {value : this.props.mailingId}}
      )
      // Get the list of recipient Id from this
      if ( recipients.totalRecords ) {
        const recipientList = recipients.values.map (r => r.recipient_id).filter (id => id)
        // Identify the correponding object
        if ( recipientList.length ) {
          const object = await this.getObjectFromId (recipientList[0])
          // Get the real list of item
          if ( object ) {
            filters = this.defaultFilters (filters, {mailingIds: {value: recipientList}})
            values = this.apiCall (object.service, 'List', first, rows, sort, filter, filters)
          }
          const route = this.getAppRoutes().find (r => r.service === object.service)
          if ( ! route.access || route.access () ) {
            this.pathName = route.route + '/'
          }
        }
      }
    }
    return values
  }

  doRender() {
    return (
      <div>
        <LazyDataTable
          ref={this.setRef}
          onOpen={this.handleOpen}
          getValues={this.getValues}
          watch={this.state.profile}
        >
          <Column field="fullQualifiedName" header="Destinataire" sortable={true} style={{width:'22%'}} />
        </LazyDataTable>
      </div>
    )
  }
}

export default MailingRecipientTable
