import React from 'react'
import {UnitService} from '../../services/UnitService'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

export class UserTable extends AuthenticatedTable {

  constructor(props) {
    super(props, 'user', '/Utilisateurs', 'Users')

    this.userTemplate = this.userTemplate.bind(this)
  }

  getValues (first=0, rows=0, sort=null, filter=null, filters=null) {

    // Filter only users belonging to provided teamId
    // used to display only teams related to a given user form
    if ( this.props.teamId ) {
      filters = this.defaultFilters (filters, {team_id: {value : this.props.teamId}})

    // display only current user and users who belong to the teams current manage
    } else if (this.state.profile.id) {
      // If current user is global manager, do not filter, show every users
      if ( ! this.state.profile.globalManager ) {
        filters = this.defaultFilters (filters, {manager_id: {value: this.state.profile.id}})
      }
    }
    // Get results ...
    return super.getValues (first, rows, sort, filter, filters)
  }

  handleCreate () {
    return super.handleCreate (this.addParameters (this.props.teamId))
  }

  userTemplate (rawData, column) {
    if ( this.props.managerId && rawData.id === this.props.managerId ) {
      return (
        <b>{rawData[column.field]}</b>
      )
    }
    return (rawData[column.field])
  }

  doRender() {

    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        onOpen={this.handleOpen}
        onDelete={this.props.teamId?null:this.handleDelete}
        deleteTitle="Archiver"
        // You cannot delete yourself (no suicide !)
        deleteEnabler={s => !s.find (u => u.id === this.state.profile.id)}
        onCreate={this.handleCreate}
        onRemove={this.props.onRemove}
        onAdd={this.props.onAdd}
        addItemGet={this.props.addItemGet}
        addItemMode={this.props.addItemMode}
        addItemTitle={this.props.addItemTitle}
        addItemPrompt={this.props.addItemPrompt}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column field="code" header="Code" sortable={true} style={{width:'8%'}} />
        <Column field="name" header="Nom" sortable={true} style={{width:'28%'}} />
        <Column field="eMail" header="Courriel" sortable={true} style={{width:'28%'}}/>
        <Column field="function" header="Fonction" sortable={true} style={{width:'20%'}} body={this.userTemplate}/>
        <Column field="unit" header="Pôle" sortable={true} style={{width:'16%'}} body={UnitService.stateTemplate}/>
      </LazyDataTable>
    )
  }
}

