import React from 'react'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import {EventTable} from './EventTable'

class HrEventList extends AuthenticatedComponent {

  doRender() {
    const filters = {
      className: {value: 'hr'},
      referenceTeam: {value : this.state.profile.cVLibrary}
    }
    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Evénements candidats</h1>

              <div className="p-grid">
                <div className="p-col-12">
                  <EventTable filters={filters}/>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default HrEventList
