import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import UserList from './UserList'
import AdminUserList from './AdminUserList'
import UserForm from './UserForm'

export class Users extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Utilisateurs' component={UserList} />
        <Route exact path='/Archives/Utilisateurs' component={AdminUserList} />
        <Route path='/Utilisateurs/:id/:team_id?' component={UserForm} />
      </Switch>
    )
  }
}
