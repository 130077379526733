import React from 'react'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

class ExperienceList extends AuthenticatedTable {

  constructor(props) {
    super (props, 'experience', '/Personnes/Expériences', 'Experiences')
  }

  doRender() {
    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Expériences</h1>

              <div className="p-grid">
                <div className="p-col-12">
                  <LazyDataTable
                    ref={this.setRef}
                    locked={this.props.locked}
                    noAutoFocus={this.props.noAutoFocus}
                    reorderableRows={true}
                    onRowReorder={this.handleReorderRows}
                    onOpen={this.handleOpen}
                    onDisable={this.handleDelete}
                    onEnable={this.handleRestore}
                    onCreate={this.handleCreate}
                    getValues={this.getAdminValues}
                    watch={this.state.profile}
                  >
                    <Column
                      field="name"
                      header="Libellé"
                      sortable={true}
                      style={{width:'40%'}}
                    />
                    <Column
                      field="defaultValue"
                      header="Défaut"
                      filterMethod={LazyDataTable.optionsFilter}
                      filterParams={LazyDataTable.booleanDefaultOptions}
                      sortable={true}
                      style={{textAlign:'center', width:'8%'}}
                      body={LazyDataTable.booleanTemplate}
                    />
                    <Column
                      field="creationDate"
                      header="Créé le"
                      sortable={true}
                      style={{width:'10%'}}
                      body={LazyDataTable.dateTemplate}
                      filterMethod={LazyDataTable.dateFilter}
                    />
                    <Column field="creationUser_code" header="par" sortable={true} style={{width:'10%'}}/>
                    <Column
                      field="modificationDate"
                      header="Modifié le"
                      sortable={true}
                      style={{width:'10%'}}
                      body={LazyDataTable.dateTemplate}
                      filterMethod={LazyDataTable.dateFilter}
                    />
                    <Column field="modificationUser_code" header="par" sortable={true} style={{width:'10%'}}/>
                    <Column
                      field="deletionDate"
                      header="Archivé le"
                      sortable={true}
                      style={{width:'10%'}}
                      body={LazyDataTable.dateTemplate}
                      filterMethod={LazyDataTable.dateFilter}
                    />
                    <Column field="deletionUser_code" header="par" sortable={true} style={{width:'10%'}}/>
                    <Column
                      field="deleted"
                      header="Etat"
                      sortable={true}
                      style={{textAlign:'center', width:'10%'}}
                      body={LazyDataTable.disableTemplate}
                      filterMethod={LazyDataTable.optionsFilter}
                      filterParams={LazyDataTable.archiveDefaultOptions}
                    />
                    <Column rowReorder={true} style={{width: '3em'}} filterMethod={LazyDataTable.noFilter} />
                  </LazyDataTable>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default ExperienceList
