import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {Attachments} from '../../components/Attachments'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext'
import {Dropdown} from 'primereact/dropdown'
import {Checkbox} from 'primereact/checkbox'
import {ProgressBar} from 'primereact/progressbar'
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {Display} from '../../components/Display'
import '../../components/Forms.css'


class ImportForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'import', '/Imports', 'Imports',

      {
        import: {
          id: props.match.params.id,
          name: "",
          type: "",
          type_id: 0,
          state: "",
          state_id: 0,
          state_state: "",
          date: new Date (),
          prefix: "",
          addUser: true,
          file: null,
          fileName: "",
          errors: 0,
          success: 0,
        },

        mandatoryFields: {
          name: {test: FormContainer.checkNotEmpty, state: false},
          type: {test: FormContainer.checkNotEmpty, state: false},
          fileName: {test: FormContainer.checkNotEmpty, state: false},
        },

        uploadProgress: 0.0,

        state_list: [],
        type_list: [],
      }
    )

    // Upload handler
    this.uploader = React.createRef()
    this.openUploader = this.openUploader.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
    this.xhrHandle = null
    this.setXhrHandle = this.setXhrHandle.bind(this)
    this.handleProgress = this.handleProgress.bind(this)
    this.cancelSending = this.cancelSending.bind(this)
  }


  /*
   * Form data management
   */

  async getExternalValues () {

    // Populate type dropdown selector options
    const type_list = await this.apiCall ('ImportTypes', 'list')
    const defaultType = {id: 0, name: ''}
    const defaultTypeValue = type_list.values.find (t => t.name === 'CV') ;
    if ( defaultTypeValue ) {
      defaultType.id = defaultTypeValue.id
      defaultType.name = defaultTypeValue.name
    }
    const state_list = await this.apiCall ('ImportStates', 'list')
    const defaultState = {id: 0, name: ''}
    const defaultStateValue = state_list.values.find (s => s.defaultValue === true) ;
    if ( defaultStateValue ) {
      defaultState.id = defaultStateValue.id
      defaultState.name = defaultStateValue.name
    }

    const globalParameters =  this.state.import.id === '0' ? await this.getGlobalParameters () : null

    // Setup values
    await this.setState (
      prevState => {
        prevState.import.state_id = prevState.import.state_id || defaultState.id
        prevState.import.state = prevState.import.state || defaultState.name
        prevState.import.type_id = prevState.import.type_id || defaultType.id
        prevState.import.type = prevState.import.type || defaultType.name
        if ( prevState.import.id === '0' ) {
           prevState.import.prefix = globalParameters['company_import_prefix']
        }
        return {
          type_list: type_list.values,
          state_list: state_list.values,
          import: prevState.import,
        }
      }
    )
  }

  cleanUpValues (values) {
    // Dates are received encoded as an ISO 8601 string then converted to date object
    values.date = new Date (values.date)
  }


  /*
   * Send a click to the hidden input file
   * to make it open the file chooser dialog
   */
  openUploader() {
    this.uploader.current.click()
  }

  /*
   * Upload handler
   * Set import files on upload event
   * Does NOT actual upload
   */
  async handleUpload (event) {
    const file = event.target.files.item(0)
    await this.setState (
      prevState => {
        prevState.import.file = file
        prevState.import.fileName = file.name
        return {import: prevState.import}
      },
    )
  }

  /*
   * Send the actual export data
   */
  async setValues () {
    // Save import file and remove it from object velues
    const file = this.state.import.file
    await this.setState (
      prevState => {
        prevState.import.file = null
        return {import: prevState.import}
      }
    )
    // Create the import object
    const newId = await this.recordValues ()
    // Send the import file as an attachment for this object
    await this.fileUpload (file, this.setXhrHandle, this.handleProgress, newId)
    // Start the actual import
    await this.apiCall ('Imports', 'start', newId)
    // Tel formContainer to close the form
    this.handleExit ()
    return false
  }

  /*
   * Set handler on current XHR upload transaction
   */
  setXhrHandle (xhr) {
    this.xhrHandle = xhr
  }

  /*
   * Compute uploading progress
   */
  handleProgress (event) {
    if (event.lengthComputable) {
      const progress = (event.loaded / event.total) * 100
      this.setState( {uploadProgress: progress})
    }
  }

  /*
   * Cancel Import attachments sending
   * Data transfert is just interrupted
   * Import is not destroyed, it will stay in partial state
   */
  cancelSending () {
    if ( this.xhrHandle !== null ) {
      this.xhrHandle.abort ()
      this.xhrHandle = null
    }
  }


  doRender() {
    const date = this.state.import.date.toLocaleDateString('fr-FT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    const sendingFooter =  (
      <div>
          <Button
            label={"Annuler"}
            icon="pi pi-times-circle"
            className={"p-button-danger"}
            onClick={this.cancelSending}
          />
      </div>
    )
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Import {this.state.import.name} du {date}</h1>
                <FormContainer
                  values={this.state.import}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  locked={this.state.import.id !== '0'}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <div className="form-new-line p-grid">

                    <Display if={this.state.import.id === '0'}>
                      <div className="p-col-12 p-grid">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="name" isMissing={this.state.mandatoryFields.name.state}>Nom de l'import</MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <InputText
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="name"
                            autoFocus={true}
                            value={this.state.import.name}
                            onChange={this.handleInputText}
                          />
                        </div>
                      </div>
                    </Display>

                    <div className="p-grid p-col-12">
                      <div className="p-grid p-col-12 p-md-4">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="type" isMissing={this.state.mandatoryFields.type.state}>Type d'import</MandatoryLabel>
                        </div>
                        <div className="p-col-12">
                          <Dropdown
                            className="form-input"
                            //disabled={!this.state.editMode}
                            disabled={true}
                            id="type"
                            options={this.state.type_list.map (c => ({label: c.name, value: c.id}))}
                            value={this.state.import.type_id}
                            onChange={this.handleExternalSelect}
                            autoWidth={false}
                          />
                        </div >
                      </div >

                      <Display if={this.state.import.id !== '0'}>
                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <label htmlFor="state">Etat</label>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={true}
                              id="state"
                              options={this.state.state_list.map (c => ({label: c.name, value: c.id}))}
                              value={this.state.import.state_id}
                              onChange={this.handleExternalSelect}
                              autoWidth={false}
                            />
                          </div >
                        </div >
                      </Display>
                    </div >

                    <Display if={this.state.import.id !== '0'}>
                      <div className="p-grid p-col-12">
                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <label htmlFor="success">Nombre d'imports réussis</label>
                          </div>
                          <div className="p-col-12">
                            <InputText
                              className="form-input"
                              disabled={true}
                              id="susccess"
                              value={this.state.import.success}
                              onChange={this.handleInputText}
                            />
                          </div >
                        </div >

                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <label htmlFor="errors">Nombre d'erreurs rencontrées</label>
                          </div>
                          <div className="p-col-12">
                            <InputText
                              className="form-input"
                              disabled={true}
                              id="errors"
                              value={this.state.import.errors}
                              onChange={this.handleInputText}
                            />
                          </div >
                        </div >

                        <Display if={this.state.import.state_state === 'running'}>
                          <div className="p-grid p-col-12 p-md-2">
                            <div className="p-col-12">
                              <label>{'\u00A0'}</label>
                            </div>
                            <div className="p-col-12">
                              <Button
                                label={"Rafraîchir"}
                                icon="pi pi-refresh"
                                className={"p-button"}
                                onClick={this.getValues}
                              />
                            </div>
                          </div>
                        </Display>

                      </div >
                    </Display>

                    <Display if={this.state.import.type === 'Clients'}>
                      <div className="p-grid p-col-12">
                        <div className="p-col-12">
                          <label htmlFor="prefix">Préfixe de création des sociétés</label>
                        </div>
                        <div className="p-col-12 p-md-4">
                          <InputText
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="prefix"
                            autoFocus={true}
                            value={this.state.import.prefix}
                            onChange={this.handleInputText}
                          />
                        </div >
                      </div >

                      <div className="p-col-12 p-grid">
                        <div className="p-col-12">
                          <Checkbox
                          className="form-input"
                          disabled={!this.state.editMode}
                          inputId="addUser"
                          value="addUser"
                          onChange={this.handleCheckBox}
                          checked={this.state.import.addUser}
                          />
                          <label htmlFor="addUser">&nbsp;Ajouter le code du commercial au préfixe</label>
                        </div>
                      </div>
                    </Display>

                    <Display if={this.state.import.id === '0'}>
                      <div className="p-grid p-col-12">
                        <div className="p-col-12">
                          <MandatoryLabel htmlFor="fileName" isMissing={this.state.mandatoryFields.fileName.state}>Fichier d'import</MandatoryLabel>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <InputText
                            className="form-input"
                            readOnly={true}
                            id="fileName"
                            value={this.state.import.fileName}
                            onClick={this.openUploader}
                          />
                        </div >
                        <div className="p-col-2">
                          <input
                            style={{display: "none"}}
                            ref={this.uploader}
                            className="FileInput"
                            accept={this.state.import.type === 'CV'?'*':'text/csv'}
                            type="file"
                            onChange={this.handleUpload}
                          />
                          <Button
                            label={"Choisir"}
                            icon="pi pi-plus"
                            className={"p-button"}
                            onClick={this.openUploader}
                          />
                        </div >
                      </div >

                      <Dialog
                        style={{width: '50%'}}
                        header="Chargement de l'import"
                        footer={sendingFooter}
                        className="sending-panel"
                        visible={this.xhrHandle !== null}
                        modal={true}
                        onHide={()=>{}}
                        closable={false}
                      >
                        <Display if={this.state.uploadProgress <= 0 || this.state.uploadProgress >= 100}>
                          <label>Préparation de l'import</label>
                          <ProgressBar mode="indeterminate" />
                        </Display>
                        <Display if={this.state.uploadProgress > 0 && this.state.uploadProgress < 100}>
                          <label>Transmission des données</label>
                          <ProgressBar value={this.state.uploadProgress.toFixed(0)} />
                        </Display>
                      </Dialog>
                    </Display>

                    <Display if={this.state.import.id !== '0'}>
                      <div className="p-col-12 p-grid">
                        <div className="p-col-12 form-new-line">
                          <label htmlFor="attachments">Fichier d'import :</label>
                        </div>
                        <div className="p-col-12">
                          <Attachments
                            id="attachments"
                            readOnly={true}
                            getValues={this.getAttachmentsValuesFromObject}
                            getVersions={this.getAttachmentVersions}
                            setVersions={this.setAttachmentVersions}
                            doDownload={this.fileDownload}
                            watch={this.state.profile}
                          >
                          </Attachments>
                        </div>
                      </div>
                    </Display>

                  </div>

                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ImportForm

