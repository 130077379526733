import decode from 'jwt-decode'
import {translate} from './Translator'

export default class AuthService {

  /* Set up the main API URL to be used for remote service calls */
  constructor(config) {

    // Global configuration
    if ( ! config ) throw Error ("No configuration data available.")
    this.config = config


    // Server APi URL
    if ( ! this.config.server ) throw Error ("Missing server URL.")
    this.domain = this.config.server

    // The application menu structure
    this.appMenu = []

    // The application global routing table
    this.appRoutes = []
    this.appDefaultRoute = null

    // The application services informations
    this.appServices = {
      services: {},
      tables: {},
    }

    this.tokenTimer = null
    this.tokenTimeoutHandlers = []
    this.onTokenTimeout = this.onTokenTimeout.bind(this)

    this.twoFactorsFacilities = {
      'AuthSimpleKey': {
        getData: this.keySimpleGetData,
        setData: this.keySimpleSetData,
        handleReject: this.keySimpleHandleReject,
      },
    }
    this.twoFactorsMethod = this.config.twoFactorsMethod ?? false
    if ( this.twoFactorsMethod && this.twoFactorsFacilities[this.twoFactorsMethod] !== undefined ) {
      this.twoFactorsData = this.twoFactorsFacilities[this.twoFactorsMethod].getData ()
    }

    this.logout = this.logout.bind (this)
    this.twoFactorsGetData = this.twoFactorsGetData.bind (this)
    this.twoFactorsSetData = this.twoFactorsSetData.bind (this)
    this.twoFactorsHandleReject = this.twoFactorsHandleReject.bind (this)

    this.eventHandlers = {}

    this.context = [{}]

    this.sessionLocked = false

    this.profileVersion = 0

    this.routerHistory = null
    this.routePath = []
    this.lockNavigation = false
  }

  /*
   * API communication methods
   * ==========================================================================
   */

  /*
   * Run a remote service call and return result
   * service = Object to instanciate on server side
   * query = method to execute
   * args = optional list of parameters
   */
  apiCall (service, query, ...args) {

    /* JSON service call */
    const request = JSON.stringify(
      {
        'Service' : service,
        'Query' : query,
        ...args
      }
    )

    return this.xhrPostRequest (request)
  }

  /*
   * Run and reference a remote service call and return result
   * handle = A callback function receiving a handle on the XHR object
   * service = Object to instanciate on server side
   * query = method to execute
   * args = optional list of parameters
   */
  apiReferencedCall (handle, service, query, ...args) {

    /* JSON service call */
    const request = JSON.stringify(
      {
        'Service' : service,
        'Query' : query,
        ...args
      }
    )

    return this.xhrPostRequest (request, handle)
  }

  /*
   * Upload an attachment on server
   *  file :          File structure as proveded by fileInput object
   *  handle :        A callback function receiving a handle on the XHR object
   *  progress :      A callback funtion used to follow the upload progress
   *  parentObject :  Identify the family of the object owner of the attachment
   *  parentId :      Identify the object owner of the attachment
   *  currentChunk :  Optional chunck parameter (if original file is sliced, this is the number of the current slice)
   *  totalChunks :   Optional chunck parameter (if original file is sliced, this is the total number of slices)
   */
  upload (file, handle = null, progress = null, parentId=1, currentChunk=1, totalChunks=1, partFileName='') {

    /* FORM DATA service call */
    const request = new FormData()
    request.append('Service', 'Attachments')
    request.append('Query', 'upload')
    request.append('ParentID', parentId)
    request.append('currentChunk', currentChunk)
    request.append('totalChunks', totalChunks)
    request.append('partFileName', partFileName)
    request.append("file", file, file.name)

    return this.xhrPostRequest (request, handle, null, progress)
  }

  /*
   * Download an attachment to server
   *  attachment :    Path of the file on the server
   *  handle :        A callback unction receiving a handle on the XHR object
   *  progress :      A callback funtion used to follow the download progress
   */
  download (attachment, handle = null, progress = null) {

    /* JSON service call */
    const request = JSON.stringify(
      {
        'Service' : 'Attachments',
        'Query' : 'download',
        attachment
      }
    )

    return this.xhrPostRequest (request, handle, progress)
  }

  /*
   * XHP generic authenticated POST Service
   */
  async xhrPostRequest (request, handle=null, downloadProgress=null, uploadProgress=null) {

    let res = await new Promise(

      (resolve, reject) => {

        const xhr = new XMLHttpRequest()

        /* Give an handle on this XHR to caller */
        if ( handle !== null ) {
          handle (xhr)
        }

        /* Monitor download */
        if ( downloadProgress !== null ) {
          xhr.onprogress = downloadProgress
        }

        /* Monitor upload */
        if ( uploadProgress !== null ) {
          xhr.upload.onprogress = uploadProgress
        }

        /* Do post call analyse */
        xhr.onload = (
          event => {
            try {
              var response = JSON.parse (event.target.response)
            } catch (e) {
              return event.target.onerror()
            }
            if (event.target.status >= 200 && event.target.status < 300) {
              /* if ok, refresh authenticatoin token */
              this.setToken(event.target.getResponseHeader("Authorization"))
              resolve (response)
            } else {
              reject (response)
            }
          }
        )

        /* On error clear all authentication data then abort */
        xhr.onerror = (
          async event => {
            if (this.loggedIn()) {
              alert (await translate ('SA0001', 'Erreur du serveur.'))
              this.logout()
            }
          }
        )

        /* On upload error signal to user for futur action */
        xhr.upload.onerror = (
          async event => {
            alert (await translate ('SA0002', 'Erreur lors du transfert.'))
          }
        )

        /* Connect to service */
        xhr.open("POST", this.domain)

        /* Set authentication headers if any */
        if (this.loggedIn()) {
          xhr.setRequestHeader('Authorization', this.getToken())
        }

        /* Prepare standard JSON query unless request is FORM DATA */
        if ( ! (request instanceof FormData) ) {
          xhr.setRequestHeader('Accept', 'application/json')
          xhr.setRequestHeader('Content-Type', 'application/json')
        }

        /* Send the request */
        xhr.send(request)
      }
    )

    /* Send back response data */
    if ( res.success ) {
      return res.data
    } else {
      throw res.error
    }

  }


  /*
   * Authentication methods
   * ==========================================================================
   */

  /* Checks if there is a saved token and it's still valid */
  loggedIn() {
    if ( this.sessionLocked ) {
      return -1
    }
    const token = this.getToken()
    return !!token && !this.isTokenExpired(token)
  }

  /* Verify if token expiration time is reached */
  isTokenExpired(token) {
    try {
      const decoded = decode(token)
      if (decoded.exp < Date.now() / 1000) {
        return true
      }
      else
        return false
    }
    catch (err) {
      return false
    }
  }

  /* Saves user token to sessionStorage */
  async setToken(idToken) {
    if ( !idToken && this.loggedIn() ) {
      if ( this.sessionLocked ) {
        return -1
      }
        alert (await translate ('SA0003', 'Session terminée de manière inattendue !'))
        return this.logout()
    }
    if (this.tokenTimer) {
      clearTimeout (this.tokenTimer)
    }
    try {
      const token =  decode(idToken)
      /* Set session timeout in 99% of time left */
      const timeout = ((token.exp * 1000) - Date.now()) * 0.99
      if (timeout > 0) {
        this.tokenTimer = setTimeout(this.onTokenTimeout, timeout)
      }
    } catch (err) {
      return null
    }
    sessionStorage.setItem('id_token', idToken)
  }

  /* Register App functions to call in Token timeout */
  registerTokenTimeoutHandler (handler) {
    this.tokenTimeoutHandlers.push (handler)
    return this.tokenTimeoutHandlers.length
  }

  /* Unregister App functions called in Token timeout */
  unRegisterTokenTimeoutHandler (handlerId) {
    if ( this.tokenTimeoutHandlers.length <= handlerId ) {
      this.tokenTimeoutHandlers.splice (handlerId -1, 1)
    }
  }

  /* Lock session if token is timeouted */
  onTokenTimeout () {
    if (this.tokenTimer) {
      clearTimeout (this.tokenTimer)
    }
    if (this.tokenTimeoutHandlers.length > 0) {
      this.sessionLocked = true
      for (let i=0; i<this.tokenTimeoutHandlers.length; i++) {
        this.tokenTimeoutHandlers[i] ()
      }
    } else {
      this.logout ()
    }
  }

  /* Retrieves the user token from sessionStorage */
  getToken() {
    return sessionStorage.getItem('id_token')
  }

  /* Clear user token and profile data from sessionStorage */
  logout(reset=true) {
    this.sessionLocked = false
    sessionStorage.removeItem('id_token')
    if (reset) {
      this.setRoute ('/')
      document.location.reload(true)
    }
  }

  /* Resubmit credentials to server and obtain a new Token */
  async reLogin (passwd, service='Login') {
    try {
      const profile = this.getProfile()
      let response = null

      if ( this.twoFactorsMethod ) {
        response = await this.apiCall (
          'Login',
          'two_factors_login',
          this.twoFactorsMethod,
          this.twoFactorsData,
          profile.login,
          passwd,
          profile
        )
      } else {
        response = await this.apiCall (service, 'login', profile.login, passwd, profile)
      }

      this.sessionLocked = !response
      return response
    } catch (e) {
        return false
    }
  }

  /* Generic Two Factors Getter */
  twoFactorsGetData () {
    if ( this.twoFactorsMethod && this.twoFactorsFacilities[this.twoFactorsMethod] !== undefined ) {
      return this.twoFactorsFacilities[this.twoFactorsMethod].getData ()
    }
    return false
  }

  /* Generic Two Factors Setter */
  twoFactorsSetData (data) {
    if ( this.twoFactorsMethod && this.twoFactorsFacilities[this.twoFactorsMethod] !== undefined ) {
      return this.twoFactorsFacilities[this.twoFactorsMethod].setData (data)
    }
    return false
  }

  /* Generic Two Factors rejection handler */
  twoFactorsHandleReject (msg) {
    if ( this.twoFactorsMethod && this.twoFactorsFacilities[this.twoFactorsMethod] !== undefined ) {
      return this.twoFactorsFacilities[this.twoFactorsMethod].handleReject (msg)
    }
    return false
  }

  /*
   * Two factors authent methods (Experimental, may be modified ...)
   * ==========================================================================
   */

  /* Retrieve key from local staorage if any */
  keySimpleGetData() {
    return localStorage.getItem('AuthSimpleKey') ;
  }

  /* Store key into local storage */
  keySimpleSetData(data) {
    localStorage.setItem('AuthSimpleKey', data) ;
    return true
  }

  /* Rejection Handler :
   * Just verify that rehect came from a Two Factors event end let login module
   * handle validation
   */
  keySimpleHandleReject(msg) {
    if ( typeof (msg) === 'string' && msg.startsWith ("TWO_FACTORS_DENIED:") ) {
      return true
    }
    return false
  }


  /*
   * Profile methods
   * ==========================================================================
   */

  /* Get public data from JWToken */
  getProfile() {
    try {
      const token =  decode(this.getToken())
      return token.data
    } catch (err) {
      return null
    }
  }

  /* Update profile version number */
  reloadProfile () {
    this.profileVersion++
  }

  /* Register App functions to call on defined event */
  registerAppEventCallback (eventName, handler) {
    if ( this.eventHandlers[eventName] === undefined ) {
       this.eventHandlers[eventName] = []
    }
    this.eventHandlers[eventName].push (handler)
    return this.eventHandlers[eventName].length
  }

  /* Unregister App functions called on defined event */
  unRegisterAppEventCallback (eventName, handlerId) {
    if ( this.eventHandlers[eventName] !== undefined && this.eventHandlers[eventName].length <= handlerId ) {
      this.eventHandlers[eventName].splice (handlerId -1, 1)
    }
  }

  /* Fire callback functions on event */
  triggerAppEvent (eventName) {
    if (this.eventHandlers[eventName] !== undefined ) {
      for (let i=0; i<this.eventHandlers[eventName].length; i++) {
        this.eventHandlers[eventName][i] ()
      }
    }
  }

  /*
   * Application Components methods
   * ==========================================================================
   */

  // Register application menu entries
  async menuRegister (entry) {
    // Check if we have a menu entry ...
    if (entry.menu !== undefined) {
      let level = this.appMenu
      const pathElements = await Promise.all ( Array.isArray(entry.menu) ? entry.menu : entry.menu.split ('/'))

      while (pathElements.length) {
        const pathElement = pathElements.shift().trim()
        if (!pathElement) {
          continue
        }
        // Find this menu entry
        let menuEntry = level.find (e => e.label === pathElement)
        // Create it if it does not exists
        if (! menuEntry) {
          menuEntry = {label: pathElement}
          level.push (menuEntry)
        }

        if (pathElements.length) {
          // This is an intermediste level (having child)
          if (menuEntry.items === undefined) {
            menuEntry.items = []
          }
          level = menuEntry.items

        } else {
          // This is a leaf
          if (entry.icon) {
            menuEntry.icon = entry.icon
          }
          // Route to reach on activation
          if (entry.route) {
            menuEntry.route = entry.route
            menuEntry.command = () => {this.setRoute(entry.route)}
          }
          // Command to execute on activation
          if (entry.command) {
            menuEntry.command = entry.command
          }
          // Access control menu AND route access
          if (entry.access) {
            menuEntry.access = entry.access
          }
          // Show control ONLY menu (route access is granted)
          if (entry.show) {
            menuEntry.access = entry.show
          }
        }
      }
    }
    return true
  }

  // Build menu entries from registered application entries
  async menuGet (level=null) {
    if (level===null) {
      level = this.appMenu
    }
    const menu = []
    for (let i=0; i<level.length; i++) {
      const menuEntry = level[i]
      if ( menuEntry.access === undefined || await menuEntry.access() ) {
        const item = {}
        if (menuEntry.label) {
          item.label = menuEntry.label
        }
        if (menuEntry.icon) {
          item.icon = menuEntry.icon
        }
        if (menuEntry.command) {
          item.command = menuEntry.command
        }
        if (menuEntry.route) {
          item.route = menuEntry.route
        }
        if (menuEntry.items) {
          item.items = await this.menuGet (menuEntry.items)
        }
        if ( item.command || item.route || (item.items && item.items.length) ) {
          menu.push (item)
        }
      }
    }
    return menu
  }

  // Register application routes
  routeRegister (entry) {
    if (entry.route !== undefined && entry.component !== undefined) {
      if (this.appRoutes.find (r => r.route === entry.route)) {
        return
      }
      if (entry.default) {
        this.appDefaultRoute = entry.route
      }
      this.appRoutes.push (
        {
          route: entry.route,
          component: entry.component,
          service: entry.service??null,
          access: entry.access??null,
        }
      )
    }
    return true
  }

  // Build application routes from egistered application entries
  routesGet () {
    return this.appRoutes.filter(r => ! r.access || r.access ()).sort ((a,b) => a.route < b.route)
  }
  defaultRouteGet () {
    return this.appDefaultRoute
  }

  // Register application services
  serviceRegister (entry) {
    const item = {}
    if ( entry.service !== undefined ) {
      item.service = entry.service
      this.appServices.services[entry.service.toLowerCase()] = item
    }
    if ( entry.orm !== undefined ) {
      item.table = entry.orm
      this.appServices.tables[entry.orm.toLowerCase()] = item
    }
    if ( entry.name !== undefined ) {
      item.name = entry.name
    }
    return true
  }

  // Returns service infos by API service name
  serviceGetByName (service) {
    service = service.toLowerCase ()
    if ( this.appServices.services[service] !== undefined ) {
      return this.appServices.services[service]
    }
    return {}
  }

  // Returns service infos by ORM table name
  serviceGetByTableName (table) {
    if ( this.appServices.tables[table] !== undefined ) {
      return this.appServices.tables[table]
    }
    return {}
  }

  /*
   * Router methods
   * ==========================================================================
   */

  /* Keep router history record */
  //FIXME :: Do not change anything to this !
  routerHistoryRegister (routerHistory) {
    if ( this.routerHistory === null && routerHistory !== undefined ) {
      this.routerHistory = routerHistory
    }
  }

  /*
   * Set new route, delete all previous route history
   * Flush application context
   */
  async setRoute (route) {
    if (this.routerHistory !== null) {
      window.scrollTo(0, 0)
      this.routePath = []
      this.context = [{}]
      this.routerHistory.replace (route)
    }
  }

  getRoute () {
    return this.routerHistory.location.pathname
  }

  /*
   * Record current route to history
   * Record current context
   * Create a new context
   * Then set new route
   */
 async pushRoute (route, currentContext={}, nextContext={}) {
    if (this.routerHistory !== null) {
      window.scrollTo(0, 0)
      /*
      if (currentContext !== null && this.context.length) {
        const prevContext = this.context[this.context.length - 1]
        this.context[this.context.length - 1] = { ...prevContext, ...currentContext}
      }
      */
      if (this.context.length) {
        this.context[this.context.length - 1] = currentContext
      }
      this.context.push (nextContext)
      this.routePath.push (this.routerHistory.location.pathname)
      await this.routerHistory.replace ('/Wait')
      await this.routerHistory.replace (route)
    }
  }

  /*
   * Change current route and context
   */
 async changeRoute (route, newContext={}) {
    if (this.routerHistory !== null) {
      window.scrollTo(0, 0)
      if (this.context.length) {
        this.context[this.context.length - 1] = newContext
      }
      await this.routerHistory.replace ('/Wait')
      await this.routerHistory.replace (route)
    }
  }

  /*
   * Update Current route after parameters change
   */
  async updateRoute (newId) {
    if (!newId) {
      document.location.reload(true)
    } else if (this.routerHistory !== null) {
      window.scrollTo(0, 0)
      const newRoute = this.routerHistory.location.pathname.replace (/\/0(\/.*)?$/, '/' + newId + "$1")
      await this.routerHistory.replace ('/Wait')
      await this.routerHistory.replace (newRoute)
    }
    return true
  }

  /*
   * Restore previous route history
   * Destroy the current context and restore the last one
   */
  async upRoute () {
    if (this.routerHistory !== null) {
      window.scrollTo(0, 0)
      if ( this.context.length ) {
        this.context.pop ()
      }
      if ( this.routePath.length ) {
        await this.routerHistory.replace ('/Wait')
        await this.routerHistory.replace (this.routePath.pop ())
      } else {
        this.context = []
        this.routerHistory.replace ('/')
      }
    }
  }

  /* Lock navigation */
  setLockNavigation (lock, message = "") {
    if (lock) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
    this.lockNavigation = lock
    this.lockNavigationMessage = message
  }

  /* Return current contex */
  get () {
    if ( this.context.length ) {
        return this.context[this.context.length - 1]
    }
    return null
  }

  /* Misc : Returns a Date object set with the correct today date at 00h00mm in the current timezone */
  today () {
    return (new Date((Date.now() - (Date.now() % 86400000)) + ((new Date()).getTimezoneOffset () * 60000)))
  }

}
