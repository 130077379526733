import React from 'react'
import {FilterService} from '../../services/FilterService'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {ProgressBar} from 'primereact/progressbar'

export class CompanyTable extends AuthenticatedTable {

  constructor(props) {
    super (
      props, 'company', '/Entreprises', 'Companies',
      {
        inCharge_list: [],
        reparentConfirm: null,
      }
    )

    this.getInCharge_list = FilterService.getInCharge_list.bind(this)
    this.setInChargeFilter = FilterService.setInChargeFilter.bind(this)

    this.handleReparent = this.handleReparent.bind(this)
    this.salesPeoplesGet = this.salesPeoplesGet.bind(this)
  }

  async getValues (first=0, rows=0, sort=null, filter=null, filters=null) {
      // Set access filter
      filters = this.setInChargeFilter (filters)
      // Get results
      const companies = await super.getValues (first, rows, sort, filter, filters)
      // Map inchard_id = 0 to anybody
      return FilterService.mapInChargeAnyBody (companies)
  }

  async getExternalValues () {
    const inCharge_list = await this.getInCharge_list ()
    this.setState ({inCharge_list: inCharge_list.values})
  }

  /*
   * Reparent stuff
   */

  batchReparentButton(s, a) {
    return (
      <Button
        className="p-button-warning"
        label="Changer de commercial"
        icon="fas fa-shipping-fast"
        onClick={this.handleAdd}
      />
    )
  }

  async handleReparent (userId, selection) {
    this.setState ({reparentConfirm: 'running'})
    await this.apiCall ('Users', 'addCompanies', userId, selection.map (c => c.id))
    if ( this.state.reparentConfirm ) {
      this.setState ({reparentConfirm:null})
      this.dataTable.loadValues ()
    }
    return true ;
  }

  salesPeoplesGet (pattern, maxValues) {
    maxValues=20
    const filters = {name : {value : pattern}, unit : {value: 'sales'}}
    return this.apiCall ('Users', 'list', 0, maxValues, false, false, filters)

  }

  renderReparentDialog () {
    const footer = <div>
      <Button
        label="Continuer en arrière plan"
        icon="pi pi-arrow-circle-right"
        className="p-button"
        onClick={ () => this.setState ({reparentConfirm:null}) }
      />
    </div>
    return (
      <Dialog
        style={{width: "40%"}}
        header="Opétarion en cours ..."
        visible={!!this.state.reparentConfirm}
        modal={true}
        footer={footer}
        closable={false}
        onHide={() => this.setState({reparentConfirm:null})}
      >
        <div>
          <ProgressBar mode="indeterminate" />
        </div>
      </Dialog>
    )
  }

  /*
   * ---------------------------------------------------------------------------------------------------------------------
   */


  doRender() {
    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        onOpen={this.handleOpen}
        onDelete={this.handleDelete}
        onCreate={this.handleCreate}

        // ------------------------------------------------------------------
        // custom1 is used to implement batch company's owner change
        // We will cheat with onAdd funtion to get the user chooser
        custom1Template={this.batchReparentButton}
        // This one is a fake just used to have a defined function field
        onCustom1={this.handleReparent}
        onAdd={this.handleReparent}
        // Do NOT enable add button, just use the action into Custom1 ;-)
        addEnabler={() => false}
        addItemFieldName="name"
        addItemGet={this.salesPeoplesGet}
        addItemTitle="Changement de commercial"
        addItemPrompt="Entrez le nom du nouveau commercial"
        // ------------------------------------------------------------------

        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column field="name" header="Nom" sortable={true} style={{width:'30%'}} />
        <Column field="unit" header="Service" sortable={true} style={{width:'20%'}} />
        <Column field="city" header="Localité" sortable={true} style={{width:'20%'}} />
        <Column field="phone" header="Standard" sortable={true} style={{width:'10%'}} />
        <Column
          field="inCharge"
          header=<i className="pi pi-user"/>
          sortable={true}
          style={{width:'3%'}}
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.inCharge_list}
        />
        <Column
          field="customer"
          header="Client"
          filterMethod={LazyDataTable.optionsFilter}
          filterParams={LazyDataTable.booleanDefaultOptions}
          sortable={true}
          style={{textAlign:'center', width:'8%'}}
          body={LazyDataTable.booleanTemplate}
        />
      </LazyDataTable>
    )
  }
}

