import React from 'react'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

export class ImportTable extends AuthenticatedTable {

  constructor(props) {
    super (
      props, 'import', '/Imports', 'Imports',
      {
        type_list: [],
        state_list: [],
      }
    )
  }

  /*
   * Get import states and types
   */
  async getExternalValues () {
    const state_list = await this.apiCall ('ImportStates', 'list')
    state_list.values = state_list.values.map (t => {return {label: t.name, value: t.id, ...t}})
    const type_list = await this.apiCall ('ImportTypes', 'list')
    type_list.values = type_list.values.map (t => {return {label: t.name, value: t.id, ...t}})
    this.setState (
      {
        state_list: state_list.values,
        type_list: type_list.values,
      }
    )
  }


  doRender() {

    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        noAutoFocus={this.props.noAutoFocus}
        onCreate={this.handleCreate}
        createTitle="Nouvel Import"
        onOpen={this.handleOpen}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column key="name" field="name" header="Titre" sortable={true} style={{width:'28%'}}/>
        <Column
          key="date"
          field="date"
          header="Date"
          filterMethod={LazyDataTable.dateFilter}
          sortable={true}
          style={{width:'1%'}}
          body={LazyDataTable.dateTemplate}
        />
        <Column
          key="type"
          field="type"
          header="Type"
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.type_list}
          sortable={true}
          style={{width:'10%'}}
          body={LazyDataTable.optionTemplate}
        />
        <Column
          key="state"
          field="state"
          header="Etat"
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.state_list}
          sortable={true}
          style={{width:'10%'}}
          body={LazyDataTable.optionTemplate}
        />
      </LazyDataTable>
    )
  }
}

