import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {Display} from '../../components/Display'
import {TextAreaToHTML} from '../../components/TextAreaToHTML'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {Dropdown} from 'primereact/dropdown'
import {Fieldset} from 'primereact/fieldset'
import {Attachments} from '../../components/Attachments'
import {TabView,TabPanel} from 'primereact/tabview'
import '../../components/Forms.css'

class PersonalContactForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'personalContact', '/Contacts', 'PersonalContacts',

      {

        personalContact: {
          id: props.match.params.id,
          prefix: "",
          prefix_id: 0,
          firstName: "",
          lastName: "",
          address: "",
          zip: "",
          city: "",
          phone: "",
          mobile: "",
          fax: "",
          eMail: "",
          notes: "",
          user_id: (props.match.params.user_id ? parseInt (props.match.params.user_id, 10) : 0),
          user: "",
        },

        constraints: {
          constraintViolationException: {
            personalContact_name_email: "Email, nom et prénom d'interlocuteur déjà utilisés (Il doivent être uniques).",
          }
        },

        mandatoryFields: {
          firstName: {test: FormContainer.checkNotEmpty, state: false},
          lastName: {test: FormContainer.checkNotEmpty, state: false},
          zip: {test: t =>{return FormContainer.checkZipCode (t, true)}, state: false},
          eMail: {test: t =>{ return FormContainer.checkEmail (t, true)}, state: false},
          //phone: {test: t =>{return FormContainer.checkFrenchPhoneNumber (t, true)}, state: false},
          phone: {test: t => FormContainer.checkIntlPhoneNumber (t, true), state: false},
          //mobile: {test: t =>{return FormContainer.checkFrenchPhoneNumber (t, true)}, state: false},
          mobile: {test: t => FormContainer.checkIntlPhoneNumber (t, true), state: false},
          //fax: {test: t =>{return FormContainer.checkFrenchPhoneNumber (t, true)}, state: false},
          fax: {test: t =>{return FormContainer.checkIntlPhoneNumber (t, true)}, state: false},
        },

        prefix_list: [],

        editMode: false,
      }
    )

  }


  /*
   * Form data management
   */

  async getExternalValues () {

    // Populate prefix dropdown selector options
    const prefix_list = await this.apiCall ('PersonCivilities', 'list')

    await this.setState (
      {
        prefix_list: prefix_list.values,
      }
    )
  }

  doRender() {

    let title = ''
    if (this.state.personalContact.prefix) {
      title += this.state.personalContact.prefix + ' '
    }
    if (this.state.personalContact.firstName) {
      title += this.state.personalContact.firstName + ' '
    }
    if (this.state.personalContact.lastName) {
      title += this.state.personalContact.lastName + ' '
    }

    let address = ''
    if (this.state.personalContact.address) {
      address += this.state.personalContact.address
    }
    if (this.state.personalContact.zip) {
      if (address) {
        address += '  -  '
      }
      address += this.state.personalContact.zip
    }
    if (this.state.personalContact.city) {
      if (address) {
        address += '  '
      }
      address += this.state.personalContact.city
    }

    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>
                  {title||'\u00A0'}
                </h1>
                <FormContainer
                  values={this.state.personalContact}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <TabView>

                    <TabPanel header="Informations" rightIcon="pi pi-info">
                      <div className="form-new-line p-grid">

                        <Display if={!this.state.editMode}>

                          <div className="p-col-12 p-grid">
                            <div className="p-col-12 p-md-10 p-md-offset-1">
                              <b><i className="pi pi-envelope"></i></b>
                              {address || 'Non spécifié.'}
                            </div>
                            <div className="p-col-12 p-md-2 p-md-offset-1">
                              <b><i className="fas fa-phone"></i> </b>
                              {this.state.personalContact.phone || 'Non spécifié.'}
                            </div>
                            <div className="p-col-12 p-md-2 form-text-align-md-center">
                              <b><i className="fas fa-mobile-alt"></i> </b>
                              {this.state.personalContact.mobile || 'Non spécifié.'}
                            </div>
                            <div className="p-col-12 p-md-6 form-text-align-md-right">
                              <b><i className="fas fa-at"></i> </b>
                              {this.state.personalContact.eMail || 'Non spécifié.'}
                            </div>
                          </div>

                          <Display if={this.state.personalContact.notes}>
                            <div className="p-col-12 p-grid">
                              <div className="p-col-12 p-md-10 p-md-offset-1">
                                <Fieldset legend="Notes">
                                  <TextAreaToHTML>{this.state.personalContact.notes}</TextAreaToHTML>
                                </Fieldset>
                              </div>
                            </div>
                          </Display>

                        </Display>

                        <Display if={this.state.editMode}>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="prefix">Préfixe</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <Dropdown
                                className="form-input"
                                id="prefix"
                                options={this.state.prefix_list.map (r => ({label: r.name, value: r.id}))}
                                value={this.state.personalContact.prefix_id}
                                onChange={this.handleExternalSelect}
                                showClear={true}
                                autoWidth={false}
                              />
                            </div >
                          </div >

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="firstName" isMissing={this.state.mandatoryFields.firstName.state}>Prénom</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="firstName"
                                autoFocus={true}
                                value={this.state.personalContact.firstName}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpFirstName}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="lastName" isMissing={this.state.mandatoryFields.lastName.state}>Nom</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="lastName"
                                value={this.state.personalContact.lastName}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpLastName}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="address">Adresse</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="address"
                                value={this.state.personalContact.address}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="zip" isMissing={this.state.mandatoryFields.zip.state} reminder="">Code postal</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="zip"
                                value={this.state.personalContact.zip}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpZipCode}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="city">Ville</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="city"
                                value={this.state.personalContact.city}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpLastName}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="phone" isMissing={this.state.mandatoryFields.phone.state} reminder="">Téléphone</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="phone"
                                value={this.state.personalContact.phone}
                                onChange={this.handleInputText}
                                //onBlur={this.cleanUpSimpleFrenchPhoneNumber}
                                onBlur={this.cleanUpIntlPhoneNumber}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="mobile" isMissing={this.state.mandatoryFields.mobile.state} reminder="">Mobile</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="mobile"
                                value={this.state.personalContact.mobile}
                                onChange={this.handleInputText}
                                //onBlur={this.cleanUpSimpleFrenchPhoneNumber}
                                onBlur={this.cleanUpIntlPhoneNumber}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="fax" isMissing={this.state.mandatoryFields.fax.state} reminder="">Fax</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="fax"
                                value={this.state.personalContact.fax}
                                onChange={this.handleInputText}
                                //onBlur={this.cleanUpSimpleFrenchPhoneNumber}
                                onBlur={this.cleanUpIntlPhoneNumber}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <MandatoryLabel htmlFor="eMail" isMissing={this.state.mandatoryFields.eMail.state}>Courriel</MandatoryLabel>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputText
                                className="form-input"
                                id="eMail"
                                value={this.state.personalContact.eMail}
                                onChange={this.handleInputText}
                                onBlur={this.cleanUpEmail}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-grid p-justify-even">
                            <div className="p-col-12 p-md-2">
                              <label htmlFor="notes">Notes</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <InputTextarea
                                rows={5}
                                cols={80}
                                autoResize={true}
                                className="form-input"
                                id="notes"
                                value={this.state.personalContact.notes}
                                onChange={this.handleInputText}
                              />
                            </div>
                          </div>


                        </Display>

                      </div>

                    </TabPanel>

                    <TabPanel header="Pièces jointes" rightIcon="pi pi-paperclip">
                      <div className="form-new-line p-grid">
                        <div className="p-col-12 form-new-line">
                          <label htmlFor="attachments">Pièces jointes associées :</label>
                        </div>
                        <div className="p-col-12">
                          <Attachments
                            id="attachments"
                            locked={this.state.editMode}
                            getValues={this.getAttachmentsValues}
                            getVersions={this.getAttachmentVersions}
                            setVersions={this.setAttachmentVersions}
                            delete={this.deleteAttachment}
                            doUpload={this.fileUpload}
                            doDownload={this.fileDownload}
                            watch={this.state.profile}
                          >
                          </Attachments>
                        </div>
                      </div>
                    </TabPanel>

                  </TabView>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PersonalContactForm
