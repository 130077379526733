import React, { Component } from 'react'
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {AuthContext} from '../../helpers/AuthContext'
import {BanMSService} from '../../services/BanMSService'

class Login extends Component {

  constructor(props){
    super(props)
    this.state = {
      username : '',
      password : '',

      twoFactorsEnabler : false,

      msBrowser : false,

      alert : false,
      title : '',
      message : '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  static contextType = AuthContext

  async handleFormSubmit(event){
    event.preventDefault()
    try {
      if ( this.context.twoFactorsMethod ) {
        await this.context.apiCall (
          'Login',
          'two_factors_login',
          this.context.twoFactorsMethod,
          this.context.twoFactorsData,
          this.state.username,
          this.state.password
        )
      } else {
        await this.context.apiCall ('Login', 'login', this.state.username, this.state.password)
      }
      this.props.history.replace('/')
    } catch(err) {
      // Clear login Token to prevent reload illegal connexion but stay on this page (do not reset)
      this.context.logout (false)
      if ( this.context.twoFactorsHandleReject (err) ) {
        this.setState ({twoFactorsEnabler: true})
      } else {
        this.setState (
          {
            alert : true,
            title : "Echec de l'authentification.",
            message : err,
          }
        )
      }
    }
  }

  handleChange(event){
    this.setState({[event.target.name]: event.target.value})
  }

  async twoFactorsEnable(){
    try {
      const key = await this.context.apiCall (
        'Login',
        'two_factors_enable',
        this.context.twoFactorsMethod,
        this.state.username,
        this.state.password,
        this.state.username
      )
      if ( key && await this.context.twoFactorsSetData (key) ) {
        this.setState (
          {
            alert : true,
            title : "Activation Réussie.",
            message : "Votre demande d'activation a été validée, vous pouvez vous connecter.",
          }
        )
      } else {
        this.context.logout ()
        throw  Error ("Unknown key error !")
      }
    } catch (err) {
      this.setState (
        {
          alert : true,
          title : "Activation impossible.",
          message : err,
        }
      )
    }
  }

  componentDidMount(){
    if ( BanMSService.thisIsAnMsBrowser () ) {
      this.setState ({msBrowser: true})
    }
    if(this.context.loggedIn()) {
      this.props.history.replace('/')
    }
  }

  render_alert() {
    const dialogFooter = (
      <div>
          <Button onClick={() => this.setState({alert:false}, this.context.logout)} label="Ok" className="p-button" />
      </div>
    )
    return (
      <Dialog
        header={this.state.title}
        visible={this.state.alert}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={() => document.location.reload(true)}
      >
        <p>{this.state.message}</p>
      </Dialog>
    )
  }

  render_two_factors_enabler() {
    const dialogFooter = (
      <div>
          <Button onClick={() => this.setState({twoFactorsEnabler:false}, this.twoFactorsEnable)} label="Tenter une activation" className="p-button-danger" />
          <Button onClick={() => this.setState({twoFactorsEnabler:false}, this.context.logout)} label="Annuler" className="p-button" />
      </div>
    )
    return (
      <Dialog
        header="Activation nécessaire."
        visible={this.state.twoFactorsEnabler}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={() => {this.setState({twoFactorsEnabler:false})}}
      >
        <p>Ce navigateur n'est pas autorisé à accèder à l'application.</p>
        <p>Vous pouvez tenter de l'activer si vous disposez d'une autorisation...<br/>
        Cette oprération est irréversible et vous ne pourrez pas activer un autre navigateur avec la même autorisation.<br/>
        Si vous n'êtes pas sur, annulez l'opération !</p>
      </Dialog>
    )
  }


  render_no_ms_browser() {
    return (
      <Dialog
        header="Visioneuse incompatible."
        visible={this.state.msBrowser}
        modal={true}
        closable={false}
        onHide={console.log}
      >
        <p>Le programme que vous utilisez n'est pas capable d'exécuter cette applicatoin.</p>
        <p>Vous devriez plutôt essayer avec un navigateur Web comme Chrome, Firefox ou Safari.</p>
      </Dialog>
    )
  }

  render() {
    try {
      return (
        <div className="login-center">
          <div className="login-card">
            <h1>GesCo 2.0</h1>
            <form onSubmit={this.handleFormSubmit}>
              <input
                className="login-form-item"
                placeholder="Adresse mail ..."
                name="username"
                type="text"
                onChange={this.handleChange}
              />
              <input
                className="login-form-item"
                placeholder="Mot de passe ..."
                name="password"
                type="password"
                onChange={this.handleChange}
              />
              <input
                className="login-form-submit"
                value="CONNEXION"
                type="submit"
              />
            </form>
          </div>
          {this.render_no_ms_browser()}
          {this.render_alert()}
          {this.render_two_factors_enabler()}
        </div>
      )
    } catch (err) {
      this.context.logout ()
    }
  }
}

export default Login
