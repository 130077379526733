import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MailingWizard} from '../../components/MailingWizard'
import {MailingService} from '../../services/MailingService'
import {Display} from '../../components/Display'
import '../../components/Forms.css'

class MailingTemplateForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'mailingTemplate', '/Mailing/Modèles', 'MailingTemplates',

      {
        mailingTemplate: {
          id: props.match.params.id,
          subject: '',
          html: '',
          text: '',
          wait: parseInt (props.match.params.id, 10),
        },

        constraints: {
          constraintViolationException: {
            mailingTemplate_subject: "Sujet déjà utilisé (Il doit être unique).",
          }
        },

        mandatoryFields: {
          subject: {test: FormContainer.checkNotEmpty, state: false},
          text: {test: FormContainer.checkNotEmpty, state: false},
        },

      }
    )

    this.field_list = MailingService.field_list
    this.template_list = []

    this.handleTemplate = this.handleTemplate.bind (this)
  }

  async getExternalValues () {
    this.template_list = await this.mailingTemplateList ()
  }

  handleTemplate (subject, html, text) {
    this.setState (
      prevState => {
        prevState.mailingTemplate.subject = subject
        prevState.mailingTemplate.html = html
        prevState.mailingTemplate.text = text
        return { mailingTemplate : prevState.mailingTemplate }
      }
    )
  }

  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Modèle de mailing</h1>
                <FormContainer
                  values={this.state.mailingTemplate}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <Display if={!this.state.mailingTemplate.wait}>
                    <div className="form-new-line p-grid">
                      <MailingWizard
                        fields={this.field_list}
                        template={true}
                        templates={this.template_list}
                        getTemplate={this.mailingTemplateGet}
                        disabled={!this.state.editMode}
                        value={this.state.mailingTemplate}
                        mandatoryFields={this.state.mandatoryFields}
                        onChange={this.handleTemplate}
                      />
                    </div>
                  </Display>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MailingTemplateForm
