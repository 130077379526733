export class BanMSService {

  static thisIsAnMsBrowser () {

    if (/MSIE 10/i.test(navigator.userAgent)) {
      return 'isIE10'
    }

    if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
      return ('isIE9orIE11')
    }

    if (/Edge\/\d./i.test(navigator.userAgent)){
      return ('isEDGE')
    }

    return false
  }

}

