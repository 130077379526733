import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {Display} from '../../components/Display'
import {Picture} from '../../components/Picture'
import {TextAreaToHTML} from '../../components/TextAreaToHTML'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {ContactTable} from '../contacts/ContactTable'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {Dropdown} from 'primereact/dropdown'
import {Checkbox} from 'primereact/checkbox'
import {Dialog} from 'primereact/dialog'
import {Fieldset} from 'primereact/fieldset'
import '../../components/Forms.css'

class CompanyForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'company', '/Entreprises', 'Companies',

      {

        company: {
          id: props.match.params.id,
          name: "",
          unit: "",
          address: "",
          zip: "",
          city: "",
          eMail: "",
          phone: "",
          webSite: "",
          fax: "",
          notes: "",
          customer: false,
          inCharge: "",
          inChargeName: "",
          inCharge_id: 0,
          logo: null,

        },

        constraints: {
          constraintViolationException: {
            company_name: "Nom d'entreprise et de service déjà utilisés (Il doivent être unique).",
            contact_customer: "Vous ne pouvez pas déclarer l'entreprise prospect tant qu'un de ses interlocuteurs est toujours client."
          }
        },

        mandatoryFields: {
          name: {test: FormContainer.checkNotEmpty, state: false},
          zip: {test: t =>{return FormContainer.checkZipCode (t, true)}, state: false},
        },

        inCharge_list: [],
        inChargeWarning: false,
        //currentUserCanManageThisCompany: false,
      }
    )

    this.handleInChargeSelect = this.handleInChargeSelect.bind(this)
  }

  /*
   * Form data management
   */

  cleanUpValues (company) {
    if ( !company.inCharge_id ) {
      company.inCharge_id = 0
    }
  }

  async getExternalValues () {
    // Populate inCharge dropdown selector options
    const filters = this.defaultFilters ({unit : {value: 'sales'}})
    const inCharge_list = await this.apiCall ( 'Users', 'list', 0, Number.MAX_SAFE_INTEGER, false, false, filters)
    inCharge_list.values = inCharge_list.values.map (u => {return {name: u.code + ' (' + u.firstName + ' ' + u.lastName + ')', id: u.id}})
    inCharge_list.values.push ({id: 0, name: 'Tous (Tous les collaborateurs)'})

    /*
    // Determine id the current user can manage the company
    const currentUserCanManageThisCompany =
      !this.state.company.inCharge_id                               // Nobody is yet in charhe of this company
      || this.state.profile.globalManager                           // Current user is global manager
      || this.state.profile.id === this.state.company.inCharge_id   // Current user is in charge of this company
    */

    await this.setState (
      {
        inCharge_list: inCharge_list.values,
        //currentUserCanManageThisCompany: currentUserCanManageThisCompany
      }
    )
  }

  handleInChargeSelect (e) {
    if ( e.value && !this.state.profile.globalManager && e.value !== this.state.profile.id ) {
      this.setState ({inChargeWarning: true})
    }
    this.handleExternalSelect (e)
  }



  doRender() {
    let address = ''
    if (this.state.company.address) {
      address += this.state.company.address
    }
    if (this.state.company.zip) {
      if (address) {
        address += '  -  '
      }
      address += this.state.company.zip
    }
    if (this.state.company.city) {
      if (address) {
        address += '  '
      }
      address += this.state.company.city
    }
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>
                  <div className="p-grid p-justify-between">
                    <div className="p-col-12 p-md-5">
                      {this.state.company.name} {this.state.company.unit && <span style={{color:'grey'}}>({this.state.company.unit})</span>}
                    </div>
                    <div className="p-col-12 p-md-2">
                      <Display if={this.state.company.logo}>
                        <Picture
                          style={{float: 'left'}}
                          className="form-input"
                          id="logo"
                          maxWidth="100"
                          maxHeight="30"
                          //height="30px"
                          locked={true}
                          update={!this.state.editMode}
                          onLoad={this.getPicture}
                          value={this.state.company.logo}
                        />
                      </Display>
                    </div>
                    <div className="p-col-12 p-md-5 form-text-align-md-right">
                      Gérée par {this.state.company.inCharge ? this.state.company.inChargeName : 'Tous'}
                    </div>
                  </div>
                </h1>
                <FormContainer
                  values={this.state.company}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <div className="form-new-line p-grid">

                    {/*
                        -----------------------------------------------------------------------

                                                 DISPLAY ONLY MODE

                        ------------------------------------------------------------------------
                    */}

                    <Display if={!this.state.editMode}>
                      <div className="p-col-12 p-grid">
                        <div className="p-col-12 p-md-9 p-md-offset-1">
                          <b><i className="pi pi-envelope"></i></b>
                          {address || 'Non spécifié.'}
                        </div>
                        <div className="p-col-12 p-md-1 form-text-align-md-right">
                          {this.state.company.customer?"Client":"Prospect"}
                        </div>
                        <div className="p-col-12 p-md-2 p-md-offset-1">
                          <b><i className="fas fa-phone"></i> </b>
                          {this.state.company.phone || 'Non spécifié.'}
                        </div>
                        <div className="p-col-12 p-md-4 form-text-align-md-center">
                          <b><i className="fas fa-globe-europe"></i> </b>
                          {this.state.company.webSite || 'Non spécifié.'}
                        </div>
                        <div className="p-col-12 p-md-4 form-text-align-md-right">
                          <b><i className="fas fa-at"></i> </b>
                          {this.state.company.eMail || 'Non spécifié.'}
                        </div>
                      </div>

                      <Display if={this.state.company.notes}>
                        <div className="p-col-12 p-grid">
                          <div className="p-col-12">
                            <Fieldset legend="Notes">
                              <TextAreaToHTML>{this.state.company.notes}</TextAreaToHTML>
                            </Fieldset>
                          </div>
                        </div>
                      </Display>

                      <Display if={this.state.company.id && this.state.company.id !== '0'}>
                        <div className="form-new-line p-col-12 p-grid">
                          <div className="p-col-12">
                            <label>Interlocuteurs</label>
                          </div>
                          <div className="p-col-12 p-md-12">
                            <ContactTable
                              companyId={this.state.company.id}
                              locked={this.state.editMode}
                              getContext={this.getContext}
                              onAdd={this.handleAdd}
                            />
                          </div>
                        </div>
                      </Display>
                    </Display>


                    {/*
                        -----------------------------------------------------------------------

                                                    EDITION MODE

                        ------------------------------------------------------------------------
                    */}


                    <Display if={this.state.editMode}>
                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <MandatoryLabel htmlFor="name" isMissing={this.state.mandatoryFields.name.state}>Nom</MandatoryLabel>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <InputText
                            className="form-input"
                            id="name"
                            autoFocus={true}
                            value={this.state.company.name}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpLastName}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="logo">Logo</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <Picture
                            className="form-input"
                            id="logo"
                            maxWidth="100"
                            maxHeight="30"
                            alt={"Logo de la société " + this.state.company.name}
                            locked={!this.state.editMode}
                            onChange={this.handlePicture}
                            onLoad={this.getPicture}
                            value={this.state.company.logo}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="unit">Service</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <InputText
                            className="form-input"
                            id="unit"
                            value={this.state.company.unit}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpLastName}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="address">Adresse</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <InputText
                            className="form-input"
                            id="address"
                            value={this.state.company.address}
                            onChange={this.handleInputText}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <MandatoryLabel htmlFor="zip" isMissing={this.state.mandatoryFields.zip.state} reminder="">Code postal</MandatoryLabel>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <InputText
                            className="form-input"
                            id="zip"
                            value={this.state.company.zip}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpZipCode}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="city">Ville</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <InputText
                            className="form-input"
                            id="city"
                            value={this.state.company.city}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpLastName}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="webSite">Site web</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <InputText
                            className="form-input"
                            id="webSite"
                            value={this.state.company.webSite}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpUrl}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="eMail">Courriel</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <InputText
                            className="form-input"
                            id="eMail"
                            value={this.state.company.eMail}
                            onChange={this.handleInputText}
                            onBlur={this.cleanUpEmail}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="phone">Téléphone (standard)</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <InputText
                            className="form-input"
                            id="phone"
                            value={this.state.company.phone}
                            onChange={this.handleInputText}
                            //onBlur={this.cleanUpSimpleFrenchPhoneNumber}
                            onBlur={this.cleanUpIntlPhoneNumber}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="fax">Fax (standard)</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <InputText
                            className="form-input"
                            id="fax"
                            value={this.state.company.fax}
                            onChange={this.handleInputText}
                            //onBlur={this.cleanUpSimpleFrenchPhoneNumber}
                            onBlur={this.cleanUpIntlPhoneNumber}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="inCharge">Commercial</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <Dropdown
                            className="form-input"
                            //disabled={!this.state.currentUserCanManageThisCompany}
                            id="inCharge"
                            options={this.state.inCharge_list.map (c => ({label: c.name, value: c.id}))}
                            value={this.state.company.inCharge_id}
                            onChange={this.handleInChargeSelect}
                            autoWidth={false}
                          />
                        </div >
                      </div>
                      <Dialog
                        header="Attention !"
                        visible={this.state.inChargeWarning}
                        style={{width: '50vw'}}
                        modal={true}
                        onHide={() => this.setState({inChargeWarning: false})}
                      >
                        En affectant cette entreprise à un autre commercial que vous, <b>vous ne serez peut être plus en mesure de la gérer</b> après avoir validé ce formulaire.
                        <br/>
                        Vous pourrez toujours y accéder si vous êtes le manager du destinataire.
                        <br/>
                        Vérifiez bien votre choix avant d'enregistrer !
                      </Dialog>


                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="notes">Notes</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <InputTextarea
                            rows={5}
                            cols={80}
                            autoResize={true}
                            className="form-input"
                            disabled={!this.state.editMode}
                            id="notes"
                            value={this.state.company.notes}
                            onChange={this.handleInputText}
                          />
                        </div>
                      </div>

                      <div className="p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="customer">Client</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <Checkbox
                          className="form-input"
                          inputId="customer"
                          value="customer"
                          onChange={this.handleCheckBox}
                          checked={this.state.company.customer}
                          />
                        </div>
                      </div>

                    </Display>
                  </div>

                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CompanyForm
