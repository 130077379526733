import React from 'react'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {Organigram} from '../../components/Organigram'

class TeamOrganigram extends AuthenticatedTable {

  constructor(props) {
    super (
      props, 'team', '/Equipes/Liste', 'Teams',
      {
        userId: 0,
      }
    )

    this.getUserList = this.getUserList.bind(this)
  }


  async getExternalValues () {
    // It is important to setup this parameter here to ensure to have a profile Id value
    this.setState ({userId: this.state.profile.id})
  }

  getUserList (...args) {
    return this.apiCall ('Users', 'list', ...args)
  }

  doRender() {
    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Organigramme</h1>

              <div className="p-grid">
                <div className="p-col-12">
                  <Organigram
                    userId={this.state.userId}
                    onOpen={this.handleOpen}
                    onDelete={this.handleDelete}
                    onCreate={this.handleCreate}
                    getTeams={this.getValues}
                    getMembers={this.getUserList}
                    getLogo={this.getPicture}
                  />
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default TeamOrganigram
