import React from 'react'
import {FilterService} from '../../services/FilterService'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {MailingWizard} from '../../components/MailingWizard'
import {MailingService} from '../../services/MailingService'
import {Column} from 'primereact/column'

export class ApplicantTable extends AuthenticatedTable {

  constructor(props) {
    super (props, 'applicant', '/Candidats', 'Applicants',
      {
        role_list: [],
        state_list: [],
        nextStep_list: [],
        lastUser_list: [],
        legalStatute_list: [],
      }
    )

    this.setReferenceTeamFilter = FilterService.setReferenceTeamFilter.bind(this)

    this.field_list = MailingService.field_list
    this.template_list = []

  }

  setCustomFilters (filters) {
    if ( !filters || !filters.state ) {
      filters = this.defaultFilters (filters, this.props.filters)
    }
    return this.setReferenceTeamFilter (filters)
  }

  async getExternalValues () {
    this.template_list = await this.mailingTemplateList ()
    const role_list = await this.getOptionList (null, 'Roles')
    role_list.values.unshift ({name: 'Tous', id: null})
    const legalStatute_list = await this.getOptionList (null, 'LegalStatutes')
    //legalStatute_list.values.unshift ({name: 'Tous', id: null})
    const state_list = await this.getOptionList (null, 'ApplicantStates')
    //state_list.values.unshift ({name: 'Tous', id: null})
    const nextStep_list = await this.getOptionList (null, 'ApplicantNextSteps')
    //nextStep_list.values.unshift ({name: 'Tous', id: null})
    const lastUser_list = await this.getOptionList (null, 'Users')
    this.setState (
      {
        role_list: role_list.values.map (t => {return {label: t.name, value: t.id, ...t}}),
        state_list: state_list.values.map (t => {return {label: t.name, value: t.id, ...t}}),
        nextStep_list: nextStep_list.values.map (t => {return {label: t.name, value: t.id, ...t}}),
        lastUser_list: lastUser_list.values.map (t => {return {label: t.name, value: t.id, ...t}}),
        legalStatute_list: legalStatute_list.values.map (t => {return {label: t.name, value: t.id, ...t}}),
      }
    )
  }


  doRender() {
    const mailingFrom =
      (this.state.profile.mailingName ? this.state.profile.mailingName : this.state.profile.username)
      + ' <' + (this.state.profile.mailingFrom ? this.state.profile.mailingFrom : this.state.profile.email) + '>'
    return (
      <div>
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        onOpen={this.handleOpen}
        onDelete={this.handleDelete}
        onCreate={this.handleCreate}
        onMailing={this.openMailing}
        mailingEnabler={this.enableMailing}
        mailingToPageOnly={true}
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column field="name" header="Nom" sortable={true} style={{width:'10%'}}/>
        <Column
          field="qualification"
          header="Compétences"
          style={{width:'22%'}}
          body={LazyDataTable.maxLinesTemplate(3)}
        />
        <Column
          field="soughtRole"
          header="Fonction"
          sortable={true}
          filterMethod={LazyDataTable.optionsFilter}
          filterParams={this.state.role_list}
          style={{width:'7%'}}
        />
        <Column
          field="lastEventDate"
          header=<i className="far fa-calendar-check"></i>
          filterMethod={LazyDataTable.dateFilter}
          sortable={true}
          style={{width:'5%'}}
          body={LazyDataTable.dateTemplate}
        />
        <Column
          field="lastUser"
          header=<i className="pi pi-user"/>
          sortable={true}
          style={{width:'5%'}}
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.lastUser_list}
        />
        <Column
          field="legalStatute"
          header=<i className="fas fa-file-contract"></i>
          sortable={true}
          style={{width:'8%'}}
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.legalStatute_list}
          body={LazyDataTable.optionTemplate}
        />
        <Column
          field="salaryExpectations"
          header=<i className="fas fa-euro-sign"></i>
          sortable={true}
          body={LazyDataTable.euroTemplate}
          style={{textAlign:'right', width:'8%'}}
        />
        <Column field="availability" header="Dispo." sortable={true} style={{width:'7%'}} />
        <Column
          field="match"
          header=<i className="fas fa-thumbs-up"></i>
          sortable={true}
          style={{width:'5%', textAlign:'center'}}
          body={LazyDataTable.percentTemplate}
          filterMethod={LazyDataTable.noFilter}
        />
        <Column
          field="state"
          header=<i className="fas fa-bullseye"></i>
          sortable={true}
          style={{width:'8%'}}
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.state_list}
          body={LazyDataTable.optionTemplate}
        />
        <Column
          field="nextStep"
          header=<i className="fas fa-hand-point-right"></i>
          sortable={true}
          style={{width:'8%'}}
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.nextStep_list}
          body={LazyDataTable.optionTemplate}
        />
        <Column
          field="phone"
          header="Téléphone"
          sortable={false}
          style={{width:'3%'}}
        />
        <Column
          field="eMail"
          header="@"
          headerStyle={{fontSize: '1.3em'}}
          sortable={false}
          style={{width:'2%'}}
          body={LazyDataTable.eMailButtonTemplate (this.openMailing)}
        />
        <Column
          field="socialLink"
          header=<i className="fab fa-linkedin-in"/>
          headerStyle={{fontSize: '1.3em'}}
          sortable={false}
          style={{width:'2%'}}
          body={LazyDataTable.socialLinkTemplate ("fab fa-linkedin-in")}
        />
      </LazyDataTable>
      <MailingWizard
        sender={mailingFrom}
        recipient={this.state.mailing.mailTo}
        fields={this.field_list}
        doMailing={this.state.mailing.open}
        templates={this.template_list}
        getTemplate={this.mailingTemplateGet}
        mailingDone={this.sendMailing}
        maxAttachmentsSize={5000000}
      />
      </div>
    )
  }
}

