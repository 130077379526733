import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import PersonCivilityList from './PersonCivilityList'
import PersonCivilityForm from './PersonCivilityForm'

export class PersonCivilities extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Personnes/Civilitées' component={PersonCivilityList} />
        <Route path='/Personnes/Civilitées/:id' component={PersonCivilityForm} />
      </Switch>
    )
  }
}
