import React from 'react'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import {EventTable} from '../events/EventTable'
import {GlobalSearch} from './GlobalSearch'

class HrMainBoard extends AuthenticatedComponent {

  doRender() {

   /*
    * By default, let's show only currents events belonging to the current user
    * Theses settings will be aoverrided by on screen selectors
    */
    const filters = {
      state: {
        value: ['running','late','alert'],
        matchMode: 'in'
      },
      className: {value: 'hr'},
      referenceTeam: {value : this.state.profile.cVLibrary}
    }

    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Tableau de bord</h1>

              <div className="form-new-line p-col-12 p-grid">
                <GlobalSearch/>
              </div>

              <div className="form-new-line p-grid">
                <div className="p-col-12">
                  <label>Rappels et événements en cours</label>
                </div>
                <div className="p-col-12">
                  <EventTable filters={filters} noAutoFocus={true}/>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default HrMainBoard
