import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import MobilityList from './MobilityList'
import MobilityForm from './MobilityForm'

export class Mobilities extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Personnes/Mobilités' component={MobilityList} />
        <Route path='/Personnes/Mobilités/:id' component={MobilityForm} />
      </Switch>
    )
  }
}
