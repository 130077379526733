import React from 'react'

export class UnitService {

  /*
   * User's Unit definition
   */

  static unit_list = [
    {label: "Commercial", value: "sales"},
    {label: "Ressources humaines", value: "hr"},
  ]

  static class_list = [
    {icon: "fas fa-user-tie", value: "sales"},
    {icon: "fas fa-user-graduate", value: "hr"},
  ]

  /* Table unit template */
  static stateTemplate(rawData, column) {
    const unit = UnitService.unit_list.find (u => u.value === rawData[column.field])
    if ( unit !== undefined ) {
      return unit.label
    }
    return ""
  }

  /* Table class template */
  static classTemplate(rawData, column) {
    const className = UnitService.class_list.find (u => u.value === rawData[column.field])
    if ( className !== undefined ) {
      return <i className={className.icon}></i>
    }
    return ""
  }

}

