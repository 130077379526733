import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import ExperienceList from './ExperienceList'
import ExperienceForm from './ExperienceForm'

export class Experiences extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Personnes/Expériences' component={ExperienceList} />
        <Route path='/Personnes/Expériences/:id' component={ExperienceForm} />
      </Switch>
    )
  }
}
