/* React components and libraries */
import React from 'react'
import classNames from 'classnames'

/* Componnents */
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel'
import {AuthenticatedComponent} from './components/AuthenticatedComponent'
import {ApplicationRoutes} from './components/ApplicationRoutes'
import {Display} from './components/Display'
import {Picture} from './components/Picture'

/* Prime React Css */
import 'primereact/resources/themes/nova-light/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

/* Application layout */
import './layout/layout.scss'

/* Font Awesome */
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'font-awesome-animation/dist/font-awesome-animation.min.css'

/* App mains modules */
import {MenuItems} from './modules/menu/MenuItems'
import {Menu} from './modules/menu/Menu'
import {InlineProfile} from './modules/menu/InlineProfile'
import {Footer} from './modules/footer/Footer'


class App extends AuthenticatedComponent {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      staticMenuInactive: false,
      mobileMenuActive: false,
      sessionLocked : false,
      sessionPasswd : "",
      warning : false,
      warningMessage : "",
      menu : [],
    }

    this.touchStart = -1

    this.registerAppComponents = MenuItems.registerAppComponents.bind (this)

    this.onWrapperClick = this.onWrapperClick.bind(this)
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onToggleMenu = this.onToggleMenu.bind(this)
    this.onSidebarClick = this.onSidebarClick.bind(this)
    this.onMenuItemClick = this.onMenuItemClick.bind(this)

    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
    this.onTouchMove = this.onTouchMove.bind(this)

    this.onProfileChange = this.onProfileChange.bind(this)

    this.onWarning = this.onWarning.bind(this)

    this.handleSessionPasswd = this.handleSessionPasswd.bind(this)
    this.handleSessionKey = this.handleSessionKey.bind(this)
    this.sessionLock = this.sessionLock.bind(this)
    this.sessionUnlock = this.sessionUnlock.bind(this)
    this.sessionCancel = this.sessionCancel.bind(this)
    this.sessionLockHandlerId = null

  }

  onTouchStart(event) {
    if ( event.touches && event.touches.length ) {
      this.touchStart = event.touches[0].clientX
    }
  }

  onTouchMove(event) {
    if ( event.touches && event.touches.length ) {
      const start = this.touchStart
      const stop = event.touches[0].clientX
      const slide = stop - start
      const threshold = window.innerWidth * 0.2
      if (! this.state.mobileMenuActive && start > -1 && start < threshold && slide > threshold) {
        this.touchStart = -1
        this.setState({ mobileMenuActive: true })
      } else if (this.state.mobileMenuActive && start > threshold && (-slide) > threshold ) {
        this.touchStart = -1
        this.setState({ mobileMenuActive: false })
      }
    }
  }

  onTouchEnd(event) {
    this.onTouchMove (event)
  }


  onWrapperClick(event) {
    if (!this.menuClick) {
      this.setState(
        {
          mobileMenuActive: false
        }
      )
    }
    this.menuClick = false
  }

  onMouseOver(event) {
    if (event.clientX < 5) {
      if (this.isDesktop()) {
        this.setState({ staticMenuInactive: false })
      } else {
        this.setState({ mobileMenuActive: true })
      }
    }
  }

  onToggleMenu(event) {

    this.menuClick = true

    /* Unset focus on button to avoid ugly decorations */
    const button = document.getElementById ('toggleMenu')
    if ( button ) {
        button.blur ()
    }

    if (this.isDesktop()) {
      this.setState({ staticMenuInactive: !this.state.staticMenuInactive })
    } else {
      const mobileMenuActive = this.state.mobileMenuActive
      this.setState({ mobileMenuActive: !mobileMenuActive })
    }

    event.preventDefault()
  }

  onSidebarClick(event) {
    this.menuClick = true
    this.timeout = setTimeout(() => {this.layoutMenuScroller.moveBar()}, 500)
  }

  onMenuItemClick(event) {
    if(!event.item.items) {
      this.setState(
        {
          mobileMenuActive: false
        }
      )
    }
  }

  onProfileChange() {
    this.context.reloadProfile ()
    this.setState({profileVersion: this.context.profileVersion}, this.reload)
  }

  onWarning (message = "Opération impossible." ) {
    this.setState (
      {
        warning : true,
        warningMessage : message
      }
    )
  }

  addClass(element, className) {
    if (element.classList) {
      element.classList.add(className)
    } else {
      element.className += ' ' + className
    }
  }

  removeClass(element, className) {
    if (element.classList) {
      element.classList.remove(className)
    } else {
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
    }
  }

  isDesktop() {
    return window.innerWidth > 1024
  }

  async componentDidMount() {
    console.log ("++++++++++++++++++++++App did mount !")
    console.log ("User Agent :", window.navigator.userAgent)
    // If parent retun false we are not logged !
    if ( await super.componentDidMount () ) {
      console.log ('User profile :', this.state.profile)
      this.sessionLockHandlerId = await this.context.registerTokenTimeoutHandler (this.sessionLock)
      await this.registerAppComponents ()
      const menu = await this.getAppMenu ()
      this.setState ({menu : menu})
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // If parent retun false we are not logged anymore !
    if ( await super.componentDidUpdate(prevProps, prevState) ) {
      if (this.state.mobileMenuActive) {
        this.addClass(document.body, 'body-overflow-hidden')
      } else {
        this.removeClass(document.body, 'body-overflow-hidden')
      }
      const menu = await this.getAppMenu ()
      if ( JSON.stringify(menu) !== JSON.stringify(this.state.menu) ) {
        this.setState ({menu : menu})
      }
    }
  }

  componentWillUnmount () {
    if (this.timeout) {
      clearTimeout (this.timeout)
    }
    if (this.sessionLockHandlerId) {
      this.context.unRegisterTokenTimeoutHandler (this.sessionLockHandlerId)
    }
    super.componentWillUnmount ()
  }

  sessionLock () {
    this.setState ({sessionLocked:true})
  }

  sessionCancel () {
    this.context.logout ()
  }

  async sessionUnlock () {
    if ( this.state.sessionLocked && await this.context.reLogin (this.state.sessionPasswd) ) {
      await this.setState({sessionLocked:false})
      this.context.triggerAppEvent ('updateAlerts')
    }
    this.setState({sessionPasswd:''})
  }

  handleSessionPasswd (event) {
    this.setState({sessionPasswd:event.target.value})
  }

  handleSessionKey (event) {
    if (event.charCode === 13) {
      this.sessionUnlock ()
    }
  }

  renderSessionLock () {
    const dialogFooter = (
      <div>
          <Button onClick={this.sessionUnlock} label="Déverrouiller" icon="pi pi-unlock" className="p-button" />
          <Button onClick={this.sessionCancel} label="Déconnecter" icon="pi pi-times" className="p-button-danger" />
      </div>
    )
    let username = " "
    const profile = this.context.getProfile ()
    if ( profile && profile.username !== undefined ) {
      username = " " + profile.username + " "
    }
    return (
      <Dialog
        maskClassName='login-mask'
        header={"Session" + username + "vérouillée"}
        visible={this.state.sessionLocked}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={this.sessionUnlock}
      >
        <p>Veuillez saisir votre mot de passe :</p>
        <input
          style={{width:'100%'}}
          onKeyPress={this.handleSessionKey}
          placeholder="Mot de passe ..."
          name="password"
          type="password"
          value={this.state.sessionPasswd}
          onChange={this.handleSessionPasswd}
        />
      </Dialog>
    )
  }

  renderWarning () {
    const dialogFooter = (
      <div>
          <Button onClick={() => this.setState({warning:false})} label="Ok" className="p-button" />
      </div>
    )
    return (
      <Dialog
        header="Attention !"
        visible={this.state.warning}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={() => this.setState({warning:false})}
      >
        <p>{this.state.warningMessage}</p>
      </Dialog>
    )
  }

  doRender() {

    let defaultLogo='assets/layout/images/logo.png'

    let wrapperClass = classNames (
      'layout-wrapper',
      {
        'layout-static-sidebar-inactive': this.state.staticMenuInactive,
        'layout-mobile-sidebar-active': this.state.mobileMenuActive
      }
    )

    return (
      <div
        className={wrapperClass}
        onClick={this.onWrapperClick}
        onMouseOver={this.onMouseOver}
        onTouchStart={this.onTouchStart}
        onTouchMove={this.onTouchMove}
        onTouchEnd={this.onTouchEnd}
      >

        <div ref={(el) => this.sidebar = el} className="layout-sidebar" onClick={this.onSidebarClick}>
          <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{height:'100%'}}>
            <div className="layout-sidebar-scroll-content" >
              <div className="layout-menu-button-container">
                <button id="toggleMenu" className="p-link layout-menu-button" onClick={this.onToggleMenu}>
                  <span className="pi pi-chevron-circle-left"/>
                </button>
              </div>
              <div className="layout-logo">
                <Display if={this.state.profile.logo}>
                  <Picture
                    alt="Logo"
                    locked={true}
                    onLoad={this.getPicture}
                    value={this.state.profile.logo}
                  />
                </Display>
                <Display if={!this.state.profile.logo}>
                  <img alt="Logo" src={defaultLogo} />
                </Display>
              </div>
              <InlineProfile onProfileChange={this.onProfileChange} onWarning={this.onWarning} />
              <Menu model={this.state.menu} onMenuItemClick={this.onMenuItemClick} onWarning={this.onWarning} />
            </div>
          </ScrollPanel>
        </div>

        <div className="layout-main">
          <ApplicationRoutes routes={this.getAppRoutes()} default={this.getAppDefaultRoute()}/>
        </div>

        <Footer />
        {this.renderWarning ()}
        {this.renderSessionLock ()}
      </div>
    )
  }
}

export default App
