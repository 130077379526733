/*
 * Global translation stuff
 *
 *  - Translator default function must be used to initialise the service API
 *
 *  - translate function have to be imported and used anywhere a translations is needed outside the DOM
 *  - T react componnent have to be imported and used anywhere a translations is needed into the DOM
 *
 */


/*
 * React stuff for the T translation class
 */
import React, {Component} from 'react'
/*
 * PrimeReact stuff to overload
 */
import {Button} from 'primereact/button'
import {SplitButton} from 'primereact/splitbutton'
import {InputText} from 'primereact/inputtext'
import {DataView} from 'primereact/dataview'

/*
 * Translator global static data storage
 */
const TranslatorService = {}

/*
 * Initialise a global variable to set API URL
 */
export default function Translator (config=null, defaultLanguage=null) {
    if ( config && config.translator ) {
      TranslatorService.enabled = true
      TranslatorService.serverURL = config.server
    } else {
      TranslatorService.enabled = false
      TranslatorService.serverURL = null
    }
    TranslatorService.language = defaultLanguage
    TranslatorService.cache = {}
    TranslatorService.devMode = false
}

/*
 * Set or change the translator destination language
 */
export function setTranslatorLanguage (language=null) {
  TranslatorService.language = language
}

/*
 * Set devlopper mode
 */
export function setTranlatorDevMode (devMode=false) {
    TranslatorService.devMode = devMode
}

/*
 * Do a translation
 */
export async function translate (wordingId=null, defaultValue="", vars={}) {

  // If translator is disabled, just return default value
  if ( !TranslatorService.enabled ) {
    return setVars (defaultValue, vars)
  }

  // In developer mode, do not translate nor resolve variables
  // Just display raw wordingId and defaultValue
  if ( TranslatorService.devMode ) {
    return '[' + wordingId + '] ' + defaultValue
  }

  const languageCode = TranslatorService.language || navigator.language

  if ( wordingId ) {
    if ( TranslatorService.cache[languageCode] ) {
      if ( TranslatorService.cache[languageCode][wordingId] !== undefined ) {
        return setVars (TranslatorService.cache[languageCode][wordingId], vars)
      }
    } else {
      TranslatorService.cache[languageCode] = {}
    }
    if ( TranslatorService.serverURL ) {
      const translation = await fetchTranslation (wordingId, TranslatorService.language || navigator.language)
      if ( translation ) {
        if ( translation.success ) {
          TranslatorService.cache[languageCode][wordingId] = translation.data
          return setVars (translation.data, vars)
        } else if ( translation.error ) {
          console.log ('*** Translation service error :', translation.error)
        } else {
          console.log ('*** Translation service error : Unknown.')
        }
      }
    } else {
      console.log ('*** Translation service error : API not initialised !!')
    }
    TranslatorService.cache[languageCode][wordingId] = defaultValue
  } else {
    console.log ('*** Translation service error : wording id not set')
  }
  return setVars (defaultValue, vars)
}

/*
 * Replace variables by their values into expression
 */
function setVars (expression, vars) {
  let v = null
  while ( (v = expression.match (/\$([^$]+)\$/)) ) {
    const value = vars[v[1]] || ''
    expression = expression.replace (/\$[^$]+\$/, value)
  }
  return expression
}

/*
 * Call the server Translation API
 */
async function fetchTranslation (wordingId, language) {
  try {
    const res = await fetch (
      TranslatorService.serverURL,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify (
          {
            Service : 'Translator',
            Query : 'translate',
            WordingId : wordingId,
            Language : language
          }
        )
      }
    )
    if (res.status >= 200 && res.status < 300) {
      return res.json ()
    }
    return { success: false, error: res.statusText, data: false }
  } catch (e) {
    return { success: false, error: e, data: false }
  }
}


/*
 * React transtator component
 */
export class T extends Component {

  constructor (props) {
    super (props)

    this.wordingCode = null
    this.defaultWording = ''
    this.arguments = {}

    // Analyse props from the syntax <T WordingCode vars={var1: 'value1', ...}>DefaultWording</T>
    for (const [k, v] of Object.entries(props)) {
      if ( k === 'children' ) {
        this.defaultWording = v
      } else if ( k === 'vars' ) {
        this.arguments = v
      } else if ( v === true && this.wordingCode === null ) {
        this.wordingCode = k
      } else {
        throw new Error ("Invalid props")
      }
    }

    this.state = {
      translation: ""
    }

  }

  // Do the translation and set the result into component's state
  async componentDidMount () {
    if ( this.wordingCode ) {
      const translation = await translate (this.wordingCode, this.defaultWording, this.arguments)
      if ( translation ) {
        this.setState ({translation: translation})
      }
    }
  }

  // If variables changes, do the translation again
  async componentDidUpdate (prevProps, prevState) {
    if ( prevProps.vars !== this.props.vars ) {
      this.arguments = this.props.vars
      this.componentDidMount ()
    }
  }

  // Return the translation result
  render () {
    return this.state.translation
  }
}

/*
 * Generic component translator
 * take a list of props to be translated and take care to await
 * the result of the translation before rendering
 */
class TranslatorComponent extends Component {

  // Construct local properties copy from initial props
  // and the list of overriding props and their initial default value
  constructor (props, component, propsOverride={}) {
    super (props)
    // Avoid many problems even if it's ugly :-|
    this.mayUpdate = false
    this.localProps = {...props, ...propsOverride}
    this.propsOverride = propsOverride
    this.component = component
  }

  // Override translated props at mount
  async componentDidMount () {
    this.mayUpdate = true
    this.overrideProps ()
  }

  // Override translated props if props update
  async componentDidUpdate (prevProps, prevState) {
    if ( prevProps !== this.props ) {
      this.localProps = {...this.props, ...this.propsOverride}
      this.overrideProps ()
    }
  }

  // Just avoid to update after unMounting
  componentWillUnmount () {
    this.mayUpdate = false
  }

  // Await for translation on overrided props
  async overrideProps () {
    for (const prop of Object.keys(this.propsOverride)) {
      if ( this.props[prop] !== undefined ) {
        this.localProps[prop] = await this.props[prop]
      }
    }
    if ( this.mayUpdate ) {
      this.forceUpdate ()
    }
  }

  render() {
    return React.createElement(this.component, this.localProps, this.props.children)
  }
}


/*
 * Prime react Button component
 * Overloading to make label translator compatible
 */
class TranslatorButton extends TranslatorComponent {
  constructor (props) {
    super (props, Button, {label: ' '})
  }
}
export { TranslatorButton as Button }

/*
 * Prime react SplitButton component
 * Overloading to make label and model translator compatible
 * model is an arry containing many props sets so we need to
 * extend the overrider capacity by ... overriding it !
 */
class TranslatorSplitButton extends TranslatorComponent {
  constructor (props) {
    super (props, SplitButton, {label: ' ', model: []})
  }

  // Override the overrider :-)
  async overrideProps () {
    if ( this.props.label !== undefined ) {
      this.localProps.label = await this.props.label
    }
    if ( this.props.model !== undefined ) {
      this.localProps.model = []
      for (const item of this.props.model) {
        const localItem = {...item}
        localItem.label = await item.label
        this.localProps.model.push (localItem)
      }
    }
    if ( this.mayUpdate ) {
      this.forceUpdate ()
    }
  }
}
export { TranslatorSplitButton as SplitButton }

/*
 * Prime react InputText component
 * Overloading to make placeholder translator compatible
 */
class TranslatorInputText extends TranslatorComponent {
  constructor (props) {
    super (props, InputText, {placeholder: ''})
  }
}
export {TranslatorInputText as InputText}

/*
 * Prime react DataView component
 * Overloading to make placeholder translator compatible
 */
class TranslatorDataView extends TranslatorComponent {
  constructor (props) {
    super (props, DataView, {emptyMessage: ''})
  }
}
export {TranslatorDataView as DataView}


