import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext'
import {Dropdown} from 'primereact/dropdown'
import {TabView,TabPanel} from 'primereact/tabview'
import '../../components/Forms.css'

class ImportParameterForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'parameters', '/Paramétres/Imports', 'Parameters',

      {
        parameters: {
          id: 1,
          applicants_import_state: 1,
          applicants_import_nextStep: 1,
          applicants_import_origin: 1,
          company_import_prefix: "",

          applicants_parse_state: 1,
          applicants_parse_nextStep: 1,
          applicants_parse_origin: 1,
          hrflow_api_key: "",
          hrflow_source_key: "",
        },

        applicants_import_state_list: [],
        applicants_import_nextStep_list: [],
        applicants_import_origin_list: [],

        applicants_parse_state_list: [],
        applicants_parse_nextStep_list: [],
        applicants_parse_origin_list: [],

        mandatoryFields: {
          applicants_import_state: {test: FormContainer.checkNotEmpty, state: false},
          applicants_import_nextStep: {test: FormContainer.checkNotEmpty, state: false},
          applicants_import_origin: {test: FormContainer.checkNotEmpty, state: false},
          company_import_prefix: {test: FormContainer.checkNotEmpty, state: false},
          applicants_parse_state: {test: FormContainer.checkNotEmpty, state: false},
          applicants_parse_nextStep: {test: FormContainer.checkNotEmpty, state: false},
          applicants_parse_origin: {test: FormContainer.checkNotEmpty, state: false},
        },

      }
    )
  }

  async getExternalValues () {
    // Get applicant's states
    const applicants_import_state_list = await this.apiCall ('ApplicantStates', 'list')
    applicants_import_state_list.values = applicants_import_state_list.values.map (t => {return {label: t.name, value: t.id, ...t}})
    const applicants_import_nextStep_list = await this.apiCall ('ApplicantNextSteps', 'list')
    applicants_import_nextStep_list.values = applicants_import_nextStep_list.values.map (t => {return {label: t.name, value: t.id, ...t}})
    const applicants_import_origin_list = await this.apiCall ('ApplicantOrigins', 'list')
    applicants_import_origin_list.values = applicants_import_origin_list.values.map (t => {return {label: t.name, value: t.id, ...t}})
    this.setState (
      {
        applicants_import_state_list: applicants_import_state_list.values,
        applicants_import_nextStep_list: applicants_import_nextStep_list.values,
        applicants_import_origin_list: applicants_import_origin_list.values,
        applicants_parse_state_list: applicants_import_state_list.values,
        applicants_parse_nextStep_list: applicants_import_nextStep_list.values,
        applicants_parse_origin_list: applicants_import_origin_list.values,
      }
    )
  }

  doRender() {
    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Paramètres des imports</h1>
                <FormContainer
                  values={this.state.parameters}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <TabView>

                    <TabPanel header="Linkedin - Datananas">
                      <div className="form-new-line p-grid">

                        <div className="p-col-12 p-grid p-justify-even">
                          <div className="p-col-12 p-md-3">
                            <MandatoryLabel
                              htmlFor="applicants_import_state"
                              isMissing={this.state.mandatoryFields.applicants_import_state.state}
                            >
                              Etat associé à un candidat importé
                            </MandatoryLabel>
                          </div>
                          <div className="p-col-12 p-md-5">
                            <Dropdown
                              className="form-input"
                              id="applicants_import_state"
                              disabled={!this.state.editMode}
                              options={this.state.applicants_import_state_list}
                              value={this.state.parameters.applicants_import_state}
                              onChange={this.handleSelect}
                              autoWidth={false}
                            />
                          </div >
                        </div>

                        <div className="p-col-12 p-grid p-justify-even">
                          <div className="p-col-12 p-md-3">
                            <MandatoryLabel
                              htmlFor="applicants_import_nextStep"
                              isMissing={this.state.mandatoryFields.applicants_import_nextStep.state}
                            >
                              Prochaine étape associée à un candidat importé
                            </MandatoryLabel>
                          </div>
                          <div className="p-col-12 p-md-5">
                            <Dropdown
                              className="form-input"
                              id="applicants_import_nextStep"
                              disabled={!this.state.editMode}
                              options={this.state.applicants_import_nextStep_list}
                              value={this.state.parameters.applicants_import_nextStep}
                              onChange={this.handleSelect}
                              autoWidth={false}
                            />
                          </div >
                        </div>

                        <div className="p-col-12 p-grid p-justify-even">
                          <div className="p-col-12 p-md-3">
                            <MandatoryLabel
                              htmlFor="applicants_import_nextStep"
                              isMissing={this.state.mandatoryFields.applicants_import_nextStep.state}
                            >
                              Origine associée à un candidat importé
                            </MandatoryLabel>
                          </div>
                          <div className="p-col-12 p-md-5">
                            <Dropdown
                              className="form-input"
                              id="applicants_import_origin"
                              disabled={!this.state.editMode}
                              options={this.state.applicants_import_origin_list}
                              value={this.state.parameters.applicants_import_origin}
                              onChange={this.handleSelect}
                              autoWidth={false}
                            />
                          </div >
                        </div>

                        <div className="p-col-12 p-grid p-justify-even">
                          <div className="p-col-12 p-md-3">
                            <MandatoryLabel
                              htmlFor="company_import_prefix"
                              isMissing={this.state.mandatoryFields.company_import_prefix.state}
                            >
                              Prefix ajouté par défaut aux sociétés importées
                            </MandatoryLabel>
                          </div>
                          <div className="p-col-12 p-md-5">
                            <InputText
                              className="form-input"
                              id="company_import_prefix"
                              value={this.state.parameters.company_import_prefix}
                              onChange={this.handleInputText}
                            />
                          </div >
                        </div>

                      </div >
                    </TabPanel>


                    <TabPanel header="CV - HrFlow">
                      <div className="form-new-line p-grid">

                        <div className="p-col-12 p-grid p-justify-even">
                          <div className="p-col-12 p-md-3">
                            <MandatoryLabel
                              htmlFor="applicants_parse_state"
                              isMissing={this.state.mandatoryFields.applicants_parse_state.state}
                            >
                              Etat associé à un CV importé
                            </MandatoryLabel>
                          </div>
                          <div className="p-col-12 p-md-5">
                            <Dropdown
                              className="form-input"
                              id="applicants_parse_state"
                              disabled={!this.state.editMode}
                              options={this.state.applicants_parse_state_list}
                              value={this.state.parameters.applicants_parse_state}
                              onChange={this.handleSelect}
                              autoWidth={false}
                            />
                          </div >
                        </div>

                        <div className="p-col-12 p-grid p-justify-even">
                          <div className="p-col-12 p-md-3">
                            <MandatoryLabel
                              htmlFor="applicants_parse_nextStep"
                              isMissing={this.state.mandatoryFields.applicants_parse_nextStep.state}
                            >
                              Prochaine étape associée à un CV importé
                            </MandatoryLabel>
                          </div>
                          <div className="p-col-12 p-md-5">
                            <Dropdown
                              className="form-input"
                              id="applicants_parse_nextStep"
                              disabled={!this.state.editMode}
                              options={this.state.applicants_parse_nextStep_list}
                              value={this.state.parameters.applicants_parse_nextStep}
                              onChange={this.handleSelect}
                              autoWidth={false}
                            />
                          </div >
                        </div>

                        <div className="p-col-12 p-grid p-justify-even">
                          <div className="p-col-12 p-md-3">
                            <MandatoryLabel
                              htmlFor="applicants_parse_nextStep"
                              isMissing={this.state.mandatoryFields.applicants_parse_nextStep.state}
                            >
                              Origine associée à un CV importé
                            </MandatoryLabel>
                          </div>
                          <div className="p-col-12 p-md-5">
                            <Dropdown
                              className="form-input"
                              id="applicants_parse_origin"
                              disabled={!this.state.editMode}
                              options={this.state.applicants_parse_origin_list}
                              value={this.state.parameters.applicants_parse_origin}
                              onChange={this.handleSelect}
                              autoWidth={false}
                            />
                          </div >
                        </div>

                        <div className="p-col-12 p-grid p-justify-even">
                          <div className="p-col-12 p-md-3">
                            <label htmlFor="hrflow_api_key">Clé secrète d'API HrFlow</label>
                          </div>
                          <div className="p-col-12 p-md-5">
                            <InputText
                              className="form-input"
                              id="hrflow_api_key"
                              value={this.state.parameters.hrflow_api_key}
                              onChange={this.handleInputText}
                            />
                          </div >
                        </div>

                        <div className="p-col-12 p-grid p-justify-even">
                          <div className="p-col-12 p-md-3">
                            <label htmlFor="hrflow_source_key">Clé de Source HrFlow</label>
                          </div>
                          <div className="p-col-12 p-md-5">
                            <InputText
                              className="form-input"
                              id="hrflow_source_key"
                              value={this.state.parameters.hrflow_source_key}
                              onChange={this.handleInputText}
                            />
                          </div >
                        </div>

                      </div >
                    </TabPanel>

                  </TabView>

                </FormContainer>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default ImportParameterForm
