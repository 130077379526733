import React, {Component} from 'react'

export class StretchBox extends Component {

  constructor(props) {
    super(props)

    this.state = {
      resizing : false
    }

    this.sizeObserver = null
    this.maskObserver = false
    this.frame = null
    this.container = null
    this.bottom = null

    this.setRef = this.setRef.bind(this)
    this.enableObserver = this.enableObserver.bind(this)
    this.disableObserver = this.disableObserver.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.doResize = this.doResize.bind(this)

    this.timeout = 100
    this.timer = null

    this.placeHolder = this.props.placeHolder ?? 1
    this.minItemsHeight = this.props.minItemsHeight ?? 0

    this.childProps = {...this.props}
    delete this.childProps.placeHolder
    delete this.childProps.minItemsHeight
  }


  /*
   * Get references on DOM objects
   */
  setRef (ref) {
    if ( !this.container ) {
      this.container = ref
    }
    if ( !this.bottom && this.props.until ) {
      this.bottom = document.querySelector (this.props.until)
    }
    if ( !this.frame ) {
      this.frame = document.querySelector ('body')
    }
  }

  /*
   * Start object's size observer
   */
  enableObserver (children=[]) {
    if ( this.sizeObserver ) {
      if ( this.frame ) {
        this.sizeObserver.observe(this.frame)
      }
      for (let i=0; i<children.length; i++) {
        this.sizeObserver.observe(children[i].object)
      }
    }
    this.maskObserver = false
  }

  /*
   * Stop object's size observer
   */
  disableObserver () {
    if ( this.sizeObserver ) {
      this.maskObserver = true
    }
    if (this.timer) {
      clearTimeout (this.timer)
      this.timer = null
    }
  }


  /*
   * Initialise object's observer and sizes
   */
  componentDidMount () {
    // Initialise the size observer
    this.sizeObserver = new ResizeObserver(this.handleResize)
    // Initialise sizes
    this.timer = setTimeout(
      () => {
        this.doResize ()
      },
      this.timeout * 5
    )
  }


  /*
   * Cleanup all observers
   */
  componentWillUnmount () {
    if ( this.sizeObserver ) {
      this.sizeObserver.disconnect ()
    }
    if (this.timer) {
      clearTimeout (this.timer)
    }
  }

  /*
   * Handle resize events
   * Wait for a timout to prevents burst
   * if another sizing event raises immediately
   */
  handleResize () {
    if (this.timer) {
      clearTimeout (this.timer)
    }
    if ( !this.maskObserver ) {
      this.timer = setTimeout(
        () => {
          this.doResize ()
        },
        this.timeout
      )
    }
  }


  /*
   * Actually do the resising of objects
   */
  doResize () {

    // First Check for ref
    if ( !this.container ) {
      return
    }

    // Then disable all sizing events
    this.disableObserver ()

    // Reset container min-height value to let it get it's default size
    this.container.style.minHeight = 0

    // Get container's dimensions
    const containerDim = this.container.getBoundingClientRect()
    let containerBottom = containerDim.bottom

    // Initialise children
    let children = []
    let childrenBottom = 0
    let childrenPadding = 0

    // Get children's dimensions
    const childrenItems = this.container.querySelectorAll(".stretch-box-item")
    for (let i=0; i<childrenItems.length; i++) {
      const child = {object: childrenItems[i]}
      // Reset child's content min-height value to let it get it's default size
      const content = child.object.querySelector (".stretch-box-content")
      if ( content ) {
        // Preserve minItemsHeight if this props is set
        content.style.minHeight = this.minItemsHeight
      }
      // Get current child's dimensions
      const dimensions = child.object.getBoundingClientRect()
      child.top = dimensions.top
      child.bottom =  dimensions.bottom
      // Record the lower child bottoom position
      if ( dimensions.bottom > childrenBottom ) {
        childrenBottom = dimensions.bottom
      }
      // If chidren are stacked (at least one is above another)
      // do not resize, exit !
      if ( child.top > childrenBottom ) {
        this.enableObserver (children)
        return
      }
      // Record the children padding (keep the higher)
      if ( childrenPadding < child.top - containerDim.top ) {
        childrenPadding = child.top - containerDim.top
      }
      // Record child
      children.push (child)
    }

    // If we have a target bottom, adjust the container size
    if ( this.bottom ) {

      // Get target dimensions
      const bottomDim = this.bottom.getBoundingClientRect()

      // Get viewPort bottom position (subtracting 1 to have the inner lower coordinate)
      const viewPortBottom =  (document.documentElement.clientHeight || window.innerHeight) - 1

      // If target bottom is above the viewport stretch container if needed
      // else, the container bottom is already above the screen because of
      // the size of it's children
      if ( bottomDim.bottom >= viewPortBottom && containerDim.bottom < bottomDim.top ) {
        const offset = bottomDim.top - containerDim.bottom - this.placeHolder
        containerBottom = containerBottom + offset
        this.container.style.minHeight = (containerBottom - containerDim.top) + 'px'
        // Adjuts childrenBottom for it to fill all the container
        // This is not needed if the children already fill the container
        // so we compute this only if container has been stretched
        if ( childrenBottom < containerBottom - childrenPadding ) {
          childrenBottom = containerBottom - childrenPadding
        }
      }
    }


    // Adjust children child to let them have the same height
    for ( let i=0; i<children.length; i++ ) {
      const child = children[i]
      if ( child.bottom < childrenBottom ) {
        const offset = childrenBottom - child.bottom
        const content = child.object.querySelector (".stretch-box-content")
        if ( content ) {
          const contentDim = content.getBoundingClientRect()
          content.style.minHeight = (contentDim.bottom - contentDim.top + offset) + 'px'
        }
      }
    }

    // Finally re enable sizing events
    this.enableObserver (children)
  }


  /*
   * Render the components
   */
  render() {
    return (
      <div {...this.childProps} ref={this.setRef}>
        {this.props.children}
      </div>
    )
  }
}
