import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import ApplicantStateList from './ApplicantStateList'
import ApplicantStateForm from './ApplicantStateForm'

export class ApplicantStates extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Etats/CVthèque' component={ApplicantStateList} />
        <Route path='/Etats/CVthèque/:id' component={ApplicantStateForm} />
      </Switch>
    )
  }
}
