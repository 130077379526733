import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import PersonalContactList from './PersonalContactList'
import PersonalContactForm from './PersonalContactForm'

export class PersonalContacts extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Contacts' component={PersonalContactList} />
        <Route path='/Contacts/:id/:user_id?' component={PersonalContactForm} />
      </Switch>
    )
  }
}
