import React, {Component} from 'react'
import {Display} from './Display'


  /*
   * Global search element
   *
   * Implement search and presentation of objects in the global search context
   *
   * Usage :
   *
   *  <ElementSearch
   *
   *    // Mandatory props
   *    search={this.state.searchPattern}             // The variable input pattern
   *    openForm={this.handleElements}                // A method to open a single Element
   *    openList={this.handleList}                    // A method to open a list of Element
   *    apiCall={this.apiCall}                        // A method to query the Element object
   *    path='/Besoins/'                              // Path to the object renderer (Forms, Tables ...)
   *    service='Needs'                               // Service to query for this object
   *    object='need'                                 // The name of the object managing these Elements
   *
   *    // Optional props
   *    title='Résultat'                              // The title of this group in the preview pannel
   *    maxPreviewResults='5'                         // The maximum number results to display in the preview pannel
   *    renderResult={(r) => " " + r.name}            // A function rendering a single preview results
   *    qrery='list'                                  // The query used to search the elements
   *    filers={}                                     // Filters to apply to the search query
   *    filterTimeout=500                             // Time to wait for patten change before do an actual search
   *    updateResults={(s, n) => true}                // Method called to update nuber of results (called with Service and number of match)
   *
   *  />
   *
   */


export class ElementSearch extends Component {

  constructor(props) {
    super(props)

    this.state = {
        elements: {totalRecords: 0, values: []},
    }

    this.filterTimer = null
    this.filterTimeout = this.props.filterTimeout || 500

    this.service = this.props.service
    this.query = this.props.query || 'list'
    this.title = this.props.title || 'Résultat'
    this.maxPreviewResults = this.props.maxPreviewResults || 5
    this.renderResult = this.props.renderResult || this.defaultRenderResult
    this.updateResults = this.props.updateResults || this.defaultUpdateResults


    this.handleSearch = this.handleSearch.bind(this)

    this.searchElement = this.searchElement.bind(this)
    this.getElements = this.getElements.bind(this)

  }


  /*
   * Search handler
   * Trigger a new search when pattern is stable
   * for at least the timeout delay
   */
  handleSearch () {
    if (this.filterTimer) {
      clearTimeout (this.filterTimer)
      this.filterTimer = null
    }
    this.filterTimer = setTimeout (this.searchElement, this.filterTimeout)
  }

  /*
   * searcher : Do the search
   */
  async searchElement () {
    const elements = await this.getElements (0, this.maxPreviewResults, this.props.search, this.props.filters)
    this.setState ({elements: elements}, () => {this.updateResults (this.service, elements.totalRecords)})
  }

  /*
   * getter : Call API to get actual data
   */
  getElements (first=0, number=1, pattern=null, filters=null) {
    return this.props.apiCall (this.service, this.query, first, number, null, pattern, filters)
  }


  /*
   * Start searching at componnent initialisation
   */
  componentDidMount () {
    this.handleSearch ()
  }

  /*
   * Restart searching when pattern changes
   */
  componentDidUpdate (prevProps, prevState) {
    if ( prevProps.search !== this.props.search ) {
      this.handleSearch ()
    }
  }

  /*
   * Reset search input timer before exit
   */
  componentWillUnmount() {
    if (this.filterTimer) {
      clearTimeout (this.filterTimer)
      this.filterTimer = null
    }
  }

  /*
   * Default results updater
   */
  defaultUpdateResults (service, numberMatched) {
    return true
  }


  /*
   * Default single result rendering
   */
  defaultRenderResult (element) {
    return (" " + element.name)
  }


  /*
   * Component rendering
   */

  render() {
    return (
      <Display if={this.state.elements.totalRecords}>
        <div className={this.props.className}>
          <ul>
            <li
              className="globalsearch-results-title globalsearch-cursor-pointer"
              onClick={x => this.props.openList (this.props.path, this.props.object)}
            >
              {this.title} : {this.state.elements.totalRecords}
            </li>
            <li>
              <ul>
                {
                  this.state.elements.values.map (
                    (e,i) => {
                      return (
                        <li
                          key={i}
                          className="globalsearch-cursor-pointer"
                          onClick={x => this.props.openForm (e.id, i, this.state.elements.totalRecords, this.props.path, this.getElements)}
                        >
                          <i className="pi pi-folder-open"/>
                          {this.renderResult (e)}
                        </li>
                      )
                    }
                  )
                }
              </ul>
            </li>
          </ul>
        </div>
      </Display>
    )
  }
}
