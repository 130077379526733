import React from 'react'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

class MailingTemplateList extends AuthenticatedTable {

  constructor(props) {
    super (props, 'mailingTemplate', '/Mailing/Modèles', 'MailingTemplates')
  }

  doRender() {
    return (
      <div className="p-fluid">
        <div className="p-grid">
          <div className="p-col-12">

            <div className="card card-w-title">
              <h1>Modèles de mailing</h1>

              <div className="p-grid">
                <div className="p-col-12">
                  <LazyDataTable
                    ref={this.setRef}
                    locked={this.props.locked}
                    noAutoFocus={this.props.noAutoFocus}
                    onOpen={this.handleOpen}
                    onCreate={this.handleCreate}
                    getValues={this.getValues}
                    watch={this.state.profile}
                  >
                    <Column field="subject" header="Sujet" sortable={true} style={{width:'20%'}} />
                  </LazyDataTable>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default MailingTemplateList
