import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import MailingStateList from './MailingStateList'
import MailingParameterForm from './MailingParameterForm'
import MailingStateForm from './MailingStateForm'

export class MailingStates extends AuthenticatedComponent {
  doRender() {
    return (
      <Switch>
        <Route exact path='/Paramétres/Mailings' component={MailingParameterForm} />
        <Route exact path='/Etats/Mailings' component={MailingStateList} />
        <Route path='/Etats/Mailings/:id' component={MailingStateForm} />
      </Switch>
    )
  }
}
